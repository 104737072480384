import React, { Component, useEffect, useState } from 'react';
import { useScrollTrigger, Typography, Box, Stack, Menu, MenuItem, InputLabel, Input, Button, TextField, Divider, Select, OutlinedInput, FormControl, FormHelperText, Chip } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';

export default function CreateTicket({closeTicketView}){
    const navigate = useNavigate();
    const {auth} = useAuth();
    const [title,setTitle] = useState('');
    const [imagefile, setImagefile] = useState('');
    const [textcontent, setTextContent] = useState('');

    const [selectSubject, setSelectSubject] = useState('')

    const Subject = [
        'Account issue',
        'Billing',
        'Bug report',
        'Development Update',
        'Suggestion',
        'Form issue'
    ]

    const [selectSeverity, setSelectSeverity] = useState('')

    const Severity = [
        'Minimal',
        'Medium',
        'Major',
        'Critical'
    ]

    const [selectProduct, setSelectProduct] = useState('');

    const Product = [
        'Demo'
    ]

    const title_onChange = (e) => {
        setTitle(e.target.value);
    }
    const subject_onChange = (e) => {
        setSelectSubject(e.target.value);
    }
    const severity_onChange = (e) => {
        setSelectSeverity(e.target.value);
    }
    const product_onChange = (e) => {
        setSelectProduct(e.target.value);
    }
    const txtcontent_onChange = (e) => {
        setTextContent(e.target.value);
    }

    const file_onChange = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            console.log(reader.result);
            setImagefile(reader.result);
        };
        reader.onerror = error => {
            console.log('Error: ', error);
        }
    }

    const navigateBack = () => {
        navigate('/tickets')    
    }

    const postTicket = (e) => {
        e.preventDefault()
        
        const ticket = {
            title: title,
            user: auth.user,
            product: selectProduct,
            subject: selectSubject,
            image: imagefile,
            severity: selectSeverity,
            textcontent: textcontent,
            comments: [],
            server: auth.server
        }
        console.log(ticket);
        axios.post('/tickets/add', ticket)
        .then(res => console.log(res.data))
        .catch(err => {
            console.log(err)
        })
        navigateBack()
    }

    return(
        <Box
        component={'form'}
        autoComplete='off'
        sx={{
            mx: 'auto', 
            width:1500,
            
            border: '1px solid',
            borderColor: '#f5f5f5',
        }}
        >
            
            
            <Box  >
                <Button onClick={navigateBack} variant='outlined' startIcon={<ArrowBackIosNewIcon/>}>Back</Button>
                <Typography sx={{display:'flex' ,justifyContent:'center', justifyItems:'center'}} variant='h4'>Open a ticket</Typography>
            </Box>
            <br />
            <Divider variant='middle' />
            <br />
            <Box>
                <TextField
                    sx={{width:400}}
                    required
                    label="Title"
                    onChange={title_onChange}
                />
                <FormControl sx={{width:400}}>
                    <InputLabel id="product-select">Product</InputLabel>
                    <Select
                        value={selectProduct}
                        onChange={product_onChange}
                        labelId='product-select'
                        
                        input={<OutlinedInput label="Product"/>}
                        
                    >
                        {Product.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <br />
            <Box>
                
                <FormControl sx={{width:400}}>
                    <InputLabel id="subject-select">Subject</InputLabel>
                    <Select
                        value={selectSubject}
                        onChange={subject_onChange}
                        labelId='subject-select'
                        
                        input={<OutlinedInput label="Subject"/>}
                        
                    >
                        {Subject.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{width:400}}>
                    <InputLabel id="severity-select">Severity</InputLabel>
                    <Select
                        value={selectSeverity}
                        onChange={severity_onChange}
                        labelId='severity-select'
                        input={<OutlinedInput label="Severity"/>}
                        
                    >
                        {Severity.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
            </Box>
            <br />
            <Button
                variant='contained'
                component='label'
                startIcon={<AttachFileIcon/>}
            >
                Upload img
                <input 
                    type="file"
                    accept='image/*'
                    hidden
                    onChange={file_onChange}
                
                 />
            </Button>
            {imagefile =="" || imagefile ==null?"": (
                <Box>
                    <Typography variant='subtitle1'>(1) Image file selected</Typography>
                    <img src={imagefile} width={100} height={100} />
                </Box>
            )}
            <br />
            <br />
            <Divider variant='middle' textAlign='left'></Divider>
            <br />
            <Box
                width='100%'
            >
                <TextField
                    multiline
                    label='Text Content'
                    minRows={5}
                    sx={{width: '100%'}}
                    onChange={txtcontent_onChange}
                />
                 
            </Box>
            <br />
            <Button variant='contained' onClick={postTicket} >Submit ticket</Button>
        </Box>
    )
}