

import useAuth from '../hooks/useAuth';
import { IconButton, Avatar, ListItemAvatar, Box, Grid, Paper, styled, Button, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, ListItemButton } from "@mui/material";
import PasswordIcon from '@mui/icons-material/Password';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { UserHasLoggedIn } from "../api/utils";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { UserHasPermissions } from '../api/utils';
import { PermObjToArray } from '../api/utils';
import { Margin } from '@mui/icons-material';
import Select from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

//Account Management flow chart
/*
1. if user has logged in, match account name from db with auth and show account panel
2. if user has Authorization permissions, show account management view
3. if user has logged in but account name from auth doesnt match the requested account -> unauth
4. if user has logged in and account name from auth matches the request but doesn exist in database -> missing page with 404
*/

function AccountManagement(){
    const {auth} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [fetchedUser, setFecthedUser] = useState({});
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [manageMode, setManageMode] = useState(false)
    const [loading, setLoading] = useState(true);
    const [loadingUserList, setLoadingUserList] = useState(true);
    const openInManage = location.state;
    
    useEffect(() => {
        try{
            console.log(openInManage.openInManage && UserHasPermissions(auth.permissions, ['Authorization']))
            if(openInManage.openInManage && UserHasPermissions(auth.permissions, ['Authorization'])){

                setManageMode(true);

                
            } else {
                setManageMode(false);
            }
        } catch(e){
            console.error(e)
            setManageMode(false);
        }
    }, [openInManage])
    /*
    useEffect(() => {
      if(fetchedUser !== undefined && fetchedUser != null){
        setLoading(true);
      }else {
        setLoading(false);
      }
    }, [fetchedUser])
    */

    useEffect(() => {
        /*
        let isMounted = true;
        const controller = new AbortController();
        const fetchuser = async () => {
            try{
                const response = await axiosPrivate.post('/users/find' , {
                    username: auth.user
                },
                {signal: controller.signal});
                if(openInManage.openInManage && UserHasPermissions(auth.permissions, ['Authorization'])){
                    const res2 = await axiosPrivate.get('/users/' ,
                    {signal: controller.signal});
                    console.log(res2.data);
                    isMounted && setUserList(res2.data);
                    setLoadingUserList(false);
                }
                console.log(response.data);
                
                isMounted && setFecthedUser(response.data);
                setLoading(false);
            }catch(e){
                console.error(e);
                setLoading(false);
                setLoadingUserList(false);
                console.log("reached")
                navigate('/login', { state: {from: location}, replace: true});
            }
        }
        fetchuser();
        return () => {
            isMounted = false;
            controller.abort();
        }
        */
    }, [])

    function handleSelectUser(target){
        setSelectedUser(target);
        console.log(selectedUser);
    }
    

    return(
        
        UserHasLoggedIn(auth)
        ? (
            <Box sx={{height:'85vh', width:'90vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Item sx={{height:'100%', width:'100%', backgroundColor:'white',display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Box sx={{width:'100%', height:'100%', }}>
                        <Box className='d-flex justify-content-start'>
                            <Typography variant='h5' sx={{color:'black'}}>
                                Account Management
                            </Typography>
                        </Box>
                        <br />
                        <Box sx={{width: '100%',display:'flex', justifyContent:'start' }}>
                            <Button onClick={() => navigate('/account/permissions')} variant='contained' endIcon={<SupervisorAccountIcon/>}>Account Permissions</Button>
                        </Box>
                        <br />
                        <Divider />
                        {/* Dividing the adming view page */}
                        <Grid container sx={{width:'100%', height:'94%'}}>
                            <Grid item xs >
                                <Box>
                                    
                                    <Box className='d-flex justify-content-start' sx={{margin:2}} >
                                        <Box>
                                            <Avatar
                                            alt='icon-img'
                                            src='https://placekitten.com/250/250'
                                            sx={{width:250, height:250}}
                                            />
                                        </Box>
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <AccountCircleIcon/>
                                                </ListItemIcon>
                                                {console.log(Object.keys(selectedUser).length)}
                                                <ListItemText 
                                                    primaryTypographyProps={{color:"black"}}
                                                    
                                                    primary={auth.user}

                                                    secondary="Username"
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem>
                                                <ListItemIcon>
                                                    <EmailIcon/>
                                                </ListItemIcon>
                                                {/* UPDATE */}
                                                <ListItemText 
                                                    primaryTypographyProps={{color:"black"}}
                                                    primary="EMAIL"
                                                    secondary="Email"
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                            secondaryAction={
                                                <IconButton edge="end" aria-label='edit'>
                                                    <EditIcon></EditIcon>
                                                </IconButton>
                                            }
                                            >
                                                <ListItemIcon>
                                                    <PasswordIcon/>
                                                </ListItemIcon>
                                                {/* UPDATE */}
                                                <ListItemText 
                                                    primaryTypographyProps={{color:"black"}}
                                                    primary="*********"
                                                    secondary="Password"
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem>
                                                <ListItemIcon>
                                                    <AssignmentIndIcon/>
                                                </ListItemIcon>
                                                <ListItemText 
                                                    primaryTypographyProps={{color:"black"}}
                                                    primary={selectedUser.permissions ? selectedUser.permissions.role : "404 perms"}
                                                    secondary="Role"
                                                />
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                            <Divider orientation='vertical'/>
                            <Grid item xs sx={{width:'100%', minHeight:'100%'}}>
                                <Box sx={{width:'100%', minHeight:'100%'}}>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Item>
            </Box>
        )
        :(
            //Not logged in
            <Navigate to="/login" state={{from: location}} replace />
        )
    )
}

export default AccountManagement;