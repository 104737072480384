import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ( {allowedPerms} ) => {
    const {auth} = useAuth();
    const location = useLocation();

    //Displays wrapped Outlet if no authenticate user is detected, otherwise navigate to login which is "/"
    return (
        auth?.permissions?.find(perm => allowedPerms?.includes(perm))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{from: location}}/>
                : <Navigate to="/login" state={{from: location}} replace />
    );
}

export default RequireAuth;
/*
{
    "ViewForms":true,
    "ViewQueries":true,
    "Create":true,
    "Edit":true,
    "Delete":true,
    "Fill":true,
    "Queries":true,
    "Authorization":true,
}
*/