import React, { Component, useEffect, useState } from 'react';
 

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { ButtonGroup, IconButton, Stack, TextField, Typography, Box, Switch, Skeleton, Menu,MenuItem, ListItemIcon } from '@mui/material';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Collapse from '@mui/material/Collapse';
import MenuIcon from '@mui/icons-material/Menu';
import { Reorder } from 'framer-motion';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const options = ['Number', 'Text', 'Toggle', 'Date', 'Image', 'List', 'Time', 'Stopwatch'];
  const list_options = ['Number', 'Text', 'Toggle']

//Datarow is the forms line class in Create View

  //spacing={1} columns={16} direction={'column'} style={{height:"100%"}}
export default function DataRow({id, rownum, maxrows, injectData, callback, rowdeleteCB, moveRowUp, moveRowDown,_item}) {
    //Datarows info to be callbacked 
    const [property_name,setProperty_name] = useState('');
    const [property_type,setProperty_type] = useState('');
    const [property_reqf,setProperty_reqf] = useState(false);
    const [property_rownum, setProperty_rownum] = useState(0)
    const [extraMenuIsOpen, setExtraMenuIsOpen] = useState(false);

    const [listExactSize, setListExactSize] = useState(true);
    const [listPrevSize, setListPrevSize] = useState(0);
    const [listSizeNumeric, setListSizeNumeric] = useState(0);
    const [listDataRows, setListDataRows] = useState([]);
    const [_listIdCounter, _setListIdCounter] = useState(100);
    const [_listRowCounter, _setListRowCounter] = useState(1);
    const [dataInitComplete, setDataInitCompelete] = useState(false);
    const [anchorElRowOp, setAnchorElRowOp] = useState(null)

    const [_id,setId] = useState(id);

    useEffect(() => {   

        setProperty_rownum(rownum);
        if(injectData !== undefined && Object.keys(injectData).length > 0){
            setId(injectData.id);
            //setProperty_rownum(injectData.row);
            setProperty_name(injectData.property_name);
            setProperty_type(injectData.property_type);
            setProperty_reqf(injectData.require_field);
            if(injectData.property_type === "List"){
                setListPrevSize(injectData.additional_info.length);
                setListSizeNumeric(injectData.additional_info.length)
                setListDataRows(injectData.additional_info);
            }
            console.log("Reached aaaa");

        }else {
            setProperty_name('');
            setProperty_type('');
            setProperty_reqf(false);
            setId(id);
        }
        doCallback();
        setDataInitCompelete(true);
        
        
    }, [])
    
    const doCallback = () => {
        switch(property_type){
            default: {
                callback({
                    id: _id,
                    property_name: property_name,
                    property_type: property_type,
                    require_field: property_reqf,
                    additional_info: ''
                })
            }
            case 'Text':{
                callback({
                    id: _id,
                    property_name: property_name,
                    property_type: property_type,
                    require_field: property_reqf,
                    additional_info: 'asd'
                })
            }
            case 'List': {
                callback({
                    id: _id,
                    property_name: property_name,
                    property_type: property_type,
                    require_field: property_reqf,
                    additional_info: listDataRows
                })
            }
        }
    }
    
    useEffect(() => {
        
        doCallback();
        
        //set extra menu open when property requires more details
        if(property_type === 'List'){
        setExtraMenuIsOpen(true);
        } else {
        setExtraMenuIsOpen(false);
        }
    }, [property_name,property_type,property_reqf,listDataRows])

    const handleOpenRowOpMenu = (event) => {
        setAnchorElRowOp(event.currentTarget)
    }
    const handleCloseRowOpMenu = () => {
        setAnchorElRowOp(null)
    }

    
    
    
    //inject this.state obj to 'rowdata' prop after every setstate has happened
    const onChangeName = (e) =>{
        setProperty_name(e.target.value);
        
    }

    const onChangeType = (e) =>{
        setProperty_type(e.target.value);
        
    }

    const onChangeSwitch = (e) =>{
       setProperty_reqf(e.target.checked);

    }

    //List Property Datarow handling Start

    const [listproperty_name, setListProperty_name] = useState([

    ])

    const onChangeListSizeNumeric = (e) => {
        console.log(e.target.value);
        //apply list size value
        if(e.target.value !== '' && e.target.value !== null){
            if(e.target.value > 7){
                setListSizeNumeric(7)
            }else if(e.target.value < 0){
                setListSizeNumeric(0);
            }
            else {
                setListSizeNumeric(e.target.value);
            }
            
        } else {
            console.log("waaa");
            setListSizeNumeric(0);
        }
        //Calculate difference and handle row manipulation

    }


    const onChangeListExactSize = (e) => {
        setListExactSize(e.target.checked);
    }

    
    useEffect(() => {
        if(listSizeNumeric >= 0 && listSizeNumeric < 8 && listPrevSize !== null){
            var diff = listSizeNumeric-listPrevSize;
            if(diff > 0){
                //array is to be pushed to
                addListDataRow(diff);
                
                //set previous value 
                if(listSizeNumeric !== null){
                    
                    setListPrevSize(listSizeNumeric);
                }else {
                    setListPrevSize(0);
                }
                
                } else if (diff < 0){
                    //array is to be sliced
                    var nmdiff = -1*diff;
                    console.log("normalizeddiff"+nmdiff);
                    
                    popLastListDataRow(nmdiff)
                    //set previous value 
                    if(listSizeNumeric !== null){
                        
                        setListPrevSize(listSizeNumeric);
                    }else {
                        setListPrevSize(0);
                    }
                
                } // list size hasnt changed, ignore?
        }
    }, [listSizeNumeric])
    

    const addListDataRow = (x) => {
        const startRow = _listRowCounter;
        const startID = _listIdCounter;
        for(let i = 0; i<x ; i++){
            const newRow = startRow + i;
            const newID = startID + i;

            setListDataRows(listDataRows => [...listDataRows, {'row':newRow, 'id':newID, 'property_name':'', 'property_type':''}])
            //console.log(rowItems);
            
        }
        _setListRowCounter(_listRowCounter => _listRowCounter + x);
        _setListIdCounter(_listIdCounter => _listIdCounter + x);
        console.log(listDataRows);
        console.log(_listIdCounter);
        console.log("added "+x+" rows")
        
    }

    const getListDataROwsPropertyName = (id) => {
        listDataRows.map(rowdata => {
            if(rowdata.id === id){
                return rowdata.property_name;
            }
        })
        return "";
    }

    const updateListDataRowsPropertyName = (e,id) => {
        const updatedRowdatasets = listDataRows.map(rowdata => {
            if(rowdata.id === id){
                return {
                    ...rowdata,
                    property_name: e.target.value,
                };
            } else {
                return rowdata;
            }
        })
        setListDataRows(updatedRowdatasets);
        console.log(listDataRows);
    }
    const updateListDataRowsPropertyType = (e,id) => {
        const updatedRowdatasets = listDataRows.map(rowdata => {
            if(rowdata.id === id){
                return {
                    ...rowdata,
                    property_type: e.target.value,

                };
            } else {
                return rowdata;
            }
        })
        setListDataRows(updatedRowdatasets);
        console.log(listDataRows);
    }

    function popLastListDataRow(x){
        var temparr = [...listDataRows]
        if(listDataRows.length > 0){

            var whaa = temparr.splice(temparr.length-x, x);
            console.log(whaa);
            console.log(temparr);
            
            setListDataRows(temparr);
            //setRowDataSets(rowDatasets.filter((o, i) => target.id-1 !== i));
            //setRowCounter(rowCounter-1);
            _setListRowCounter(_listRowCounter => _listRowCounter - x);
            //console.log(rowDatasets);
        }
    }
    //List Property Datarow handling End
     

    return (
        !dataInitComplete ? (
            <Grid container rows={16} rowSpacing={0} layout={'column'} style={{height:'100%'}}>
                <Grid xs={16} container columns={16} columnSpacing={1} spacing={0}  style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                    <Skeleton height={35} variant='rounded' />
                    </Grid>
                    <Grid xs={6} style={{height:'100%', }}>
                    <Skeleton height={35} variant='rounded' />
                    </Grid>
                    <Grid xs={5} style={{height:'100%'}}>
                    <Skeleton height={35} variant='rounded' />
                    </Grid>
                    <Grid xs={2} style={{height:'100%'}}>
                    <Skeleton height={35} variant='rounded' />
                    </Grid>
                    <Grid xs={2} style={{height:'100%'}}>
                        <Skeleton height={35} variant='rounded' />
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <Grid container rows={16} rowSpacing={0} layout={'column'} style={{height:'100%'}}
                component={Reorder.Item}
                value={_item}
            >

            <Grid xs={16} container columns={16} columnSpacing={1} spacing={1}  style={{height:'100%'}}>
                <Grid xs={1} style={{height:'100%'}}><Item>{rownum+1}</Item></Grid>
                <Grid xs={6} style={{height:'100%', }}>
                    <input value={property_name} onChange={onChangeName} style={{width:'100%', minHeight:35,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
                </Grid>
                <Grid xs={5} style={{height:'100%'}}>
                    <select value={property_type} onChange={onChangeType} style={{width:'100%', minHeight:35,  padding: '2 2 2 2', border:'0', borderRadius:5 }}>
                        <option>Choose Property Type</option>
                            {options.map((option, index) => {
                                return <option key={index}>
                                    {option}
                                </option>
                            })}
                    </select>
                </Grid>
                <Grid xs={2} style={{height:'100%'}}>
                    <Item className='d-flex align-items-center justify-content-center' style={{padding:0, minHeight:35}}>
                        <Switch checked={property_reqf}  value={property_reqf} onChange={onChangeSwitch} size='small'/>
                    </Item>
                </Grid>
                <Grid xs={2} style={{height:'100%'}}>
                    <Item style={{padding:0, minHeight:35}} >
                        <Stack direction="row" spacing={2} className='justify-content-center' sx={{display: {xs:'none',md:'none', lg:'flex'}}}>

                            <IconButton size='small' disabled={(rownum === 0)}>
                                <ArrowUpwardIcon onClick={() => moveRowUp(rownum)} color='primary' sx={(rownum === 0) ? ({color:'gray'}) : null}/>
                            </IconButton >
                            <IconButton onClick={() => moveRowDown(rownum)} size='small' disabled={(rownum === maxrows-1)}>
                                <ArrowDownwardIcon color='primary' sx={(rownum === maxrows-1) ? ({color:'gray'}) : null}/>
                            </IconButton>
                            
                            <IconButton onClick={() => rowdeleteCB({row:rownum})} size='small'>
                                <RemoveCircleIcon  color='error'/>
                            </IconButton>
                        </Stack>
                        <Box sx={{ justifyContent:'center' ,display:{xs:'flex',md:'flex', lg:'none'}}}>
                            <IconButton
                                onClick={handleOpenRowOpMenu}
                            >
                                <MenuIcon fontSize='small'/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorElRowOp}
                                open={Boolean(anchorElRowOp)}
                                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                                keepMounted
                                transformOrigin={{vertical:'top',horizontal:'right'}}
                                onClose={handleCloseRowOpMenu}
                                sx={{
                                    display: {xs:'block', md: 'block', lg:'none'}
                                }}
                            >
                                <MenuItem onClick={() => {
                                    moveRowUp(rownum);
                                    handleCloseRowOpMenu();
                                    
                                }}>
                                    <ListItemIcon>
                                        <ArrowUpwardIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                        Row Up
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    moveRowDown(rownum);
                                    handleCloseRowOpMenu();
                                }}>
                                    <ListItemIcon>
                                        <ArrowDownwardIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                        Row Down
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    rowdeleteCB({row:rownum});
                                    handleCloseRowOpMenu();
                                }}>
                                    <ListItemIcon>
                                        <RemoveCircleIcon fontSize='small' />
                                    </ListItemIcon>
                                    <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                        Delete Row
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>  
                    </Item>
                </Grid>
            </Grid>
            { property_type === "List" ? (
                <Grid xs={16} container columns={16} columnSpacing={1} spacing={1}  style={{height:'100%'}}>
                    <Grid xs={16} sx={{height:'100%'}}>
                        <Item sx={{backgroundColor:'lightblue'}}>
                            <Box sx={{display:'inline-flex', backgroundColor: '#9fd0e0' , minHeight:35,  padding: '2 2 2 2', alignItems:'center'}}>
                                <Typography variant='subtitle1' color='white' sx={{paddingRight:2, paddingLeft:2, paddingTop:0.5}}>Exact size?</Typography>
                                <Switch /*onChange={onChangeListExactSize}*/ value={listExactSize} checked={listExactSize} sx={{}} size='small'/>
                            </Box>
                            <Box sx={{display:'inline-flex', backgroundColor: '#9fd0e0'}}>
                                <Typography variant='subtitle1' color='white' sx={{paddingRight:2, paddingLeft:2, paddingTop:0.5}}>Size</Typography>
                                <input type="number" value={listSizeNumeric} onChange={onChangeListSizeNumeric} disabled={!listExactSize} style={{ minHeight:35,  padding: '2 2 2 2', border:'0', width:75, backgroundColor:'#cce6f0', borderColor:'#E5E5E5', borderRadius:5}} />
                            </Box>
                            
                        </Item>
                    </Grid>
                    
                    {listExactSize && listSizeNumeric > 0 && listSizeNumeric < 8 ? (
                        
                    listDataRows.map((row,i) => {
                            
                            return(
                            <Grid container xs={16} key={row.id} rows={16} rowSpacing={0} layout={'column'} sx={{height:'100%'}}>
                                <Grid container columns={2} spacing={0} xs={1}>
                                    <Grid container direction="row" xs={1}>

                                    </Grid>
                                    {/* UGLY HACK FOR LIST LINES WITH GRIDS, BOX SIZING AND BOX SHADOWS */}
                                    <Grid direction="row" xs={1}>
                                        <Box sx={{ height:'100%',}}>
                                            <Box sx={{boxShadow: "-1px 1px 0px #b0b0b0",  background:'white', height:'48%', }}></Box>
                                            <Box sx={{height:'4%'}}></Box>
                                            {Object.keys(listDataRows).length-1 !== i ? (
                                                <Box sx={{boxShadow: "-1px 0px 0px #b0b0b0",  background:'white', height:'48%', }}></Box>
                                            ):(
                                                <Box></Box>
                                            )}
                                            
                                        </Box>
                                    </Grid>
                                    
                                </Grid>
                                <Grid xs={2} md={1} style={{height:'100%'}} ><Item sx={{backgroundColor:'lightblue',}}>{property_rownum}.{i+1}</Item></Grid>
                                <Grid xs={6} style={{height:'100%', }}>
                                    <input value={row.property_name} onChange={(e) => updateListDataRowsPropertyName(e, row.id)} placeholder={'Property name...'} style={{width:'100%', minHeight:35,  padding: '2 2 2 2', border:'0', backgroundColor:'#9fd0e0', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
                                </Grid>
                                <Grid xs={5} style={{height:'100%'}}>
                                    <select value={row.property_type} onChange={(e) => updateListDataRowsPropertyType(e, row.id)}  style={{width:'100%', minHeight:35, padding: '2 2 2 2', border:'0', backgroundColor:'#cce6f0', borderRadius:5 }}>
                                        <option>Choose Property Type</option>
                                            {list_options.map((option, index) => {
                                                return <option key={index}>
                                                    {option}
                                                </option>
                                            })}
                                    </select>
                                </Grid>
                            </Grid>
                            )}
                        )
                    ):(
                        null
                    )}
                </Grid>

            )
            :(
                null
            )
            }

            </Grid> 
        )
    )
}