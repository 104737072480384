// icon:node-plus | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from "react";

function IconNodePlus(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M11 4a4 4 0 100 8 4 4 0 000-8zM6.025 7.5a5 5 0 110 1H4A1.5 1.5 0 012.5 10h-1A1.5 1.5 0 010 8.5v-1A1.5 1.5 0 011.5 6h1A1.5 1.5 0 014 7.5h2.025zM11 5a.5.5 0 01.5.5v2h2a.5.5 0 010 1h-2v2a.5.5 0 01-1 0v-2h-2a.5.5 0 010-1h2v-2A.5.5 0 0111 5zM1.5 7a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
      />
    </svg>
  );
}

export default IconNodePlus;
