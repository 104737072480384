import { Box, Grid, Paper, styled, Button, Typography, TextField, Fade, IconButton, InputLabel, FormControl, Divider, MenuItem, List,ListItemButton, ListItemText, ListItemAvatar, Select, Chip, Dialog, DialogActions, DialogContent, DialogContentText,DialogTitle, OutlinedInput} from "@mui/material";
import {Link, Navigate, useNavigate,useLocation} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from "../api/axios";
import { UserHasPermissions, EncodePermStringArray, DecodePermValueToString } from '../api/utils';
import { useEffect, useState , useRef, useContext,useCallback} from "react";
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const permissionArr = [
    "ViewForms",
    "ViewQueries",
    "Create",
    "Edit",
    "Delete",
    "Fill",
    "Queries",
    "Authorization",
]

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

export default function AccountPermissions() {

    const [userList, setUserList] = useState([]);
    const [loadingUserList, setLoadingUserList] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
   
    const [newUserPerms, setNewUserPerms] = useState([])
    const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [openInEdit, setOpenInEdit] = useState(false)
    
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const  {auth} = useAuth();

    const navigate = useNavigate();

    const handleCloseCreateUserDialog = () => {
        setOpenCreateUserDialog(false);
    }
    const handleOpenCreateUserDialog = () => {
        setOpenCreateUserDialog(true);
    }

    const resetNewUserPerms = () => {
        setNewUserPerms([])
    }

    const prepareEdit = () => {
        setOpenInEdit(true);
        setOpenCreateUserDialog(true);
    }

    const handleChangeSelect = (event) => {
        const {
            target: {value},
        }= event;
        setNewUserPerms(
            typeof value === "string" ? value.split(',') : value
        );
    }

    const getPermissionValue = (value) => {
        if(value === 0){
            return "ViewFroms"
        }
        if(value === 1){
            return "ViewQueries"
        }
        if(value === 2){
            return "Create"
        }
        if(value === 3){
            return "Edit"
        }
        if(value === 4){
            return "Delete"
        }
        if(value === 5){
            return "Fill"
        }
        if(value === 6){
            return "Queries"
        }
        if(value === 7){
            return "Authorization"
        }
    }

    useEffect(() => {
        
        let isMounted = true;
        const controller = new AbortController();
        const fetchuser = async () => {
            try{
                
                const res2 = await axiosPrivate.get('/users/', {params:{"server":auth.server}},
                {signal: controller.signal});
                console.log(res2.data);
                isMounted && setUserList(res2.data);
                setLoadingUserList(false);
                
 
            }catch(e){
                console.error(e);
                setLoadingUserList(false);
                //console.log("reached")
                navigate('/login', { state: {from: location}, replace: true});
            }
        }
        fetchuser();
        return () => {
            isMounted = false;
            controller.abort();
        }
        
    }, [])

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        console.log([...userList[index].permissions.value])
    };

    return(
        <Box sx={{display:'-ms-flexbox',justifyContent:'center', alignItems:'center', padding: 2}}>
            <Box sx={{display:'flex', justifyContent:'start', width:'100%'}}>
                <Button variant="contained" onClick={() => navigate("/account",{state:{openInManage: true}})}>Back</Button>
                <Button onClick={() => {handleOpenCreateUserDialog(); resetNewUserPerms(); setOpenInEdit(false);}} variant="contained" endIcon={<AddIcon/>}>Create User</Button>
            </Box>
            <br />
            <Box sx={{display:'flex', justifyContent:'center', width:'100%'}}>
                <Typography variant="h4" sx={{fontFamily:'Open Sans' }}>Account Permissions</Typography>
                 
            </Box>
            <Divider sx={{opacity:1}}/>
            <br />
            <Box sx={{display:'flex', justifyContent:'center', alignContent:'center', width:'100%'}}>
                <Grid container columns={12} >
                    <Grid Item xs={4}>
                        <Box sx={{width:'100%', justifyContent:'center'}}>
                            <Typography textAlign='center' variant="h5">
                                User List
                            </Typography>
                            
                            <Box sx={{width:'100%', justifyContent:'center'}}>
                                <List>
                                    {userList.map((user, index) => {
                                        return(
                                            <ListItemButton
                                                key={user._id}
                                                selected={selectedIndex === index}
                                                onClick={(event) => handleListItemClick(event,index)}
                                            >
                                                <ListItemText primary={user.username}/>

                                            </ListItemButton> 
                                            )   
                                       
                                    })}
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid Item xs={4}>
                        <Box sx={{width:'100%', justifyContent:'center'}}>
                            
                            <Typography textAlign='center' variant="h5">
                                User Permissions
                            </Typography>
                            <Button variant="contained" sx={{marginBottom:2}} onClick={() => prepareEdit()}>
                                Edit Account
                            </Button>
                            
                            <Divider sx={{opacity:1}}/>
                            {loadingUserList ? (null) : (
                            <Box sx={{width:'100%', display:'-ms-flexbox', justifyContent:'center' }}>
                                <Box sx={{width:'100%', display:'flex', justifyContent:'start'}}>
                                    <AccountCircleIcon sx={{fontSize:'35px', color:'gray'}}/>
                                    <Typography textAlign='start' variant="h6" sx={{marginLeft:2}}>
                                        {userList[selectedIndex].username}
                                    </Typography>
                                </Box>
                                <Divider sx={{opacity:0.75}}/>
                                <br />
                                <Typography textAlign='start' variant="h6" sx={{marginLeft:2}}>
                                        Permissions
                                </Typography>
                                <Divider sx={{opacity:1}}/>
                                <br />
                                {[...userList[selectedIndex].permissions.value].map((item, ind) => {
                                    if(item==="1"){
                                        return(
                                            <Chip key={ind} sx={{display:item===0?('none'):('')}}
                                                
                                                label={getPermissionValue(ind)}
                                            >
                                                
                                            </Chip>
                                        )
                                    }
                                    
                                })
                                    
                                }
                                
                            </Box>
                            
                            )}
                            
                        </Box>
                         
                    </Grid>
                    <Grid Item xs={4}>
                        <Box sx={{width:'100%', justifyContent:'center'}}>
                            <Typography textAlign='center' variant="h5">
                                Permissions list
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                 
            </Box>

        <Box>
            <Dialog
                open={openCreateUserDialog}
                onClose={handleCloseCreateUserDialog}
                maxWidth={"sm"}
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit : (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const modifiedJson = {
                            "username":formJson.username,
                            "password":formJson.password,
                            "server":auth.server,
                            "permissions":{
                                "role":formJson.role,
                                "value":EncodePermStringArray(newUserPerms)
                            }
                        }
                        if(openInEdit){
                            axios.post("/users/updateById",{
                                "id":userList[selectedIndex]._id,
                                "username":formJson.username,
                                "password":formJson.password,
                                "server":auth.server,
                                "permissions":{
                                    "role":formJson.role,
                                    "value":EncodePermStringArray(newUserPerms)
                                }
                            })
                            setOpenInEdit(false);
                        }else{
                            axios.post('/users/add' ,modifiedJson)
                            .then((res) => {
                                var temp = res.data;
                                console.log(temp)
                                setUserList(userList => [...userList, temp])
                                //add user to list
                            })
                            .catch((err) => {
                                console.log(err);
                            })
                        }
                        
                        
            
                        handleCloseCreateUserDialog();
                        
                        
                    }
                }}
            >
                <DialogTitle>Create new user</DialogTitle>
                <DialogContent>
                    
                    <DialogContentText>
                        Fill in the user info
                    </DialogContentText>
                    <br />
                    <Box sx={{display:'flex-inline'}}>
                        <TextField 
                            sx={{m: 1, width:300}}
                            label="User Name"
                            id="user"
                            name="username"
                            type="text"
                            autoComplete="off"
                            margin="dense"
                            variant="standard"
                            defaultValue={openInEdit?userList[selectedIndex].username:""}
                        />
                        <br />
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TextField 
                                sx={{m: 1, width:300}}
                                label="Password"
                                id="password"
                                name="password"
                                type={!showPassword?"password":"text"}
                                margin="dense"
                                variant="standard"
                                
                            />
                            <IconButton  onClick={() => {setShowPassword(showPassword?false:true)}}>
                            {showPassword? <VisibilityIcon></VisibilityIcon> : <VisibilityOffIcon></VisibilityOffIcon>}
                            </IconButton>
                        </Box>
                        
                        <br />
                        <Typography sx={{marginLeft:1, fontWeight:'bold', fontFamily:'Open Sans'}} variant="body1">User Permissions</Typography>
                        <DialogContentText sx={{marginLeft:1}}>User role description and permissions</DialogContentText>
                        <TextField 
                            sx={{marginLeft:1, width:300}}
                            label="Role"
                            id="Role"
                            name="role"
                            autoComplete="off"
                            type="text"
                            margin="dense"
                            variant="standard"
                        />
                        
                        
                        <FormControl sx={{m: 1, width:300}}>
                            <InputLabel id="multiSelectLabel">Permissions</InputLabel>
                            <Select
                            labelId="multiSelectLabel"
                            multiple
                            value={newUserPerms}
                            onChange={handleChangeSelect}
                            name="value"
                            //defaultValue={openInEdit?DecodePermValueToString(userList[selectedIndex].permissions.value):""}
                            input={<OutlinedInput id="select-multiple-chip" label="Permissions"/>}
                            renderValue={(selected) => (
                                <Box sx={{display:'flex', flexWrap:'wrap', gap:0.5}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                                {permissionArr.map((perm) => (
                                    <MenuItem
                                        key={perm}
                                        value={perm}
                                        
                                    >
                                        {perm}
                                    </MenuItem>
                                ))

                                }
                            </Select>
                        </FormControl>
                        <br />
                        <Button variant="contained" color="error">Delete User</Button>
                    </Box>
                    
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleCloseCreateUserDialog()} >
                        Back
                    </Button>
                    <Button variant="contained" type="submit" onClick={() => {console.log(newUserPerms); console.log(EncodePermStringArray(newUserPerms))}}>
                        Submit
                    </Button>
                    
                </DialogActions>
            </Dialog>
        </Box>
        </Box>
    )
}