import { Box, Grid, Paper, styled, IconButton, Button, Typography, Stack, Tooltip} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import { useEffect, useState , useRef, useContext,useCallback} from "react";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function FormFolder({folder, openFolderCB, children_n}) {

    const [mouseOver, setMouseOver] = useState(false);
    const [childAmount, setChildAmount] = useState(0);

    useEffect(() => {
      setChildAmount(children_n)
    }, [])
    

    return(
        <Item 
            onMouseEnter={() => setMouseOver(true)} 
            onMouseLeave={() => setMouseOver(false)} 
            sx={{backgroundColor:folder.color, height:'100%', width:'100%', filter:mouseOver?'brightness(95%)':null, transition: "all 0.5s ease",}}
            
        >

            <Box sx={{display:'flex', width:'100%', justifyContent:'start'}}>
                <Box sx={{position:'absolute', paddingLeft:1, paddingRight:1, margin: 1, backgroundColor:folder.color, borderRadius:2}}>
                    <Tooltip title="Items in folder">
                        <Typography variant="h5" fontWeight={'bold'} fontFamily={'Open Sans'} sx={{color:'black'}}>
                            {childAmount}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={{justifyContent:'center',height:'100%', width:'100%', alignItems:'center', alignContent:'center',}}>
                <Stack  sx={{justifyContent:'center', alignItems:'center',}}>
                    <IconButton onClick={() => openFolderCB(folder)}>
                        <FolderOpenIcon sx={{fontSize:66, color:folder.color, filter:'brightness(80%)'}}/>
                    </IconButton>
                    
                    <Typography variant='h6' noWrap={false} sx={{fontFamily:'Open Sans', color:'black'}}>
                        {folder.name}
                    </Typography>
                </Stack>
            </Box>
        </Item>
    )
}