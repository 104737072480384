import { Box, Grid, Paper, styled, Button, Typography, Fade, IconButton} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import { useEffect, useState , useRef, useContext,useCallback} from "react";

export default function WorkStepsOverview() {

    return(
        <Box sx={{justifyContent:'center', alignItems:'center', padding: 2}}>
            <Typography variant="h4" fontFamily={'Open Sans'} fontWeight={'bold'}>
                Overview
            </Typography>
            
        </Box>
    )
}