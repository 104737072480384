import { FunctionsSharp } from '@mui/icons-material';
import dayjs from 'dayjs'
import { useState, useEffect } from 'react';

const defaultPermissionValue = {
    "ViewForms":false,
    "ViewQueries":false,
    "Create":false,
    "Edit":false,
    "Delete":false,
    "Fill":false,
    "Queries":false,
    "Authorization":false
};

const defaultPermissionStringArray = [
    "ViewForms",
    "ViewQueries",
    "Create",
    "Edit",
    "Delete",
    "Fill",
    "Queries",
    "Authorization"
]

export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const matchqlist = window.matchMedia(query);
        function handleChange(e){
            setMatches(e.matches);
        }
        matchqlist.addEventListener("change", handleChange);
        
        
        return () => {
            matchqlist.removeEventListener("change", handleChange);
        }
    }, [query]);
    
    return matches;
}


export function PermParser(value){
    if(value && Object.keys(defaultPermissionValue).length === value.length){
        const asd = value;
        let tempperm = defaultPermissionValue;
        let i = 0;
        Object.keys(tempperm).forEach(key => {
            tempperm[key] = Boolean(Number(asd[i]));
            i++;
        });
        return tempperm;
    } else {
        return {
            "error": "Invalid permission value"
        }
    }
}

export function EncodePermStringArray(value) {
    if(value !== null && Array.isArray(value)){
        var tempcode = ""
        const temparr = defaultPermissionValue;
        Object.keys(temparr).forEach(obj => {
            //console.log(obj)
            if(value.includes(obj)){
                tempcode += "1";
            } else {
                tempcode +="0";
            }
        });
        return tempcode;
    }else{
        return null;
    }
}

export function DecodePermValueToString(value){
    if(value !== null){
        var tempString = "";
        for(var i = 0; i < value.length; i++){
            if(value[i] === "0"){
                
            }else if(value[i] === "1"){
                tempString += defaultPermissionStringArray[i]+","
            }
        }
        return tempString;
    }
}

export function PermObjToArray(value){
    if(value){
        const temparr = [];
        Object.keys(value).forEach(key => {
            if(value[key]) {temparr.push(key)}
        })
        return temparr;
    } else {
        return null;
    }
}

export const formatDate = (date) => {
    console.log(date);
    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getYear();

    var formattedDay = day < 10 ? "0" + day : day;
    var formattedMonth = month < 10 ? "0" + month : month;

    return formattedDay+"/"+formattedMonth+"/"+year;
}

export function UserHasPermissions(auth, perms){
    if(auth !== undefined){
        //console.log(auth.find(p => perms.includes(p)))
        //console.log(auth);
        //return auth.find(p => perms.includes(p));
        if(auth.find(p => perms.includes(p)) != undefined){
            return true;
        }
        return false
    }
    return false;
}

//Returns true if authcontext has property user => assigned at login 
export function UserHasLoggedIn(auth){
    return auth !== undefined && 'user' in auth;
}

/*
Parser object output for default admin role
{
    "ViewForms":true,
    "ViewQueries":true,
    "Create":true,
    "Edit":true,
    "Delete":true,
    "Fill":true,
    "Queries":true,
    "Authorization":true,
}*/