import { Box, Grid, Paper, styled, Button, Typography, Fade, IconButton} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import { useEffect, useState , useRef, useContext,useCallback} from "react";
import WorkStepsOverview from "./worksteps-overview.component";
import WorkStepsSidebar from "./worksteps-sidebar.component";
import WorkStepsOperations from "./worksteps-operations.component";

export default function WorkSteps() {

    //Container/Wrapper 🥴 Workstep dashboard/statistics/overview/
    
    const stages = Object.freeze({
        Overview: 0,
        Operations: 1,
        Stats: 2,
        Accounts: 3,
        Settings: 4

    })

    const [currentStage,setCurrentStage] = useState(stages.Overview);

    const CurrentStageComponent = () => {
        switch(currentStage){
            default:
                return(
                    <WorkStepsOverview/>
                )
            case stages.Overview:
                return(
                    <WorkStepsOverview/>
                )
            case stages.Operations:
                return(
                    <WorkStepsOperations/>
                )
                    
        }
    }

    function StageSwitch(stage){
        setCurrentStage(stage);
        console.log(stage);
    }

    return(
        <Grid container columns={12} columnSpacing={3} >
            <Grid xs={1} item>
                <Box sx={{ height:'90vh', width:'100%', backgroundColor:'#f7f7f7', borderRadius:5}}>
                    <WorkStepsSidebar stageSwitch={StageSwitch} currentStage={currentStage}/>
                </Box>
            </Grid>
            <Grid xs={11} item>
                <Box sx={{height:'90vh', width:'100%', backgroundColor:'#f7f7f7', borderRadius:5}}>
                    <CurrentStageComponent/>
                </Box>
            </Grid>
        </Grid>
    )
}