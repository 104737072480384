import React, { useEffect, useState, useContext } from 'react';
import {Link,useNavigate, useLocation} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { UserHasLoggedIn } from '../api/utils';
import { Collapse, AppBar, Toolbar, Box,Typography,Menu,MenuItem,Button,Container,IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AuthContext from '../context/AuthProvider';
import LogoutIcon from '@mui/icons-material/Logout';

export default function Navbar() {

    const {auth} = useAuth();
    const formperms = ['ViewForms'];
    const [openNavbar, setOpenNavbar] = useState(false)
    const [anchorElNav, setAnchorElNav] = useState(null);
    const {setAuth} = useContext(AuthContext)

    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    useEffect(() => {
      if(UserHasLoggedIn(auth)){
        setOpenNavbar(true);
      } else {
        setOpenNavbar(false);
      }
    }, [auth])

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/');
    }
    

    return(
        
        //UserHasLoggedIn(auth)
        //?
        <Collapse orientation='vertical' in={openNavbar}>
            <AppBar position='static' sx={{backgroundColor:'#212529'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{backgroundColor:'#212529'}}>
                        <Box sx={{width:'100%',display: {xs:'none', md:'flex'}, justifyContent:'space-between'}}>
                            <Button sx={{color:'white', display: {xs: 'none', md: 'flex'}}} onClick={() => navigate('/')}>
                                <Typography variant='h6'  noWrap sx={{
                                    display: {xs: 'none', md: 'flex'},
                                    
                                    
                                    fontFamily: 'Open Sans',
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color:  'white'
                                }}>
                                    FormDemo
                                </Typography>
                            </Button>
                            <Box sx={{flexGrow: 1, display:{xs:'none', md:'flex'}}}>
                                <Button
                                onClick={() => navigate('/forms')}
                                sx={{my: 2, color:'white', backgroundColor:'#212529', display:'block'}}
                                >
                                    Forms
                                </Button>
                                <Button
                                onClick={() => navigate('/steps/dashboard')}
                                sx={{my: 2, color:'white', backgroundColor:'#212529', display:'block'}}
                                >
                                    Queries
                                </Button>
                                <Button
                                onClick={() => navigate('/tickets')}
                                sx={{my: 2, color:'white', backgroundColor:'#212529', display:'block'}}
                                >
                                    Tickets
                                </Button>
                                <Button
                                onClick={() => navigate('/account', {state:{openInManage: true}})}
                                sx={{my: 2, color:'white', backgroundColor:'#212529', display:'block'}}
                                >
                                    Account Management
                                </Button>
                            </Box>
                            <Button sx={{color:'white', display: {xs: 'none', md: 'flex'}, }} onClick={() => logout()}>
                                <Typography variant='h6'  noWrap sx={{
                                    display: {xs: 'none', md: 'flex'},
                                    
                                    
                                    fontFamily: 'Open Sans',
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color:  'white'
                                }}>
                                    Sign out
                                </Typography>
                            </Button>
                        </Box>

                        
                        
                        <Box sx={{flexGrow: 1, display:{xs:'flex', md:'none'}}}>
                            <IconButton
                                size='xl'
                                onClick={handleOpenNavMenu}
                                color='white'

                            >
                                <MenuIcon fontSize='large' sx={{color:'white', }}/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorElNav}
                                anchorOrigin={{vertical:'bottom', horizontal:'left'}}
                                keepMounted
                                transformOrigin={{vertical:'top',horizontal:'left'}}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs:'block', md: 'none'}
                                }}
                            >
                                <MenuItem onClick={() => {
                                    handleCloseNavMenu();
                                    navigate('/forms');
                                }}>
                                    <Typography textAlign='center'>Forms</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {handleCloseNavMenu(); navigate('/queries');}}>
                                    <Typography textAlign='center'>Queries</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {handleCloseNavMenu(); navigate('/tickets');}}>
                                    <Typography textAlign='center'>Tickets</Typography>
                                </MenuItem>
                                <MenuItem onClick={() => {handleCloseNavMenu(); navigate('/account');}}>
                                    <Typography textAlign='center'>Account Management</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        
                        <Box sx={{flexGrow: 1, display:{xs:'flex', md:'none'}}}>
                            <Button sx={{display: {xs: 'flex', md: 'none'}}} onClick={() => navigate('/')}>
                                <Typography variant='h6' noWrap sx={{
                                    display: {xs: 'flex', md: 'none'},
                                    
                                    mr: 2,
                                    fontFamily: 'Open Sans',
                                    fontWeight: 700,
                                    letterSpacing: '.1rem',
                                    color:  'white'
                                }}>
                                    FormDemo
                                </Typography>
                            </Button>
                        </Box>
                        <Box sx={{flexGrow: 1, display:{xs:'flex', md:'none'}}}>
                            <IconButton 
                            size='xl'
                            color='white'
                            onClick={() => logout()}>
                                <LogoutIcon fontSize='large' sx={{color:'white', }}/>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Collapse>
        
        //:
        //<div></div>
    );
}
/*
<nav className='navbar navbar-dark bg-dark navbar-expand-lg'>
                <Link to="/" className='navbar-brand'>infoquery demo</Link>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                        {auth?.permissions?.find(perm => formperms.includes(perm))
                            ? (
                                <li className="navbar-item">
                                    <Link to="/forms" className='nav-link'>Forms</Link>
                                </li>
                            )
                            : (
                                <div></div>
                            )
                        }
                        {auth?.permissions?.find(perm => ['ViewQueries'].includes(perm))
                            ? (
                                <li className="navbar-item">
                                    <Link to="/queries" className='nav-link'>Queries</Link>
                                </li>
                            )
                            : (
                                <div></div>
                            )
                        }
                        {auth?.permissions?.find(perm => ['Authorization'].includes(perm))
                            ? (
                                <li className="navbar-item">
                                    <Link to="/account" state={{openInManage: true}} className='nav-link'>Account Management</Link>
                                </li>
                            )
                            : (
                                <div></div>
                            )
                        }
                        {auth?.permissions?.find(perm => ["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"].includes(perm))
                            ? (
                                <li className="navbar-item">
                                    <Link to="/tickets" state={{openInManage: true}} className='nav-link'>Tickets</Link>
                                </li>
                            )
                            : (
                                <div></div>
                            )
                        }


                    </ul>
                </div>

                <div className='d-flex justify-content-center align-items-end'>
                    <ul className="navbar-nav ">
                        <li className="navbar-item">
                            <Link to="/" className='nav-link'>Sign Out</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/account" state={{openInManage: false}} className='nav-link' >Account</Link>
                        </li>
                        
                        
                    </ul>
                </div>
            </nav>
*/