//ERROR: "Cannot read properties of undefined (reading 'params')"
// tutustu uuteen router id hommaan asda sd


import React, { Component, useEffect, useState, useRef } from 'react';


import {Link, useParams} from 'react-router-dom';
import dayjs from 'dayjs'

import Grid from '@mui/material/Unstable_Grid2';
import { createTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from 'axios';


import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import AbcIcon from '@mui/icons-material/Abc';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ListIcon from '@mui/icons-material/List';
import ImageIcon from '@mui/icons-material/Image';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Switch from '@mui/material/Switch';
import { Box, Typography, Tooltip, TextField, Chip } from '@mui/material';
import { MonthCalendar } from '@mui/x-date-pickers';
import LinearProgress from '@mui/material/LinearProgress';
import { TimeField } from '@mui/x-date-pickers/TimeField';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: "all 0.5s ease",
    WebkitTransition: "all 0.5s ease",
    MozTransition: "all 0.5s ease" 
  }));

// FillDataRow is the Form row class for Open Form view
// Filldatarow has to show the custom features for the specified row datatype.

//ToDo
/*
  1. Currently each of the row specific logic is in switch case
*/

export default function FillDataRow({instruction, querycallback, isReadOnly, injectQueryValue}) {

    const refCont = useRef();
    const [targetHeight, setTargetHeight] = useState(50)
    const [refHasLoaded, setRefHasLoaded] = useState();
    const [hoverActive0, setHoverActive0] = useState(false);
    const [hoverActive1, setHoverActive1] = useState(false);
    const [hoverActive2, setHoverActive2] = useState(false);

    const [inputtext, setInputText] = useState('');
    const [inputNumber, setInputNumber] = useState(0);
    const [inputBool, setInputBool] = useState(false);
    const [inputDate, setInputDate] = useState(dayjs());
    const [inputTime, setInputTime] = useState(dayjs());
    const [inputFile, setInputFile] = useState('');
    const [displayImg, setDisplayImg] = useState('');
    const [inputListRows, setInputListRows] = useState([]);
    const [inputStopwatchTime, setInputStopwatchTime] = useState(new Date(0,0,0,0,0,0));

    const [inputListIDCounter, setListIDCounter] = useState(123);
    const [inputListRowCounter, setListRowCounter] = useState(0);

    //Stopwatch utility
    const [swIsActive, setSwIsActive] = useState(false);
    const [swIsPaused, setSwIsPaused] = useState(true);
    const [swIntervalId, setSwIntervalId] = useState(null);

    const handleStartSW = () => {
        setSwIsActive(true);
        setSwIsPaused(false);
        const id = setInterval(() => {
            setInputStopwatchTime(prevTime => new Date(prevTime.getTime() + 1000));
        }, 1000);
        setSwIntervalId(id);
    }

    const handlePauseSW = () => {
        clearInterval(swIntervalId);
        setSwIsPaused(true);
        console.log("time: "+inputStopwatchTime.toTimeString());
    }

    const handleResumeSW = () => {
        setSwIsPaused(false);
        const id = setInterval(() => {
            setInputStopwatchTime(prevTime => new Date(prevTime.getTime() + 1000));
        }, 1000);
        setSwIntervalId(id);
    }

    const handleResetSW = () => {
        clearInterval(swIntervalId);
        setSwIsActive(false);
        setSwIsPaused(true);
        setInputStopwatchTime(new Date(0,0,0,0,0,0));
    }

    useEffect(() => {
        return () => clearInterval(swIntervalId);
    }, [swIntervalId]);

    useEffect(() => {
        switch(instruction.property_type){
            default:
                querycallback({
                    query: instruction.property_name,
                    input: inputtext,
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'Text':
                querycallback({
                    query: instruction.property_name,
                    input: inputtext,
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'Number':
                querycallback({
                    query: instruction.property_name,
                    input: inputNumber.toString(),
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'Toggle':
                querycallback({
                    query: instruction.property_name,
                    input: inputBool,
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'Date':
                querycallback({
                    query: instruction.property_name,
                    input: inputDate.toString(),
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'Image':
                querycallback({
                    query: instruction.property_name,
                    input: inputFile,
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'List':
                querycallback({
                    query: instruction.property_name,
                    input: inputListRows,
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case 'Time':
                querycallback({
                    query: instruction.property_name,
                    input: inputTime,
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;
            case "Stopwatch":
                console.log(inputStopwatchTime.toTimeString())
                querycallback({
                    query: instruction.property_name,
                    input: inputStopwatchTime.toTimeString(),
                    input_type: instruction.property_type,
                    input_required: instruction.require_field
                })
                break;

        }
    },[inputtext, inputNumber, inputBool, inputDate, inputFile, inputListRows, inputStopwatchTime]);

    useEffect(() => {
        setRefHasLoaded(false);
        if(instruction.property_type === 'List'){
            if(instruction !== undefined && instruction !== null){
                instantiateListRows(instruction)
            }
        }
        determineTargetHeight(refCont)

        return () => {
            console.log(inputListRows)
            setRefHasLoaded(true);
        }
        
    }, [])

    useEffect(() => {
        determineTargetHeight(refCont);
    },[inputFile])

    const determineTargetHeight = (ref) => {
        
        if(ref.current){
            console.log(ref.current.offsetHeight);
            if(ref.current.offsetHeight > targetHeight){
                setTargetHeight(ref.current.offsetHeight);
            }
        }
    }
    
    const GetIconByType = ({type}) => {
        switch(type){
            default:
                return(
                    <AbcIcon/>
                )
            case 'Text':
                return(
                    <AbcIcon/>
                )
            case 'Number':
                return(
                    <NumbersIcon/>
                )
            case 'Toggle':
                return(
                    <ToggleOnIcon/>
                )
            case 'Image':
                return(
                    <ImageIcon/>
                )
            case 'Date':
                return(
                    <CalendarMonthIcon/>
                )
            case 'List':
                return(
                    <ListIcon/>
                )
            case 'Time':
                return(
                    <AvTimerIcon/>
                )
        }
    }
    
    const onChangeTextInput = (e) => {
        setInputText(e.target.value)
        
    }
    const onChangeNumberInput = (e) => {
        setInputNumber(e.target.value)
        
    }
    const onChangeBoolInput = (e) => {
        setInputBool(e.target.checked)
        
    }
    const onChangeDateInput = (e) => {
        setInputDate(e);
    }
    const onChangeTimeInput = (e) => {
        setInputTime(e);
    }
    const onChangeFileInput = (e) => {
        if(e.target.files[0]){
            setInputFile(e.target.files[0]);
            setDisplayImg(URL.createObjectURL(e.target.files[0]));
        }
        //URL.createObjectURL(e.target.files[0])
        /*
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            console.log(reader.result);
            setInputFile(reader.result);
        };
        reader.onerror = error => {
            console.log('Error: ', error);
        }
        */
    }

    

    const instantiateListRows = (inst) => {
        const startRow = inputListRowCounter;
        const startID = inputListIDCounter;
        const additinfo = inst.additional_info;
        const x = Object.keys(additinfo).length;
        console.log(additinfo);
        for(let i = 0; i<x ; i++){
            const newRow = startRow + i;
            const newID = startID + i;
            console.log(additinfo[i]);

            setInputListRows(inputListRows => [...inputListRows, {'row':additinfo[i].row, 'id':newID, 'field_name':additinfo[i].property_name,'input_type':additinfo[i].property_type, 'input_value':''}])
            //console.log(rowItems);
            
        }
        setListRowCounter(inputListRowCounter => inputListRowCounter + x);
        setListIDCounter(inputListIDCounter => inputListIDCounter + x);
        console.log(inputListRows);
        //console.log(inputListIDCounter);
        //console.log("added "+x+" rows")
    }

    const ListItemInputField = ({type, value, onChange}) => {
        const handleChange = ({target}) => {
            if(typeof onChange === 'function'){
                onChange(target.value)
            }
        }
        switch(type){
            default:
                
                return(
                    <input value={value} onChange={handleChange} style={{width:'100%', minHeight:50,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
                )
            case 'Number':
                
                return(
                    <input value={value} onChange={onChange} placeholder='Number Input...' style={{width:'100%', minHeight:50,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="number" />
                )
            case 'Text':
                
                return(
                    <input value={value} onChange={onChange} placeholder='Text Input...' style={{width:'100%', minHeight:50,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
                )
            case 'Toggle':
                
                return(
                    
                    <Item className='d-flex align-items-center justify-content-center' 
                        style={{
                            minHeight:50, 
                            height:targetHeight,
                            
                            }}>
                            <Switch value={value} onChange={onChange} size='small'/>
                        </Item> 

                ) 
        }
    }

    const getInputListDataRowValue = (id) => {
        inputListRows.map(row  => {
            if(row.id === id) {
                return row.input_value;
            }
        })
        return "";
    }

    const updateListDataRowsInput = (e,id) => {
        const updatedRowdatasets = inputListRows.map(rowdata => {
            if(rowdata.id === id){
                switch(rowdata.input_type){
                    default:
                        return {
                            ...rowdata,
                            input_value: e.target.value,
        
                        };
                    case "Text":
                        return {
                            ...rowdata,
                            input_value: e.target.value,
        
                        };
                    case "Number":
                        return {
                            ...rowdata,
                            input_value: e.target.value,
        
                        };
                    case "Toggle":
                        return {
                            ...rowdata,
                            input_value: e.target.checked,
        
                        };
                }
                
            } else {
                return rowdata;
            }
        })
        setInputListRows(updatedRowdatasets);
        console.log(inputListRows);
    }

    
    switch(instruction.property_type){
        default:
            return(
                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}><Item>DEBUG</Item></Grid>
                    <Grid xs={7} style={{height:'100%'}}><Item>ERROR</Item></Grid>
                    <Grid xs={8} style={{height:'100%'}}><Item>ERROR UNKNOWN PROPERTY_TYPE IN fill-datarow.component.js</Item></Grid>
                </Grid>
            )
        case 'Text':
            return(
                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive0(true)} 
                        onMouseLeave={() => setHoverActive0(false)} 
                        ref={refCont}
                        style={{
                            minHeight: 50,
                            height: targetHeight,
                            background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            {instruction.row}
                        </Item>
                    </Grid>
                    <Grid xs={7} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive1(true)} 
                        onMouseLeave={() => setHoverActive1(false)} 
                        ref={refCont}
                        style={{
                            minHeight: 50,
                            background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                            height: targetHeight,
                            }}>
                            
                                <Box sx={{
                                    display:'flex',
                                    backgroundColor:'#777777',
                                    borderRadius: 3,
                                    color:"white",
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    paddingLeft: 1,
                                    paddingRight: 1
                                    }}>
                                    <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                        {instruction.property_name}
                                    </Typography>
                                    
                                    <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                        <Box sx={{marginLeft: 1}}>
                                            <GetIconByType type={instruction.property_type} />
                                        </Box>
                                        
                                    </Tooltip>
                                    {!instruction.require_field?(null):
                                    (
                                        <Tooltip title="Field is required and cannot be left empty">
                                            <PriorityHighIcon fontSize='small'/>
                                        </Tooltip>
                                    )
                                    }
                                    
                                </Box>
                            
                        </Item>
                    </Grid>
                    <Grid xs={8} style={{height:'100%'}}>
                        <Box>
                            <TextField 
                                InputProps={{
                                    readOnly: isReadOnly ? (true) : (false),
                                    disableUnderline: isReadOnly ? (true) : (false)
                                }}

                                onMouseEnter={() => setHoverActive2(true)} 
                                onMouseLeave={() => setHoverActive2(false)} 
                                ref={refCont}
                                onChange={onChangeTextInput}
                                label='Text Input'
                                defaultValue={isReadOnly&&injectQueryValue!==undefined? (injectQueryValue.input ): (inputtext)}
                                variant='filled'
                                type='text'
                                sx={{
                                    width:'100%', 
                                    minHeight:50,  
                                    
                                    padding: '2 2 2 2',
                                    border:'0', 
                                    background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "#EFEFEF",
                                    
                                    borderColor:'#E5E5E5', 
                                    borderRadius:2,
                                    transition: "all 0.5s ease",
                                    WebkitTransition: "all 0.5s ease",
                                    MozTransition: "all 0.5s ease" 
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            )
        case 'Number':
            return(

                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive0(true)} 
                        onMouseLeave={() => setHoverActive0(false)} 
                        ref={refCont}
                        style={{
                            minHeight: 50,
                            height: targetHeight,
                            background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                                {instruction.row}
                        </Item>
                    </Grid>
                    <Grid xs={7} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive1(true)} 
                        onMouseLeave={() => setHoverActive1(false)} 
                        ref={refCont}
                        style={{
                            minHeight: 50,
                            height: targetHeight,
                            background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            <Box sx={{
                                display:'flex',
                                backgroundColor:'#777777',
                                borderRadius: 3,
                                color:"white",
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingLeft: 1,
                                paddingRight: 1,
                                
                                }}>
                                <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18} ,justifyContent:'center'}}>
                                    {instruction.property_name}
                                </Typography>
                                <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                    <Box sx={{marginLeft: 1}}>
                                        <GetIconByType type={instruction.property_type} />
                                    </Box>
                                </Tooltip>
                                {!instruction.require_field?(null):
                                (
                                    <Tooltip title="Field is required and cannot be left empty">
                                        <PriorityHighIcon fontSize='small'/>
                                    </Tooltip>
                                )
                                }
                            </Box>
                        </Item>
                    </Grid>
                    <Grid xs={8} style={{height:'100%'}}>
                        {/* Move to Mui textfield */}
                        <Box>
                            <TextField 
                                InputProps={{
                                    readOnly: isReadOnly ? (true) : (false),
                                    disableUnderline: isReadOnly ? (true) : (false)
                                }}

                                onMouseEnter={() => setHoverActive2(true)} 
                                onMouseLeave={() => setHoverActive2(false)} 
                                ref={refCont}
                                onChange={onChangeNumberInput}
                                label='Number Input'
                                value={isReadOnly&&injectQueryValue!==undefined? (injectQueryValue.input.toString()): (inputNumber)}
                                variant='filled'
                                type='number'
                                sx={{
                                    width:'100%', 
                                    minHeight:50,  
                                    
                                    padding: '2 2 2 2',
                                    border:'0', 
                                    background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "#EFEFEF",
                                    
                                    borderColor:'#E5E5E5', 
                                    borderRadius:2,
                                    transition: "all 0.5s ease",
                                    WebkitTransition: "all 0.5s ease",
                                    MozTransition: "all 0.5s ease" 
                                }}
                            />
                        </Box>
                        {/*
                        <input placeholder='Number Input...' onChange={onChangeNumberInput} 
                        onMouseEnter={() => setHoverActive2(true)} 
                        onMouseLeave={() => setHoverActive2(false)} 
                        style={{
                            width:'100%', 
                            minHeight:50,  
                            padding: '2 2 2 2', 
                            border:'0', 
                            background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "#EFEFEF",
                            
                            borderColor:'#E5E5E5', 
                            borderRadius:5,
                            transition: "all 0.5s ease",
                            WebkitTransition: "all 0.5s ease",
                            MozTransition: "all 0.5s ease" 
                            }} type="number" />    
                            */}
                    </Grid>
                </Grid>

            )
        case 'Toggle':
            return(
                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' ref={refCont} 
                        onMouseEnter={() => setHoverActive0(true)} 
                        onMouseLeave={() => setHoverActive0(false)} 
                        style={{
                            minHeight: 50 , 
                            height:targetHeight,
                            background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                                {instruction.row}</Item></Grid>
                    <Grid xs={7} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' ref={refCont} 
                        onMouseEnter={() => setHoverActive1(true)} 
                        onMouseLeave={() => setHoverActive1(false)} 
                        style={{
                            minHeight: 50 , 
                            height:targetHeight,
                            background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            <Box sx={{
                                display:'flex',
                                backgroundColor:'#777777',
                                borderRadius: 3,
                                color:"white",
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingLeft: 1,
                                paddingRight: 1
                                }}>
                                <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                    {instruction.property_name}
                                </Typography>
                                <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                    <Box 
                                    
                                    sx={{
                                        marginLeft: 1,
                                        }}>
                                        <GetIconByType type={instruction.property_type} />
                                    </Box>
                                </Tooltip>
                                {!instruction.require_field?(null):
                                (
                                    <Tooltip title="Field is required and cannot be left empty">
                                        <PriorityHighIcon fontSize='small'/>
                                    </Tooltip>
                                )
                                }
                            </Box>
                        </Item>
                    </Grid>
                    <Grid xs={8} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive2(true)} 
                        onMouseLeave={() => setHoverActive2(false)} 
                        style={{
                            minHeight:50, 
                            height:targetHeight,
                            background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            <Switch onChange={onChangeBoolInput} ref={refCont} size='large' checked={isReadOnly&&injectQueryValue!==undefined? (injectQueryValue.input): (inputBool)} InputProps={{
                                    readOnly: isReadOnly ? (true) : (false),
                                    disableUnderline: isReadOnly ? (true) : (false)
                                }}/>
                        </Item> 
                    </Grid>
                </Grid>
            )
        case 'Date':
            return(
                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive0(true)} 
                        onMouseLeave={() => setHoverActive0(false)} 
                        style={{
                            minHeight: 50, 
                            height:targetHeight,
                            background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }} 
                        ref={refCont}
                        >
                            {instruction.row}
                        </Item>
                    </Grid>
                    <Grid xs={7} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center'
                        onMouseEnter={() => setHoverActive1(true)} 
                        onMouseLeave={() => setHoverActive1(false)}  
                        style={{
                            minHeight: 50, 
                            height:targetHeight,
                            background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            <Box sx={{
                                display:'flex',
                                backgroundColor:'#777777',
                                borderRadius: 3,
                                color:"white",
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingLeft: 1,
                                paddingRight: 1
                                 }} ref={refCont} >
                                <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                    {instruction.property_name}
                                </Typography>
                                <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                    <Box sx={{marginLeft: 1}}>
                                        <GetIconByType type={instruction.property_type} />
                                    </Box>
                                </Tooltip>
                                {!instruction.require_field?(null):
                                (
                                    <Tooltip title="Field is required and cannot be left empty">
                                        <PriorityHighIcon fontSize='small'/>
                                    </Tooltip>
                                )
                                }
                            </Box>
                        </Item>
                    </Grid>
                    <Grid xs={8} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive2(true)} 
                        onMouseLeave={() => setHoverActive2(false)} 
                        style={{
                            minHeight:50, 
                            height:targetHeight,
                            background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "white"
                            }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        
                                <DatePicker 
                                    
                                    readOnly={isReadOnly}
                                    ref={refCont}
                                    defaultValue={isReadOnly&&injectQueryValue? (dayjs(injectQueryValue.input)): (inputDate)}
                                    onChange={onChangeDateInput}
                                />
                            
                            </LocalizationProvider>
                        </Item> 
                    </Grid>
                </Grid>
            )
        case 'Image':
            return(
                    <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                        <Grid xs={1} style={{height:'100%'}}>
                            <Item className='d-flex align-items-center justify-content-center' 
                            onMouseEnter={() => setHoverActive0(true)} 
                            onMouseLeave={() => setHoverActive0(false)} 
                            ref={refCont}
                            style={{
                                minHeight: 50,
                                height:targetHeight,
                                background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                                }}>{instruction.row}</Item></Grid>
                        <Grid xs={7} style={{height:'100%'}}>
                            <Item className='d-flex align-items-center justify-content-center' 
                            onMouseEnter={() => setHoverActive1(true)} 
                            onMouseLeave={() => setHoverActive1(false)} 
                            ref={refCont}
                            style={{
                                background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                                height:targetHeight,
                                minHeight: 50
                                }}>
                                <Box sx={{
                                    display:'flex',
                                    backgroundColor:'#777777',
                                    borderRadius: 3,
                                    color:"white",
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    paddingLeft: 1,
                                    paddingRight: 1
                                    }}>
                                    <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                        {instruction.property_name}
                                    </Typography>
                                    <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                        <Box sx={{marginLeft: 1}}>
                                            <GetIconByType type={instruction.property_type} />
                                        </Box>
                                    </Tooltip>
                                    {!instruction.require_field?(null):
                                    (
                                        <Tooltip title="Field is required and cannot be left empty">
                                            <PriorityHighIcon fontSize='small'/>
                                        </Tooltip>
                                    )
                                    }
                                </Box>
                            </Item>
                        </Grid>
                        <Grid xs={8} style={{height:'100%',}}>
                            <Box sx={{display:'flex', alignContent:'center', minHeight:50, }} ref={refCont}>
                                {isReadOnly && injectQueryValue !== undefined && injectQueryValue !== null ?
                                (
                                    <img src={injectQueryValue.input} alt="" width={500} height={200}/>
                                ) : (
                                    <Box sx={{display:'flex'}}>
                                        <Button variant='contained' component='label' endIcon={<AttachFileIcon />}>
                                            <Typography sx={{fontFamily:'Open Sans', fontSize:{xs:11, md:15}}}>
                                                Upload File
                                            </Typography>
                                            <input onChange={onChangeFileInput} 
                                                hidden
                                                type="file"
                                                accept='image/*'
                                            />
                                        </Button>
                                        <Box sx={{display:'flex', alignContent:'center', justifyContent:'center'}}>
                                            {displayImg ? (
                                                <Typography noWrap={false} sx={{display:'flex', fontSize:13,marginLeft:{xs:2,md:8,lg:15}, letterSpacing:'.1rem' , textAlign:'center',justifyContent:'center', alignItems:'center', width:{xs:'50%', md:'100%'}}}>
                                                    (1) Image file uploaded
                                                </Typography>
                                            ) : (
                                                <Typography noWrap={false} sx={{display:'flex', fontSize:13, marginLeft:{xs:2,md:8}, letterSpacing:'.15rem' , textAlign:'center', justifyContent:'center', alignItems:'center', width:{xs:'50%', md:'100%'}}}>
                                                    No Image file uploaded
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                
                                
                            </Box>
                            <Box>
                                {displayImg ? (
                                    <img src={displayImg} alt="" height={100} width={100}/>
                                ) : (
                                    null
                                )}
                            </Box>
                        </Grid>
                    </Grid>
            )
        case 'List':
            return(
                <Grid container rows={16} rowSpacing={0} rowGap={0.5} style={{height:'100%'}}> 
                    <Grid xs={16} container columns={16}  columnSpacing={1} spacing={1} style={{height:'100%'}}>
                        <Grid xs={1} style={{height:'100%'}}>
                            <Item className='d-flex align-items-center justify-content-center' 
                            onMouseEnter={() => setHoverActive0(true)} 
                            onMouseLeave={() => setHoverActive0(false)} 
                            style={{
                                minHeight: 50,
                                background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                                }}>{instruction.row}</Item></Grid>
                        <Grid xs={15} style={{height:'100%'}}>
                            <Item className='d-flex align-items-center justify-content-center' 
                            onMouseEnter={() => setHoverActive1(true)} 
                            onMouseLeave={() => setHoverActive1(false)} 
                            style={{
                                minHeight: 50,
                                background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                                }}>
                                    <Box sx={{
                                        display:'flex',
                                        backgroundColor:'#777777',
                                        borderRadius: 3,
                                        color:"white",
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        paddingLeft: 1,
                                        paddingRight: 1
                                        }}>
                                        <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                            {instruction.property_name}
                                        </Typography>
                                        <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                            <Box sx={{marginLeft: 1}}>
                                                <GetIconByType type={instruction.property_type} />
                                            </Box>
                                        </Tooltip>
                                        {!instruction.require_field?(null):
                                        (
                                            <Tooltip title="Field is required and cannot be left empty">
                                                <PriorityHighIcon fontSize='small'/>
                                            </Tooltip>
                                        )
                                        }
                                    </Box>
                                </Item>
                            </Grid>
                        
                    </Grid>
                    {Object.keys(inputListRows).length !== 0 && inputListRows !== undefined ? (
                        inputListRows.map((row,i) => {
                            return(
                                <Grid xs={16} container  key={row.id} columns={16}  columnSpacing={1} spacing={1} style={{height:'100%'}}>
                                    <Grid container columns={2} spacing={0} xs={1}>
                                        <Grid container direction="row" xs={1} >
                                            
                                        </Grid>
                                        <Grid xs={1}  direction="row">
                                            {/* UGLY HACK FOR LIST LINES WITH GRIDS, BOX SIZING AND BOX SHADOWS */}
                                            <Box sx={{ height:'100%',}}>
                                                <Box sx={{boxShadow: "-1px 1px 0px #b0b0b0",  background:'white', height:'48%', }}></Box>
                                                <Box sx={{height:'4%'}}></Box>
                                                {Object.keys(inputListRows).length-1 !== i ? (
                                                    <Box sx={{boxShadow: "-1px 0px 0px #b0b0b0",  background:'white', height:'48%', }}></Box>
                                                ):(
                                                    <Box></Box>
                                                )}
                                                
                                            </Box>
                                            
                                        </Grid>
                                    </Grid>
                                    <Grid xs={1} style={{height:'100%'}}><Item className='d-flex align-items-center justify-content-center' style={{minHeight: 50, backgroundColor:'#f0f0f0'}}>
                                                {instruction.row}.{row.row}
                                            </Item>
                                        </Grid>
                                    <Grid xs={6} style={{height:'100%'}}><Item className='d-flex align-items-center justify-content-center' style={{minHeight: 50, backgroundColor:'#f0f0f0'}}>
                                            <Box sx={{display:'flex'}}>
                                                <Typography sx={{fontSize:{xs:12, md:18}}}>{row.field_name}</Typography>
                                                <Tooltip title={`${row.field_name} query expects ${row.input_type} input`}>
                                                    <Box sx={{marginLeft: 1}}>
                                                        <GetIconByType type={row.input_type} />
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </Item>
                                    </Grid>
                                        
                                    <Grid xs={8} style={{height:'100%'}}>
                                        {/*<ListItemInputField value={getInputListDataRowValue(row.id)} onChange={(e) => {updateListDataRowsInput(e,row.id)}} type={row.input_type}/>*/}
                                        {row.input_type === 'Text' ?
                                            (
                                                
                                                <input 
                                                onChange={(e) => {updateListDataRowsInput(e,row.id)}} 
                                                placeholder='Text Input...' 
                                                style={{width:'100%', minHeight:50,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} 
                                                type="text" 
                                                readOnly={isReadOnly ? (true) : (false)}
                                                defaultValue={isReadOnly&&injectQueryValue!==undefined&&injectQueryValue.input[i]!==undefined? (injectQueryValue.input[i].input_value): ('')}
                                                
                                                />
                                            )
                                            :
                                            (
                                                row.input_type === 'Number' ?
                                                (
                                                    <input 
                                                    onChange={(e) => {updateListDataRowsInput(e,row.id)}} 
                                                    placeholder='Number Input...' 
                                                    style={{width:'100%', minHeight:50,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} 
                                                    type="number" 
                                                    defaultValue={isReadOnly&&injectQueryValue!==undefined&&injectQueryValue.input[i]!==undefined? (injectQueryValue.input[i].input_value): ('')}
                                                    readOnly={isReadOnly ? (true) : (false)}
                                                    />
                                                )
                                                :
                                                (
                                                    row.input_type === 'Toggle' ?
                                                    (
                                                        <Item className='d-flex align-items-center justify-content-center' 
                                                        style={{
                                                            minHeight:50, 
                                                            height:targetHeight,
                                                            
                                                            }}>
                                                            <Switch 
                                                            onChange={(e) => 
                                                            {updateListDataRowsInput(e,row.id)}} 
                                                            size='small'
                                                            InputProps={{
                                                                readOnly: isReadOnly ? (true) : (false),
                                                                disableUnderline: isReadOnly ? (true) : (false)
                                                            }}
                                                            checked={isReadOnly&&injectQueryValue!==undefined? (injectQueryValue.input[i].input_value): ('')}
                                                            />
                                                        </Item> 
                                                    )
                                                    :
                                                    (
                                                        <input onChange={(e) => {updateListDataRowsInput(e,row.id)}} style={{width:'100%', minHeight:50,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
                                                    )
                                                )
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })
                    ):(
                        <Box sx={{width: '100%'}}>
                            <LinearProgress />
                        </Box>
                    )

                    }
                </Grid>
            )
        case 'Time':
            return(
                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive0(true)} 
                        onMouseLeave={() => setHoverActive0(false)} 
                        style={{
                            minHeight: 50, 
                            height:targetHeight,
                            background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }} ref={refCont}>{instruction.row}</Item></Grid>
                    <Grid xs={7} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center'
                        onMouseEnter={() => setHoverActive1(true)} 
                        onMouseLeave={() => setHoverActive1(false)}  
                        style={{
                            minHeight: 50, 
                            height:targetHeight,
                            background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            <Box sx={{
                                display:'flex',
                                backgroundColor:'#777777',
                                borderRadius: 3,
                                color:"white",
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingLeft: 1,
                                paddingRight: 1
                                 }} ref={refCont} >
                                <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                    {instruction.property_name}
                                </Typography>
                                <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                    <Box sx={{marginLeft: 1}}>
                                        <GetIconByType type={instruction.property_type} />
                                    </Box>
                                </Tooltip>
                                {!instruction.require_field?(null):
                                (
                                    <Tooltip title="Field is required and cannot be left empty">
                                        <PriorityHighIcon fontSize='small'/>
                                    </Tooltip>
                                )
                                }
                            </Box>
                        </Item>
                    </Grid>
                    <Grid xs={8} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive2(true)} 
                        onMouseLeave={() => setHoverActive2(false)} 
                        style={{
                            minHeight:50, 
                            height:targetHeight,
                            background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "white"
                            }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        
                                <TimeField
                                    ref={refCont}
                                    label="HH:MM:SS"
                                    format='HH:mm:ss'
                                    onChange={onChangeTimeInput}
                                    defaultValue={isReadOnly&&injectQueryValue? (dayjs(injectQueryValue.input)): (inputTime)}
                                />
                            
                            </LocalizationProvider>
                        </Item> 
                    </Grid>
                </Grid>
            )
        case "Stopwatch":
            return(
                <Grid container columns={16} columnSpacing={1} spacing={1} layout={'row'} style={{height:'100%'}}>
                    <Grid xs={1} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive0(true)} 
                        onMouseLeave={() => setHoverActive0(false)} 
                        style={{
                            minHeight: 50, 
                            height:targetHeight,
                            background: hoverActive0 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }} ref={refCont}>{instruction.row}</Item></Grid>
                    <Grid xs={7} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center'
                        onMouseEnter={() => setHoverActive1(true)} 
                        onMouseLeave={() => setHoverActive1(false)}  
                        style={{
                            minHeight: 50, 
                            height:targetHeight,
                            background: hoverActive1 ? "rgba(33, 150, 243, 0.05)" : "white",
                            }}>
                            <Box sx={{
                                display:'flex',
                                backgroundColor:'#777777',
                                borderRadius: 3,
                                color:"white",
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingLeft: 1,
                                paddingRight: 1
                                 }} ref={refCont} >
                                <Typography sx={{fontWeight:'bold',fontSize:{xs:13, md:18}}}>
                                    {instruction.property_name}
                                </Typography>
                                <Tooltip title={`${instruction.property_name} query expects ${instruction.property_type} input`}>
                                    <Box sx={{marginLeft: 1}}>
                                        <GetIconByType type={instruction.property_type} />
                                    </Box>
                                </Tooltip>
                                {!instruction.require_field?(null):
                                (
                                    <Tooltip title="Field is required and cannot be left empty">
                                        <PriorityHighIcon fontSize='small'/>
                                    </Tooltip>
                                )
                                }
                            </Box>
                        </Item>
                    </Grid>
                    <Grid xs={8} style={{height:'100%'}}>
                        <Item className='d-flex align-items-center justify-content-center' 
                        onMouseEnter={() => setHoverActive2(true)} 
                        onMouseLeave={() => setHoverActive2(false)} 
                        style={{
                            minHeight:50, 
                            height:targetHeight,
                            background: hoverActive2 ? "rgba(33, 150, 243, 0.05)" : "white"
                            }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        
                                <TimeField
                                    
                                    ref={refCont}
                                    label="HH:MM:SS"
                                    format='HH:mm:ss'
                                    value={inputStopwatchTime}
                                    readOnly
                                />

                            </LocalizationProvider>
                            <IconButton
                                //if Stopwatch is not active = start stopwatch, if stopwatch is active and paused= resume, if stopwatch is active and not paused = pause
                                onClick={!swIsActive?(handleStartSW):(swIsPaused?(handleResumeSW):(handlePauseSW))}
                            >
                                {swIsPaused ? (
                                    <PlayArrowIcon/>
                                ):(
                                    <PauseIcon/>
                                )}
                            </IconButton>
                            <IconButton
                                disabled={!swIsActive}
                                onClick={handleResetSW}
                            >
                                <StopIcon/>
                            </IconButton>
                        </Item> 
                    </Grid>
                </Grid>
            )
    }
}