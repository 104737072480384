import { useEffect, useState } from 'react';
import { UserHasPermissions } from '../api/utils';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import FormCardElement from './form-card-element';
import FormFolder from './form-folder.component';
import QueryList from './query-list.component';
import { Box, Grid, Typography, TextField, Select,InputLabel, ToggleButtonGroup,Menu,MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText,DialogActions,Stack,ToggleButton, List, ButtonGroup, Link, ListItemIcon, Checkbox, ListItemText, Collapse, Divider, Button, ListItemButton, ListSubheader, ListItem, IconButton, FormControl } from '@mui/material';
import {motion} from "framer-motion"
import { Reorder, useDragControls } from 'framer-motion';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ReplyIcon from '@mui/icons-material/Reply';
import CircleIcon from '@mui/icons-material/Circle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../api/axios';


import dayjs from 'dayjs';
// The list functionality animated and logics with Framer
export default function FormListMixedView({formData, addFolderCb, moveFolderChildren, currentViewMode, updateChildrenCB, folders, currentFolder, setCurrentFolder, currentFolderChildren, folderChildrenAmount, openFolder, filterString, suretodeletepopup, getQueryObjById, getFillCounter, auth}) {

    
    const [folderList, setFolderList] = useState([])
    const [folderIsOpen, setFolderIsOpen] = useState([])
    const [templist, settemplist] = useState([])
    const controls = useDragControls()

    const [openFolderCreatePU, setOpenFolderCreatePU] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const [popupOpen, setPopupOpen] = useState(false);
    const [formDesc,setFormDesc] = useState("");
    const [formTitle, setFormTitle] = useState("");
    const [deleteAssurance, setDeleteAssurance] = useState(false);
    const [moveAssurance, setMoveAssurance] = useState(false);
    const [formToBeDeleted, setFormToBeDeleted] = useState(null);
    const [folderColor, setFolderColor] = useState('#ffffff');
    const [childrenInFolder, setChildrenInFolder] = useState([]);
    const [destinationFolder,setDestinationFolder] = useState("");

    const [fileCreateDDElAnchor, setFileCreateDDElAnchor] = useState(null);
    const fileCreateDDopen = Boolean(fileCreateDDElAnchor);

    const [fileConfigDDElAnchor, setFileConfigDDElAnchor] = useState(null);
    const fileConfigDDopen = Boolean(fileConfigDDElAnchor);

    const [selectedChildren, SetSelectedChildren] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const handleDestinationChange = (e) => {
        setDestinationFolder(e.target.value);
    }

    const handleFolderColorChange = (event, newColor) => {
        setFolderColor(newColor);
    }

    const closeSuretodelete = () => {
        setDeleteAssurance(false);
        
    }
    const closeSuretoMove = () => {
        setMoveAssurance(false);
        
    }

    const handleNewFormNameOnChange = (e) => {
        setNewFolderName(e.target.value)
    }

    const closeCreateFolderPU = () => {
        setOpenFolderCreatePU(false);
    }
    const handleOpenFolderCreatePU = () =>{
        setOpenFolderCreatePU(true);
    }
    const closeCreateFormVerif = () =>{
        setPopupOpen(false);
    }
    const openCreateFormVerif = () =>{
        setFileCreateDDElAnchor(null)
        setFormDesc("");
        setFormTitle("");
        setPopupOpen(true);

    }
    const openFileCreateDD = (e) => {
        setFileCreateDDElAnchor(e.currentTarget);
    }
    const closeFileCreateDD = () => {
        setFileCreateDDElAnchor(null);
    }
    const openFileConfigDD = (e) => {
        setFileConfigDDElAnchor(e.currentTarget);
    }
    const closeFileConfigDD = () => {
        setFileConfigDDElAnchor(null);
    }
    const continueToCreateForm = (event) => {
        //validate
        if(formTitle !== undefined && formTitle.length > 0){
            setPopupOpen(false);
            //localStorage.setItem("tempformname",formTitle);
            if(currentFolder){
                navigate("/create-form", {state:{"formname":formTitle,"folder":currentFolder,"lastview":currentViewMode}});
            }
            
        }
    }

    const handleOpenFolderDropDown = (id) => {

    }

    const getFolderIdChildren = (id) => {
        if(folderList !== null && folderList !== undefined && folderList.length >= 0 ){
            let tempFolders = folderList
            let tempchildren = []   

            tempFolders.map(folder => {
                if(folder.parent === id){
                    tempchildren.push(folder);
                }
            })
            //console.log(tempchildren)
            return tempchildren
        }else{
            return null
        }
    }

    const getFolderDepth = (id) => {
        let depth = 0;
        let folderdata = folderList.find((folder) => folder._id === id)
        if(folderdata === undefined){
            return undefined;
        }
        while(folderdata.parent !== "0"){
            folderdata = folderList.find((folder) => folder._id === folderdata.parent)
            if(folderdata === undefined){
                return undefined;
            }
            depth++
        }
        return depth
    }

    const InitializeFolderDropdown = (_folders) => {
        let tempopenfolders = []
        let tempfolders = _folders;

        
        tempfolders.map(folder => {
            tempopenfolders.push({
                folder:folder._id,
                open:false
            })
        })
        setFolderIsOpen(tempopenfolders);
    }

    const checkFolderDropdown = (id) => {
        let tempfolder = folderIsOpen

        let result = tempfolder.find((folder) => folder.folder === id)
        if(result !== undefined && result !== null){
            //console.log(result)
            return result.open
        } else {
            return false;
        }
    }

    const toggleFolderDropdown = (id) => {
        let tempfolders = folderIsOpen
        const updatedfoldersopen = tempfolders.map(_folder => 
            _folder.folder === id ? {..._folder, open:!_folder.open} : _folder
        )
        setFolderIsOpen(updatedfoldersopen);
    }

    const FolderDropdownOpenOnly = (id) => {
        let tempfolders = folderIsOpen
        const updatedfoldersopen = tempfolders.map(_folder => 
            _folder.folder === id ? {..._folder, open:true} : _folder
        )
        setFolderIsOpen(updatedfoldersopen);
    }

    const openFolderMixed = (id) => {
        FolderDropdownOpenOnly(id);
        let folderinQuestion = folderList.find((folder) => folder._id === id)
        if(folderinQuestion !== undefined){
            openFolder(folderinQuestion);
        }else {
            console.log("openFolderMixed failed")
        }
        
    }


    const FolderChildOnClick = (childobj) => {
        if(childobj.type === "folder"){
            openFolderMixed(childobj.data._id)
        }else if(childobj.type === "form"){
            navigate("/form/"+childobj.data._id, {state: {queryTable: getQueryObjById(childobj.data._id), lastview:currentViewMode}})
        }
    }

    useEffect(() => {
        settemplist(formData)
        setFolderList(folders)
        InitializeFolderDropdown(folders);

    }, [])

    useEffect(() => {
        console.log("asd")
        setChildrenInFolder(currentFolderChildren);
        SetSelectedChildren([])
        
    },[currentFolder,folderList,templist, currentFolderChildren])

    //Critical
    const onTitleChange = (event) => {
        setFormTitle(event.target.value);

    }

    const selectAll = (event) => {
        if(event.target.checked){
            SetSelectedChildren(childrenInFolder)
        } else {
            SetSelectedChildren([])
        }
    }

    const selectRow = (event,child) => {
         
        if(event.target.checked){
            //console.log(rownum+" is checked");
            //Check if same type with same id is in selected
            if(!checkIfChildIncludesInSelected(child)){
                SetSelectedChildren(selectedChildren => [...selectedChildren, child])
            }
        }else{
            //console.log(rownum+" is unchecked");

            if(checkIfChildIncludesInSelected(child)){
                const temparr = [...selectedChildren];
                
                if(getSelectedChildIndex !== undefined){
                    temparr.splice(getSelectedChildIndex(child), 1)
                    SetSelectedChildren(temparr);
                }else{
                    console.log("Houston we have a problem")
                }
                
            }
            /*
            if(selectedRowI.includes(rownum)){
                const temparr = [...selectedRowI];
                temparr.splice(temparr.indexOf(rownum), 1)
                setSelectedRowI(temparr);
            }
                */
        }
        console.log(selectedChildren)
    }

    const getSelectedChildIndex = (child) => {
        let temparray = selectedChildren;
        let result = undefined
        temparray.map((_child, index) => {
            //console.log(child1, child2)
            //console.log(child1.localeCompare(child2) === 0)
            if(_child.data._id === child.data._id){
                result = index
            }
        })
        return result
    }

    const checkIfChildIncludesInSelected = (child) => {
        let temparray = selectedChildren;
        let result = false
        temparray.map(_child => {

            //console.log(child1, child2)
            //console.log(child2.localeCompare(child1) === 0)
            if(_child.data._id === child.data._id){
                result = true
                    
                
            }
        })
        return result
    }

    function suretodeletepopup(){
        setDeleteAssurance(true);
        
    }
    function sureToMovePopup(){
        setMoveAssurance(true);
        
    }

    const deleteSelected = () => {
        //callback selected items to main class
        let results = [...childrenInFolder]

        if(selectedChildren !== undefined && selectedChildren.length > 0){
            updateChildrenCB(selectedChildren)
            //remove selected items from current lists
            selectedChildren.map((child) => {
                if(childrenInFolder.find((_child) => _child.data._id === child.data._id) !== undefined){
                    results.splice(results.indexOf(childrenInFolder.find((_child) => _child.data._id === child.data._id)),1)
                }
            })
            setChildrenInFolder(results);
            SetSelectedChildren([])
            closeSuretodelete();
            console.log("Forms sent to deletion")
        } else {
            console.log("Failed")
        }
        
        //clear selected items
    }

    const saveNewFolder = () => {
        const newFolder = {
            "parent":currentFolder._id,
            "isroot":false,
            "color":folderColor,
            "name":newFolderName,
            "server":auth.server,
        }
        axios.post("/folders/add", newFolder)
        .then((res) => {
            console.log(res.data.message)
            console.log(res.data.back)
            const child = res.data.back
            

            addFolderCb(child);
            setFolderList([
                ...folderList,
                child
            ])
            
        })
        .catch((err) => {
            console.log(err)
        })
        closeCreateFolderPU();
    }

    const moveSelectedChildren = () => {
        if(destinationFolder!== null){
            moveFolderChildren(selectedChildren,destinationFolder._id);

            let tempFolder = [...folderList]
            let tempForms = [...templist]


            
            
            
            
            closeSuretoMove();
        }
    }

    const getSelectedToString = () => {
        if(selectedChildren !== undefined && selectedChildren.length > 0){
            if(selectedChildren.length === 1){
                return "(1) "+selectedChildren[0].type+" "+selectedChildren[0].data.name+" selected."
            }else{
                let amntFolders = 0;
                let amntForms = 0;
                let result = ""
                selectedChildren.map(child => {
                    if(child.type === "form"){
                        amntForms++;
                    } else {amntFolders++;}
                })
                result += amntFolders>0?(" ("+amntFolders+") folders "):("")
                result += amntForms>0?(" ("+amntForms+") forms "):("")
                result += "selected."
                return result
            }
        } else {
            return null
        }
    }
    /*
    const selectAll = (event) => {
        if(queries !== null && queries !== undefined && queries.query_data !== undefined && queries.query_data !== null){
            if(event.target.checked){
                //select all
                const arrlen = Object.keys(queries.query_data).length
                const temparr = [];
                for(let i = 0; i < arrlen; i++){
                    temparr.push(i)
                }
                setSelectedRowI(temparr);
            } else {
                setSelectedRowI([]);
            }
        }
    }
    */
    //
    

    function NestedFolderListItem({folder}){
        if(getFolderIdChildren(folder._id).length > 0){
            return(
                <Box sx={{transition: "all 0.5s ease"}}>
                <ListItem
                    disablePadding
                    sx={{paddingLeft:2+(2*getFolderDepth(folder._id))}}
                    secondaryAction={
                        <IconButton sx={{display:'flex', justifyContent:'center'}}
                            onClick={() => toggleFolderDropdown(folder._id)}
                        >
                            {checkFolderDropdown(folder._id) === true ? 
                            (
                                <ExpandLessIcon />
                            ) : 
                            (
                                <ExpandMoreIcon />
                            )

                            }
                        </IconButton>
                        
                    }
                
                >
                    <ListItemButton 
                        onClick={() => openFolderMixed(folder._id)}
                        selected={currentFolder._id === folder._id}
                    >
                        <ListItemIcon>
                            {currentFolder._id === folder._id?(
                                <FolderOpenIcon/>
                            ):(
                                <FolderIcon/>
                            )}
                            
                        </ListItemIcon>
                        <ListItemText 
                            
                        >
                            <Typography  sx={{fontFamily:'Open Sans'}} >
                                {folder.name+" ("+folderChildrenAmount(folder._id)+")"} 
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                    
                    
                </ListItem>
                <Collapse in={checkFolderDropdown(folder._id)} timeout="auto" unmountOnExit>
                        {getFolderIdChildren(folder._id).map((_folder) => {
                            return (
                                <NestedFolderListItem
                                    key={_folder._id}
                                    folder={_folder}
                                />
                            )
                        })}
                    </Collapse>
                </Box>
            )
        } else {
            return(
                <ListItem
                    key={folder._id}
                    disablePadding
                    sx={{paddingLeft:2+(2*getFolderDepth(folder._id))}}
                    
                >
                    <ListItemButton 
                        onClick={() => openFolderMixed(folder._id)}
                        selected={currentFolder._id === folder._id}
                    >
                        <ListItemIcon>
                            {currentFolder._id === folder._id?(
                                <FolderOpenIcon/>
                            ):(
                                <FolderIcon/>
                            )}
                        </ListItemIcon>
                        <ListItemText 
                            
                        >
                            <Typography  sx={{fontFamily:'Open Sans'}} >
                                {folder.name+" ("+folderChildrenAmount(folder._id)+")"} 
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                    
                    
                </ListItem>
            )
        }
    }


    return (
        <Box sx={{transition: "all 0.5s ease",borderRadius:5,
            display:'flex', width:'95%', justifyContent:'center', alignItems:'center'
        }}
        >
            
            {(filterString === '' && filterString.length === 0) ? (
                //filter string empty, show current folder 🤖

                <Box sx={{width:{md:'75%', lg:"90%"}}}>

                    <Grid container columns={12}>
                        <Grid xs={3}>
                            <List
                                width='100%'
                                subheader={
                                    <Box sx={{display:'flex'}}>
                                        
                                        <ListSubheader sx={{fontFamily:'Open Sans',}}>
                                            Directories - Current Directory: {currentFolder.name}
                                        </ListSubheader>
                                    </Box>
                                    
                                }
                            >
                                <ListItem
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={() => openFolderMixed("0")}
                                        selected={currentFolder._id === "0"}
                                    >
                                        <ListItemIcon>
                                        {currentFolder._id === "0"?(
                                            <FolderOpenIcon/>
                                        ):(
                                            <FolderIcon/>
                                        )}
                                        </ListItemIcon>
                                        <ListItemText 
                                            
                                        >
                                            <Typography  sx={{fontFamily:'Open Sans'}} >
                                                Home
                                            </Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                    
                                    
                                </ListItem>
                                {folderList !== undefined && folderList !== null ? (
                                    getFolderIdChildren("0").map(_folder => {
                                        if(_folder !== undefined){
                                            return(
                                                <NestedFolderListItem
                                                    key={_folder._id}
                                                    folder={_folder}
                                                />
                                            )
                                        }
                                    })
                                ):(null)

                                }
                            </List>
                        </Grid>
                        <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8'}}/>
                        <Grid xs={8}>
                            <Box sx={{width:'100%', paddingLeft: 2 }}>
                                <ButtonGroup>
                                    <Button variant='contained'
                                        onClick={openFileCreateDD}
                                        id='filecreatebutton'
                                    >
                                        New
                                    </Button>
                                    <Button id='fileconfigbutton' variant='contained' onClick={openFileConfigDD}>
                                        Configure
                                    </Button>
                                </ButtonGroup>
                                <Menu
                                    id='fileconfigmenu'
                                    MenuListProps={{
                                        'aria-labelledby': 'fileconfigbutton'
                                    }}
                                    anchorEl={fileConfigDDElAnchor}
                                    open={fileConfigDDopen}
                                    onClose={closeFileConfigDD}
                                >
                                    <MenuItem onClick={() => sureToMovePopup()}>
                                        <DriveFileMoveIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                        Move
                                    </MenuItem>
                                    <MenuItem onClick={() => suretodeletepopup()}>
                                        <DeleteIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                        Delete
                                    </MenuItem>
                                    
                                </Menu>
                                <Menu
                                    id='filecreatemenu'
                                    MenuListProps={{
                                        'aria-labelledby': 'filecreatebutton'
                                    }}
                                    anchorEl={fileCreateDDElAnchor}
                                    open={fileCreateDDopen}
                                    onClose={closeFileCreateDD}
                                >
                                    <MenuItem onClick={openCreateFormVerif}>
                                        <AssignmentIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                        Form
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenFolderCreatePU}>
                                        <FolderIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                        Folder
                                    </MenuItem>
                                    
                                </Menu>
                            </Box>
                            <Divider orientation='horizontal' variant='middle' flexItem sx={{opacity:'0.8', paddingTop:2}}/>
                            <Box sx={{width:'100%', padding: 2}}>

                                <Box sx={{display:'flex', paddingLeft:2, alignItems:'center'}}>
                                    <Checkbox onChange={(e) => selectAll(e)} checked={selectedChildren.length === childrenInFolder.length && childrenInFolder.length > 0}/>
                                    <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8'}}/>
                                    <Typography variant='body2' fontFamily={'Open Sans'} color={'gray'} sx={{paddingLeft:2}}>
                                        Directory Contents - {getSelectedToString()}
                                    </Typography>
                                </Box>
                                <List
                                    sx={{width:'100%'}}
                                >
                                    {currentFolder == null || currentFolder == undefined ? (null): (
                                        childrenInFolder.map((child) => {
                                            return(
                                                <ListItem
                                                    sx={{width:'100%'}}
                                                    key={child.data._id}
                                                >
                                                    <Checkbox onChange={(e) => selectRow(e,child)} checked={checkIfChildIncludesInSelected(child)}/>
                                                    <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8'}}/>
                                                    <Box sx={{display:'flex', paddingLeft:2, paddingRight:2, width:'25%'}}>
                                                        
                                                        {child.type === "form" ? (
                                                            <AssignmentIcon sx={{color:'gray'}}/>
                                                        ):(
                                                            child.type === "folder" ? (
                                                                <FolderIcon sx={{color:child.data.color}}/>
                                                            ):(null)
                                                        )}
                                                        
                                                        <Link variant='body1' fontFamily={'Open Sans'} sx={{paddingLeft:2}} component="button" underline='none' noWrap={true} onClick={() => FolderChildOnClick(child)} >
                                                            {child.data.name}
                                                        </Link>

                                                    </Box>
                                                    <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8', display:child.type !=="folder"?(null):("none")}}/>
                                                    <Box sx={{display:'flex', paddingLeft:2, paddingRight:2, width:'25%'}}>
                                                        <PersonIcon sx={{display:child.type ==="form"?("flex"):('none'), color:'gray'}}/>
                                                        <Typography sx={{paddingLeft:2}}>
                                                            {child.data.creator}
                                                        </Typography>
                                                    </Box>
                                                    <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8'}}/>
                                                    <Box sx={{display:'flex', paddingLeft:2, paddingRight:2, width:'20%'}}>
                                                        <CalendarMonthIcon sx={{  color:'gray'}}/>
                                                        <Typography sx={{paddingLeft:2}}>
                                                            {dayjs(child.data.createdAt).format('DD.MM.YYYY')}
                                                        </Typography>
                                                    </Box>
                                                    <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8'}}/>
                                                    {child.data.build_instruction === undefined || child.data.build_instruction.length <= 0 ? (null): (
                                                        <Box sx={{display:child.type !=="folder"?("flex"):("none"), paddingLeft:2, }}>
                                                            <TableRowsIcon sx={{  color:'gray'}}/>
                                                            <Typography>
                                                                {child.data.build_instruction.length}
                                                            </Typography>
                                                            
                                                            
                                                        </Box>
                                                    )

                                                    }
                                                    <Box sx={{display:child.type !=="folder"?("flex"):("none"),paddingLeft:1,paddingRight:2 }}>
                                                        <ReplyIcon sx={{ color:'gray'}}/>
                                                            
                                                        <Typography >
                                                        { getFillCounter(child.data._id)}
                                                        </Typography>
                                                        
                                                        
                                                    </Box>
                                                    <Divider variant='middle' orientation='vertical' flexItem sx={{opacity:'0.8'}}/>
                                                    
                                                     
                                                    
                                                    
                                                </ListItem>
                                                
                                            )
                                        })
                                    )}
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                // 🏃 filtering folders and forms based on filterstring
                <Grid container spacing={3} columns={12}>
                {folders.filter(folder => folder.name.toString().toLowerCase().includes(filterString.toString().toLowerCase())&&folder._id!=="0").map((obj, index) => {
                    //folder.id !== 0 hides root folder 👻
                    return(
                        <Grid xs={12} sm={3} md={2} lg={1.5} key={obj._id}>
                            
                            <FormFolder openFolderCB={openFolder} folder={obj} children_n={folderChildrenAmount(obj._id)}/>
                                
                        </Grid>
                    )
                })}

                {templist.filter(form => form.name.toString().toLowerCase().includes(filterString.toString().toLowerCase())).map((obj, index) => {
                    return (
                        
                        <Grid xs={12} sm={6} md={4} lg={3} key={obj._id}>
                            
                            <FormCardElement 
                            key={obj._id}
                            delay={80*index}
                            queryTable={getQueryObjById(obj._id)}
                            //👇 form is for showing data on form cards, consider injecting only relevant data
                            form={{
                                name: obj.name,
                                id: obj._id,
                                build_instruction: obj.build_instruction,
                                timestamp: obj.createdAt,
                                creator: obj.creator,
                                fill_counter: getFillCounter(obj._id)
                            }}
                            userCanDelete={UserHasPermissions(auth.permissions, ["Delete"])}
                            startDeletePopup={suretodeletepopup}
                            //openForm={openForm(obj._id)}
                            />
                                
                            
                        </Grid>
                        
                    )
                })}
                </Grid>
            )}
            {!currentFolder ? (null) : (
                <Dialog fullWidth={true} open={openFolderCreatePU} onClose={closeCreateFolderPU}>
                    <DialogTitle>Create Folder</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Creating folder inside folder: {currentFolder.name}</DialogContentText>
                        <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Title*"
                        onChange={handleNewFormNameOnChange}
                        type="email"
                        fullWidth
                        variant="standard"
                        autoComplete='off'
                    />
                        
                        
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            Folder color
                        </DialogContentText>
                        <Stack>
                            <ToggleButtonGroup size='small' value={folderColor} onChange={handleFolderColorChange} exclusive={true}>
                                <ToggleButton value="#ffffff" key="#ffffff">
                                    <CircleIcon sx={{color:'#ffffff', fontSize:30}}/>
                                </ToggleButton>
                                <ToggleButton value="#84cdee" key="#84cdee">
                                    <CircleIcon sx={{color:'#84cdee',fontSize:30}}/>
                                </ToggleButton>
                                <ToggleButton value="#ff9b9b" key="#ff9b9b">
                                    <CircleIcon sx={{color:'#ff9b9b',fontSize:30}}/>
                                </ToggleButton>
                                <ToggleButton value="#c6c1ff" key="#c6c1ff">
                                    <CircleIcon sx={{color:'#c6c1ff',fontSize:30}}/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{justifyContent:'start', alignItems:'center'}}>
                            <Button onClick={closeCreateFolderPU} variant='contained' >
                                Exit
                            </Button>
                            <Button  variant='contained' color='primary' onClick={saveNewFolder}>
                                Create
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
            <Dialog fullWidth={true} open={deleteAssurance} onClose={closeSuretodelete}>
                <DialogTitle>Delete Files</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete the following:</DialogContentText>
                        <DialogContentText>{getSelectedToString()}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{justifyContent:'start', alignItems:'center'}}>
                            <Button variant='contained' onClick={closeSuretodelete}>
                                Back
                            </Button>
                            <Button variant='contained' color='error' onClick={deleteSelected}>
                                Delete
                            </Button>
                        </Box>
                    </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} open={moveAssurance} onClose={closeSuretoMove}>
                <DialogTitle>Move Files</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Moving Files:</DialogContentText>
                        {selectedChildren.map((child) => {
                            return(
                                <DialogContentText>{"  - ("+child.type+"), "+child.data.name}</DialogContentText>
                            )
                        })

                        }
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>Select Destination Folder</DialogContentText>
                        <InputLabel labelId='destselect'>Destination</InputLabel>
                        <FormControl fullWidth>
                            <Select
                                labelId='destselect'
                                value={destinationFolder}
                                label="Destination"
                                onChange={handleDestinationChange}
                            >
                                {folderList.map((folder) => {
                                    if(folder._id !== currentFolder._id){
                                        return (
                                            <MenuItem value={folder}>
                                                {folder.name}
                                            </MenuItem>
                                        )
                                    }
                                })

                                }
                            </Select>
                        </FormControl>
                        
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{justifyContent:'start', alignItems:'center'}}>
                            <Button variant='contained' onClick={closeSuretoMove}>
                                Back
                            </Button>
                            <Button variant='contained' color='primary' onClick={moveSelectedChildren}>
                                Move
                            </Button>
                        </Box>
                    </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="sm" open={popupOpen} onClose={closeCreateFormVerif}>
                <DialogTitle>Create form</DialogTitle>
                    <DialogContent>
                    <DialogContentText>Start by giving your form a title!</DialogContentText>
                    <div>
                        
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Title*"
                            onChange={onTitleChange}
                            type="email"
                            fullWidth
                            variant="standard"
                            autoComplete='off'
                        />
                    </div>
                    
                    </DialogContent>
                    <DialogActions>
                    <div className='d-flex align-items-center'>
                        <button className="btn btn-primary btn-space me-1" onClick={closeCreateFormVerif}>Back</button>
                        {formTitle===undefined||formTitle.length<=0 ? (<button className="btn btn-primary btn-space disabled" >Continue</button>) : (<button className="btn btn-primary btn-space" onClick={continueToCreateForm}>Continue</button>)}
                    </div>
                    </DialogActions>
            </Dialog>  
        </Box>
    )
}