import { Box, Grid, Paper, styled, Button, Typography, Fade} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import noimg from "../imgs/dummy_650x100_ffffff_cccccc.png"
import prujubäkkis from "../imgs/prujubäkkis1.PNG"
import LoginIcon from '@mui/icons-material/Login';
import { useEffect, useState , useRef} from "react";
import { useMediaQuery } from "../api/utils";
import backg1 from "../imgs/wfgbäk1.PNG"


export default function WorkflowGuideIntro() {
    const navigate = useNavigate();
    const refcont = useRef();
    const [step, setStep] = useState(0);
    const [titleVis, setTitleVis] = useState(false);
    const [transitions, setTransitions] = useState([
        false,
        false,
        false,
        false,
        false,
    ])
    const [windowHeight, setWindowHeight] = useState(0);
    const [windowWidth, setWindowWidth] =useState(0);
    const [titledim, setTitledim] = useState({
        width: 0,
        height: 0
    })
    
    useEffect(() => {
        setTitleVis(true);
        setWindowHeight(window.innerHeight);
        setWindowWidth(window.innerWidth);
        
    }, [])
    

    const titlePos = () => {
        if(transitions[0] === false){
            return {
                x: 0,
                y: 0,
            }
        } else {
            return {
                x: 0,
                y: -windowHeight/2.5,
            }
        }
    }

    const handleNext = () => {
        let curstep = step + 1;
        if(curstep > 5){setStep(5); curstep = 5;}else {
            setStep(curstep);
        }
        handleSetTransitions(curstep);
    }

    const handleSetTransitions = (curstep) => {
        switch(curstep){
            default:
                setTransitions([
                    false,
                    false,
                    false,
                    false,
                    false,
                ])
                break;
            case 1:
                setTransitions([
                    true,
                    false,
                    false,
                    false,
                    false,
                ])
                break;
            case 2:
                setTransitions([
                    true,
                    true,
                    false,
                    false,
                    false,
                ])
                break;
            case 3:
                setTransitions([
                    true,
                    true,
                    true,
                    false,
                    false,
                ])
                break;
            case 4:
                setTransitions([
                    true,
                    true,
                    true,
                    true,
                    false,
                ])
                break;
            case 5:
                setTransitions([
                    false,
                    false,
                    false,
                    false,
                    true,
                ])
                navigate("/workflowguide/demo")
                break;
        }
    }

    const handleBack = () => {
        let curstep = step - 1;
        if(curstep < 0){setStep(0); curstep = 0;} else {
            setStep(curstep);
        }
        handleSetTransitions(curstep);
    }

    return(
        <Box sx={{display:'flex', width:'99vw', height:'90vh',}}>
            <Box>
                <Button
                    onClick={handleBack}
                >
                    Back
                </Button>
            </Box>
            <Box sx={{display:'flex',width:'100%', height:'100%',  justifyContent: 'center', alignItems:'center',  }}>
                {windowHeight === 0 || windowWidth === 0 ? (null) : (
                    
                    <Typography sx={{display:transitions[4] ? ('none') : ('flex')}} variant="h1"  fontFamily={'Open Sans'} style={{position:'absolute', opacity:titleVis ? (1) : (0) ,transition: "all 0.5s ease", transform:`translate(${titlePos().x}px, ${titlePos().y}px)`}}  >
                        What is workflow guide mode? 
                    </Typography>
                    
                    
                )}
                <Box sx={{display:('flex'),width:'100%', height:'100%',  justifyContent: 'center', alignItems:'center', paddingTop:15, marginLeft:20, marginRight:20}}>
                    <Grid container columns={15} spacing={0} columnGap={5} sx={{display:'flex',width:'100%', height:'100%'}}>
                        <Grid xs={4.7} >
                            <Fade in={transitions[1] && !transitions[5]}>
                                <Box sx={{display:'inline-flex',width:'100%', height:'100%' , background:`rgb(26, 31, 41)  url(${backg1}) `,  backgroundSize:'cover', backgroundBlendMode: "luminosity", opacity:0.8, justifyContent: 'center'}}>
                                    <Typography variant="h3" fontFamily={'Open Sans'} >
                                        Design
                                    </Typography>
                                </Box>
                            </Fade>
                            
                        </Grid>
                        <Grid xs={4.7} >
                            <Fade in={transitions[2] && !transitions[5]}>
                                <Box sx={{display:'inline-flex',width:'100%', height:'100%',  justifyContent: 'center', backgroundColor:'' }}>
                                    <Typography variant="h3" fontFamily={'Open Sans'} >
                                        Work
                                    </Typography>
                                </Box>
                            </Fade>
                        </Grid>
                        <Grid xs={4.7} >
                            <Fade in={transitions[3] && !transitions[5]}>
                                <Box sx={{display:'inline-flex',width:'100%', height:'100%',  justifyContent: 'center',   }}>
                                    <Typography variant="h3" fontFamily={'Open Sans'} >
                                        Collect
                                    </Typography>
                                </Box>
                            </Fade>
                        </Grid>
                        
                    </Grid>
                </Box>
                <Box sx={{display:'none', position:'absolute', width:'100%', height:'100%', backgroundColor:'gray', justifyContent: 'center', alignItems:'center',}}>
                    
                </Box>

            </Box>
            <Box>
                <Button
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
            
        </Box>
    )
}