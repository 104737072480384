import React, { Component, Fragment, useEffect, useState } from 'react';
import axios from '../api/axios';
import {Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import { useScrollTrigger, Tooltip, Typography, Box, Stack, Menu, MenuItem, InputLabel, Input, Button, TextField, Divider, Select, OutlinedInput, FormControl, FormHelperText, Chip, List, ListItem, ListItemButton, ListItemText, ListItemAvatar } from '@mui/material';
import CreateTicket from './create-ticket.component';
import SelectedTicket from './selected-ticket.component';
import PersonIcon from '@mui/icons-material/Person';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import CategoryIcon from '@mui/icons-material/Category';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReplyIcon from '@mui/icons-material/Reply';
import useAuth from '../hooks/useAuth';

export default function TicketsView(){
    const navigate = useNavigate();
    const [ticketData, setTicketData] = useState([])
    const [viewState, setViewState] = useState(0); //0 list view, 1 create ticket, 2 view ticket
    const [openTicket, setOpenTicket] = useState(null);
    const [loadingTickets, setLoadingTickets] = useState(true);
    const [selectTicket, setSelectTicket] = useState(null);
    const {auth} = useAuth();
    useEffect(() => {
      fetchTicketData();
    }, [])
    
    
    async function fetchTicketData(){
        setLoadingTickets(true);
        axios.get('/tickets', {params:{"server":auth.server}})
        .then(res => {
            setTicketData(res.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => setLoadingTickets(false));
    }

    const openCreateTicketView = () => {
       navigate('/tickets/create')
       //init states
    }

    const closeCreateTicketView = () => {
        setViewState(0);
    }

    //fetch tickets after creating one
    const finalizeCreateTicket = () => {
        
        fetchTicketData();
        setViewState(0);
    }

    const selectTicketData = (data) => {
        navigate('/tickets/'+data._id)
    }

    return(
        <Box
        sx={{
            mx: 'auto', 
            width:1500,
            
            border: '1px solid',
            borderColor: '#f5f5f5',
        }}
        >
            <Button onClick={openCreateTicketView}>Open a ticket</Button>
            <Box>
                {(!loadingTickets)
                ?
                (
                <List
                    sx={{width: '100%', bgcolor: 'background.paper'}}
                >
                    {ticketData.map((ticket, index) => {
                        return(
                            <ListItem key={ticket._id} sx={ticket.severity === 'Critical' ? {backgroundColor:'#ffd7d4'}:undefined}>
                                <ListItemButton onClick={() => selectTicketData(ticket)}>
                                <ListItemAvatar>
                                    <PersonIcon/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Box
                                            sx={{display:'flex', justifyContent:'space-between'}}
                                        >
                                            <Box sx={{display:'inline-block', width:'200px'}} >
                                                <Tooltip title={ticket.title}>
                                                    <Typography sx={{fontWeight:'bold'}} variant='subtitle1' noWrap={true}>
                                                        {ticket.title}
                                                    </Typography>
                                                </Tooltip>
                                                
                                            </Box>
                                            
                                            <Divider orientation='vertical' flexItem/>
                                            <Box sx={{display:'flex'}}>
                                                
                                                <Tooltip title='Related service'>
                                                    <MiscellaneousServicesIcon sx={{color:'gray'}}/>
                                                </Tooltip>
                                                <Box sx={{display:'inline-block', width:'200px'}}>
                                                    <Typography variant='subtitle1'>
                                                        {ticket.product}
                                                    </Typography>
                                                </Box>
                                                
                                            </Box>
                                            
                                            <Divider orientation='vertical' flexItem/>
                                            <Box sx={{display:'flex'}}>
                                                <Tooltip title='Ticket Category'>
                                                    <CategoryIcon sx={{color:'gray'}}/>
                                                </Tooltip>
                                                <Box sx={{display:'inline-block', width:'200px'}}>
                                                    <Typography variant='subtitle1'>
                                                        {ticket.subject}
                                                    </Typography>
                                                </Box>
                                                
                                            </Box>
                                            
                                            <Divider orientation='vertical' flexItem/>
                                            <Box sx={{display:'flex', alignItems:'center'}}>
                                                <Tooltip title='Severity'>
                                                    <PriorityHighIcon sx={{color:'gray'}}/>
                                                </Tooltip>
                                                <Box sx={{display:'inline-block', width:'100px'}}>
                                                    <Typography variant='subtitle1'>
                                                        {ticket.severity}
                                                    </Typography>
                                                </Box>
                                                
                                            </Box>
                                            
                                        </Box>
                                    }
                                    secondary={
                                        <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                            <Box>
                                                <Box sx={{display:'flex'}}>
                                                    <Tooltip title='Replies'>
                                                        <ReplyIcon sx={{color:'gray'}}/>
                                                    </Tooltip>
                                                    <Typography variant='subtitle2' textAlign={'center'}>
                                                        Comments: {ticket.comments.length}
                                                    </Typography>
                                                </Box>
                                                <Typography variant='subtitle2'>
                                                {ticket.createdAt}
                                            </Typography>
                                            </Box>
                                            
                                            
                                            <Box sx={{display:'flex'}}>
                                                <Tooltip title='Post by User'>
                                                    <PersonIcon sx={{color:'gray'}}/>
                                                </Tooltip>
                                                <Typography variant='subtitle2' textAlign={'center'}>
                                                    {ticket.user}
                                                </Typography>
                                                
                                            </Box>
                                            
                                        </Box>
                                    }
                                    
                                />
                                
                                </ListItemButton>
                                <Divider/>
                            </ListItem>
                            
                        )
                    })}
                </List>
                )
                :
                (
                    <Box>Loading...</Box>
                )
                }
            </Box>
        </Box>
    )
}