//https://www.grapecity.com/wijmo/demos/Input/Popup/Dialogs/react
//Date picker
//puhelin näkymä
//kuva tiedosto
//puhelin applikaatio separaatio

import React, { Component, useEffect, useState } from 'react';

import {Link, useParams, useNavigate} from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import { Box, Divider, Stack,Grid,Typography,Tooltip, Checkbox, Button, IconButton, Dialog, DialogContent, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import AbcIcon from '@mui/icons-material/Abc';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ListIcon from '@mui/icons-material/List';
import ImageIcon from '@mui/icons-material/Image';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import UndoIcon from '@mui/icons-material/Undo';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExpandIcon from '@mui/icons-material/Expand';
import RemoveIcon from '@mui/icons-material/Remove';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AddIcon from '@mui/icons-material/Add';
import IconFiletypeJson from '../reactsvgicons/filetype-json';
import IconFiletypeCsv from '../reactsvgicons/filetype-csv';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dayjs from 'dayjs'

import FormFill from './form-fill.component';

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


/* Lists Queries in a comprehensive way
  if User is sudo:
    show sudo all users answers
  else
    show user only own answers
  
*/

export default function QueryList({queryfetch, BIs}) {

    const [queries, setQueriesList] = useState([]);
    const [buildInstructions, setBuildInstructions] = useState([]);
    const [selectedRowI, setSelectedRowI] = useState([]);
    const [queryColumnSize, setQueryColumnSize] = useState([])
    const [selectedImg, setSelectedImg] = useState(null)
    const [openImageExpand, setOpenImageExpand] = useState(false);
    const [extraMenuOpen, setExtraMenuOpen] =useState(false);
    const [openAnswersQueryData, setOpenAnswersQueryData] = useState(null)
    const [imgMouseOver, SetImgMouseOver] = useState([]);

    //export menu
    const [exportAnchorEl, setExportAnchorEl] = useState(null)
    const exportOpen = Boolean(exportAnchorEl);
    const [isExported, setIsExported] = useState(false);
    const handleExportClick = (event) => {
        setExportAnchorEl(event.currentTarget);
    }
    const handleExportClose = () => {
        setExportAnchorEl(null)
    }
    
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const {auth} = useAuth();

    const handleOpenImageExpand = (fileInput) => {
        setSelectedImg(fileInput);
        setOpenImageExpand(true);
    }
    const handleCloseImageExpand = (fileInput) => {
        setSelectedImg('');
        setOpenImageExpand(false);
    }

    useEffect(()=>{
        setQueriesList(queryfetch);
        setBuildInstructions(BIs);
    },[])

    useEffect(() => {
        if(BIs !== undefined && BIs !== null){
            BIs.map((row, i) => {
                if(row.property_type === 'List'){
                    setQueryColumnSize(queryColumnSize => [...queryColumnSize, {
                        'x':400,
                        "y":100
                    }])
                }else {
                    setQueryColumnSize(queryColumnSize => [...queryColumnSize, {
                        'x':300,
                        "y":100
                    }])
                }
                
            })
        }
    }, [BIs])

    

    const GetWidthByType = (type) => {
        switch(type){
            default:
                return 300;
            case "List":
                return 400;
        }
    }

    const debufg = () => {
        console.log(selectedRowI);
    }

    const selectRow = (event,rownum) => {
        if(event.target.checked){
            //console.log(rownum+" is checked");
            if(!selectedRowI.includes(rownum)){
                setSelectedRowI(selectedRowI => [...selectedRowI, rownum])
            }
        }else{
            //console.log(rownum+" is unchecked");
            if(selectedRowI.includes(rownum)){
                const temparr = [...selectedRowI];
                temparr.splice(temparr.indexOf(rownum), 1)
                setSelectedRowI(temparr);
            }
        }
        //console.log(selectedRowI)
    }

    const deleteRow = () => {
        console.log(queries);
        if(selectedRowI.length > 0 && queries !== null && queries !== undefined && queries.query_data !== undefined && queries.query_data !== null && queries.query_data.length > 0){
            console.log("deleting rows: ",selectedRowI);
            let tempqueries = queries;
            selectedRowI.sort()
            selectedRowI.toReversed().map((index) => {
                if(index !== -1){
                    tempqueries.query_data.splice(index, 1);
                    
                }
            })
            console.log(tempqueries);
            setQueriesList(tempqueries);
            axios.post('/queries/updatebypairid/'+queries.form_pair_id, {
                querydata: tempqueries.query_data,
                fc_update: -selectedRowI.length,
                server: auth.server
            })
            .then(res => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
            setSelectedRowI([]);
        }
    }

    

    const selectAll = (event) => {
        if(queries !== null && queries !== undefined && queries.query_data !== undefined && queries.query_data !== null){
            if(event.target.checked){
                //select all
                const arrlen = Object.keys(queries.query_data).length
                const temparr = [];
                for(let i = 0; i < arrlen; i++){
                    temparr.push(i)
                }
                setSelectedRowI(temparr);
            } else {
                setSelectedRowI([]);
            }
        }
    }

    const exportQueriesJson = () =>   {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(queries)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `${queries.name}.json`;
        link.click()
    }

    const exportQueriesCsv = () => {
        let csvcontent = "data:text/csv;charset=utf-8,"+queries.query_data.map(e => e.join(",")).join("\n");
        var encodecsv = encodeURI(csvcontent);
        const link = document.createElement("a");
        link.href = encodecsv;
        link.download = `${queries.name}.csv`;
        link.click()
    }

    const incGridSize = () => {
        const newColumnSizes = queryColumnSize.map((col, i) => {
            return{
                ...col,
                x: col.x + 25,
                y: col.y +25/3
            }
        })
        setQueryColumnSize(newColumnSizes);
        console.log(queryColumnSize);
    }
    const decGridSize = () => {
        console.log(queryColumnSize);
        const newColumnSizes = queryColumnSize.map((col, i) => {
            return{
                ...col,
                x: col.x - 25,
                y: col.y - 25/3
            }
        })
        setQueryColumnSize(newColumnSizes);
        console.log(queryColumnSize);
    }


    const GetIconByType = ({type}) => {
        switch(type){
            default:
                return(
                    <AbcIcon/>
                )
            case 'Text':
                return(
                    <AbcIcon/>
                )
            case 'Number':
                return(
                    <NumbersIcon/>
                )
            case 'Toggle':
                return(
                    <ToggleOnIcon/>
                )
            case 'Image':
                return(
                    <ImageIcon/>
                )
            case 'Date':
                return(
                    <CalendarMonthIcon/>
                )
            case 'List':
                return(
                    <ListIcon/>
                )
            case 'Time':
                return(
                    <AvTimerIcon/>
                )
        }
    }

    return (
        <Box>
            {queries !== null && queries !== undefined && queries.query_data !== undefined && queries.query_data !== null  ? (
                openAnswersQueryData !== null && openAnswersQueryData !== undefined && Object.keys(openAnswersQueryData).length > 0 ?(
                    <Box>
                        <Box sx={{display:'block', justifyContent:'center'}}>
                            <Box sx={{display:'flex', justifyContent:'center'}}>    
                                <IconButton sx={{color:'blue'}} onClick={() => {setOpenAnswersQueryData(null)}}>
                                    <UndoIcon sx={{fontSize:35}} />
                                </IconButton>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                
                                <Typography color='red' variant='h2'>
                                    HEADER INFO & BUTTONS HERE
                                </Typography>
                            </Box>
                            
                            <FormFill 
                                readOnly={true}
                                formdata={{
                                    "build_instruction": buildInstructions,
                                    "name": "temp"
                                }}
                                injectQueryData={openAnswersQueryData}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box>
                    {/* Topmost Info panel */}
                    <Box sx={{width:'100%', marginLeft: 1}}> 
                        <Typography variant='body1' sx={{fontFamily:'Open Sans'}}>
                            {queries.query_data !== undefined && queries.query_data !== null ? (
                                `Showing ${Object.keys(queries.query_data).length} answers`
                            ):(
                                "Showing 0 answers"
                            )}
                        </Typography>
                        <Button variant='contained' id='exportButton' onClick={handleExportClick} endIcon={<ArrowDropDownIcon/>}>Export</Button>
                        <Button variant='contained' disabled >Edit row</Button>
                        <Button variant='contained' onClick={() => deleteRow()}>Delete row</Button>
                        <Divider sx={{opacity:1}}/>
                    </Box>
                    <Menu
                        open={exportOpen}
                        anchorEl={exportAnchorEl}
                        onClose={handleExportClose}
                        MenuListProps={{
                            'aria-labelledby': 'exportButton'
                        }}
                    >
                        <MenuItem
                            onClick={exportQueriesJson}
                        >
                            <ListItemIcon>
                                <IconFiletypeJson style={{fontSize:'25px'}}/>
                            </ListItemIcon>
                            <ListItemText>JSON</ListItemText>
                        </MenuItem>
                        <MenuItem
                            
                        >
                            <ListItemIcon>
                                <IconFiletypeCsv style={{fontSize:'25px'}}/>
                            </ListItemIcon>
                            <ListItemText>CSV(under construction)</ListItemText>
                        </MenuItem>
                    </Menu>
                    <Box sx={{width:'100%', marginLeft: 1}}> 
                        <Typography variant='body1' sx={{fontFamily:'Open Sans'}}>
                            Grid Size:
                        </Typography>
                        <IconButton onClick={incGridSize}>
                            <AddIcon />
                        </IconButton>
                        <IconButton onClick={decGridSize}>
                            <RemoveIcon />
                        </IconButton>
                        <Divider sx={{opacity:1}}/>
                    </Box>
                    {/* Custom Table */}
                    <Box sx={{
                        display:'flex',
                        width: '100%',
                        overflow: 'auto'
                        }}>
                            
                        
                        <Divider sx={{opacity:1}} orientation='vertical'/>
                        <Stack
                            direction='column'
                            divider={<Divider sx={{opacity:1}}/>}
                        >
                            {/* Header Row */}
                            <Stack
                                divider={<Divider sx={{opacity:1}} orientation='vertical'/>}
                                direction='row'
                                spacing={2}
                                sx={{marginLeft: 2,}}
                            >
                                <Box
                                    sx={{display:'flex', width:50, height: 100, justifyContent:'center', alignItems: 'center', }}
                                    >
                                        <Box sx={{
                                            display:'flex',
                                            
                                            justifyContent:'center'
                                            
                                        }}
                                        >
                                        <Checkbox checked={selectedRowI.length === queries.query_data.length} onChange={selectAll}/>
                                        
                                    </Box>
                                </Box>
                                { !extraMenuOpen ? (
                                    <Box
                                        sx={{display:'flex', width:10, height: 100, justifyContent:'center', alignItems: 'center', }}
                                        >
                                            <Box sx={{
                                                display:'flex',
                                                
                                                justifyContent:'center'
                                                
                                            }}
                                            >
                                            <IconButton onClick={() => setExtraMenuOpen(true)}>
                                                <ExpandIcon  sx={{rotate:"90deg"}}/>
                                            </IconButton>
                                            
                                        </Box>
                                    </Box>
                                ): (
                                    <Box>
                                        <Box
                                            sx={{display:'flex', width:400, height: 100, justifyContent:'center', alignItems: 'center', background:'#676767'}}
                                            >
                                                <Box sx={{
                                                    display:'flex',
                                                    
                                                    justifyContent:'center'
                                                    
                                                }}
                                                >
                                                <Box sx={{
                                                display:'flex',
                                                backgroundColor:'white',
                                                borderRadius: 3,
                                                color:'#777777',
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                paddingLeft: 1,
                                                paddingRight: 1,
                                                alignItems:'center',
                                                justifyContent:'center',
                                                justifySelf:'center',
                                                alignSelf:'center'
                                            }}
                                            >
                                                <Typography variant='body1' sx={{fontFamily:'Open Sans'}}>Query Information</Typography>
                                                <Tooltip title="Shrink Query information">
                                                    <Box sx={{marginLeft: 1}}>
                                                        <IconButton onClick={() => setExtraMenuOpen(false)}>
                                                            <CloseFullscreenIcon  sx={{rotate:"45deg"}}/>
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                                
                                                
                                            </Box>
                                        </Box>
                                    </Box>
                                    
                                )

                                }
                                {buildInstructions.map((row, i) => {
                                    return(
                                        <Box
                                            key={i}
                                            sx={{display:'flex', width:queryColumnSize[i].x, height: queryColumnSize[i].y, justifyContent:'center', alignItems: 'center',backgroundColor:'white'}}
                                        >
                                            <Box sx={{
                                                display:'flex',
                                                backgroundColor:'#777777',
                                                borderRadius: 3,
                                                color:"white",
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                paddingLeft: 1,
                                                paddingRight: 1,
                                                alignItems:'center',
                                                justifyContent:'center',
                                                justifySelf:'center',
                                                alignSelf:'center'
                                            }}
                                            >
                                                <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>{row.property_name}</Typography>
                                                <Tooltip title={`${row.property_name} field is of type ${row.property_type}`}>
                                                    <Box sx={{marginLeft: 1}}>
                                                        <GetIconByType type={row.property_type} />
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Stack>
                            {queries.query_data.map((query, l) => {
                                return(
                                    <Stack
                                        key={l+10000}
                                        divider={<Divider sx={{opacity:1}} orientation='vertical'/>}
                                        direction='row'
                                        spacing={2}
                                        
                                        sx={{marginLeft: 2,
                                            backgroundColor:selectedRowI.includes(l) ? 'rgba(240,240,240,255)' : 'rgba(250,250,250,255)'
                                        
                                        }}
                                        
                                    >
                                        {/* Checkbox */}
                                        <Box
                                            sx={{display:'flex', width:50, height: 100, justifyContent:'center', alignItems: 'center', }}
                                            >
                                                <Box sx={{
                                                    display:'flex',
                                                    
                                                    justifyContent:'center'
                                                    
                                                }}
                                                >
                                                <Checkbox onChange={(e) => selectRow(e,l)} checked={selectedRowI.includes(l) ? (true) : (false)} />
                                                
                                            </Box>
                                        </Box>
                                        {extraMenuOpen ? (
                                            <Box
                                                sx={{display:'flex', width:400, height: 100, justifyContent:'center', alignItems: 'center', backgroundColor:'#676767'}}
                                                >
                                                <Box sx={{
                                                    display:'flex',
                                                    
                                                    justifyContent:'center'
                                                    
                                                }}
                                                >
                                                    <IconButton onClick={() => setExtraMenuOpen(true)}>
                                                        <FileOpenIcon  />
                                                    </IconButton>
                                                    
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{display:'flex', width:10, height: 100, justifyContent:'center', alignItems: 'center',}}
                                                >
                                                <Box sx={{
                                                    display:'flex',
                                                    
                                                    justifyContent:'center'
                                                    
                                                }}
                                                >
                                                    <IconButton onClick={() => setOpenAnswersQueryData(query)}>
                                                        <FileOpenIcon />
                                                    </IconButton>
                                                    
                                                </Box>
                                            </Box>
                                        )

                                        }
                                
                                    {query.query.map((row, k) => {
                                        switch(row.input_type){
                                            default:
                                                return(
                                                    <Box
                                                        key={row.id}
                                                        sx={{display:'flex', width:queryColumnSize[k].x, height: queryColumnSize[k].y, justifyContent:'center', alignItems: 'center', }}
                                                        >
                                                            <Box sx={{
                                                                display:'flex',
                                                                alignItems:'center',
                                                                justifyContent:'center',
                                                                width:'100%',
                                                                height:'100%'
                                                            }}
                                                            >
                                                            <Typography variant='body2' sx={{fontFamily:'Open Sans',}}>{row.input.toString()}</Typography>
                                                            
                                                        </Box>
                                                    </Box>
                                                )
                                            case "Image":
                                                return(
                                                    <Box
                                                        key={row.id}
                                                        sx={{display:'flex', width:queryColumnSize[k].x, height: queryColumnSize[k].y, justifyContent:'center', alignItems: 'center', }}
                                                        >
                                                        <Box sx={{
                                                            display:'flex',
                                                            alignItems:'center',
                                                            justifyContent:'center',
                                                            width:'100%',
                                                            height:'100%'
                                                        }}
                                                        >

                                                            <img src={row.input} style={{borderRadius:'1rem', 
                                                                transition: "all 0.5s ease",
                                                                WebkitTransition: "all 0.5s ease",
                                                                MozTransition: "all 0.5s ease" ,
                                                                }} 
                                                                alt=""
                                                                width={queryColumnSize[k].x*0.70} 
                                                                height={queryColumnSize[k].y*0.90} 
                                                                onClick={() => handleOpenImageExpand(row.input)}/>
                                                            <Dialog
                                                                fullWidth={true}
                                                                open={openImageExpand}
                                                                maxWidth={'xl'}
                                                                onClose={handleCloseImageExpand}
                                                            >
                                                                <DialogContent>
                                                                    <img src={selectedImg} alt="" style={{height:windowHeight*0.85, aspectRatio: 1}}/>
                                                                </DialogContent>
                                                            </Dialog>
                                                            
                                                            
                                                        </Box>
                                                    </Box>
                                                )
                                            case "Toggle":
                                                return(
                                                    <Box
                                                        key={row.id}
                                                        sx={{display:'flex', width:queryColumnSize[k].x, height: queryColumnSize[k].y, justifyContent:'center', alignItems: 'center', }}
                                                        >
                                                            <Box sx={{
                                                                
                                                                display:'flex',
                                                                backgroundColor:'#777777',
                                                                borderRadius: 3,
                                                                color:"white",
                                                                paddingTop: '2px',
                                                                paddingBottom: '2px',
                                                                paddingLeft: 1,
                                                                paddingRight: 1,
                                                                alignItems:'center',
                                                                justifyContent:'center'
                                                                
                                                            }}
                                                            >
                                                            <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                                                {row.input ? (
                                                                    "Yes"
                                                                ):(
                                                                    "No"
                                                                )
                                                                }

                                                                    
                                                            </Typography>
                                                            
                                                        </Box>
                                                    </Box>
                                                )
                                            case "List":
                                                return(
                                                    <Box
                                                        key={row.id}
                                                        sx={{display:'flex', width:queryColumnSize[k].x, height: Object.keys(row.input).length*50, justifyContent:'center', alignItems:'center', }}
                                                        >
                                                        <Stack  direction='column' sx={{width: '100%', height:'100%', }}  divider={<Divider  sx={{opacity:1,}}/>}>
                                                            {row.input.map((list_i, j) => {
                                                                return(
                                                                    <Stack
                                                                    key={list_i.id}
                                                                    sx={{width: '100%', }}
                                                                    direction='row'
                                                                    divider={<Divider orientation='vertical' sx={{opacity:1}}/>}
                                                                    >
                                                                        <Box sx={{
                                                                            width:queryColumnSize[k].x * 0.375,
                                                                            height:50,
                                                                            display:'flex',
                                                                            alignItems:'center',
                                                                            justifyContent:'center'
                                                                            }}>
                                                                            <Box sx={{
                                                                                display:'flex',
                                                                                backgroundColor:'#777777',
                                                                                borderRadius: 3,
                                                                                color:"white",
                                                                                alignItems:'center',
                                                                                justifyContent:'center',
                                                                                paddingLeft: 1,
                                                                                paddingRight: 1
                                                                                }}>
                                                                                <Typography variant='body2' noWrap={true} sx={{ fontFamily:'Open Sans', fontSize:12}}>
                                                                                    {list_i.field_name}
                                                                                </Typography>
                                                                                <Tooltip title={`List field ${list_i.field_name} is of type ${list_i.input_type}`}>
                                                                                    <Box sx={{marginLeft: 1}}>
                                                                                        <GetIconByType type={list_i.input_type} />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Box>
                                                                        
                                                                        <Box sx={{width:queryColumnSize[k].x * 0.625, height:50, display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                            {list_i.input_type === "Toggle" ? (
                                                                                <Box sx={{
                                                                                    display:'flex',
                                                                                    backgroundColor:'#777777',
                                                                                    borderRadius: 3,
                                                                                    color:"white",
                                                                                    alignItems:'center',
                                                                                    justifyContent:'center',
                                                                                    paddingLeft: 1,
                                                                                    paddingRight: 1
                                                                                }}>
                                                                                    {list_i.input_value ? (
                                                                                        <Typography variant='body2' sx={{ fontFamily:'Open Sans',}}>
                                                                                            Yes
                                                                                        </Typography>
                                                                                    ): (
                                                                                        <Typography variant='body2' sx={{ fontFamily:'Open Sans',}}>
                                                                                            No
                                                                                        </Typography>
                                                                                    )}
                                                                                </Box>
                                                                            ) : (
                                                                                <Typography variant='body2' sx={{ fontFamily:'Open Sans',}}>
                                                                                    {list_i.input_value}
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    </Stack>
                                                                )
                                                            })}
                                                        </Stack>
                                                    </Box>
                                                )
                                            case "Date":
                                                return(
                                                    <Box
                                                        key={row.id}
                                                        sx={{display:'flex', width:queryColumnSize[k].x, height: queryColumnSize[k].y, justifyContent:'center', alignItems: 'center', }}
                                                        >
                                                            <Box sx={{
                                                                
                                                                display:'flex',
                                                                alignItems:'center',
                                                                justifyContent:'center'
                                                                
                                                            }}
                                                            >
                                                            <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                                                {dayjs(row.input).format('DD.MM.YYYY')}
                                                            </Typography>
                                                            
                                                        </Box>
                                                    </Box>
                                                )
                                            case "Time":
                                                return(
                                                    <Box
                                                        key={row.id}
                                                        sx={{display:'flex', width:queryColumnSize[k].x, height: queryColumnSize[k].y, justifyContent:'center', alignItems: 'center', }}
                                                        >
                                                            <Box sx={{
                                                                
                                                                display:'flex',
                                                                alignItems:'center',
                                                                justifyContent:'center'
                                                                
                                                            }}
                                                            >
                                                            <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                                                {dayjs(row.input).format('HH:mm')}
                                                            </Typography>
                                                            
                                                        </Box>
                                                    </Box>
                                                )
                                                case "Stopwatch":
                                                    return(
                                                        <Box
                                                            key={row.id}
                                                            sx={{display:'flex', width:queryColumnSize[k].x, height: queryColumnSize[k].y, justifyContent:'center', alignItems: 'center', }}
                                                            >
                                                                <Box sx={{
                                                                    
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    justifyContent:'center'
                                                                    
                                                                }}
                                                                >
                                                                <Typography variant='body2' sx={{fontFamily:'Open Sans'}}>
                                                                    {row.input.split(' ')[0]}
                                                                </Typography>
                                                                
                                                            </Box>
                                                        </Box>
                                                    )
                                        }
                                        
                                    })}
                                    </Stack>
                                )
                            })}
                        </Stack>
                        
                    </Box>
                </Box>
                )
                     
            ) : (
                <Box sx={{width:'100%'}}> 
                        <Typography variant='body1' sx={{fontFamily:'Open Sans'}}>
                            Depricated database item
                        </Typography>
                        <Divider sx={{opacity:1}}/>
                    </Box>
            )}
            
        </Box>
    )
    }