import { Box, Grid, Paper, styled, Button, Typography, TextField, Fade, IconButton,Divider, Collapse} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import { useEffect, useState , useRef, useContext,useCallback} from "react";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

export default function WorkStepsOperations() {

    const [openNewOpNameDrawer,setOpenNewOpNameDrawer] = useState(false);

    const handleChangeNewOpNameDrawer = () => {
        setOpenNewOpNameDrawer((prev) => !prev);
    }

    return(
        <Box sx={{width:'100%', height:'100%', padding:2}}>
            <Box>
                <Typography variant="h4" fontFamily={'Open Sans'} fontWeight={'bold'}>
                    Operations
                </Typography>
                <Divider sx={{color:'black'}}/>
            </Box>
            <Box sx={{display:'flex'}}>
                <IconButton onClick={handleChangeNewOpNameDrawer} sx={{borderRadius:1, }}>
                    <AddIcon sx={{backgroundColor:'#1976d2', transition:"all 0.5s ease", fontSize:36,borderRadius:1, color:'white', transform:openNewOpNameDrawer?'rotate(45deg)':null}} />
                </IconButton>
                <Collapse in={openNewOpNameDrawer} orientation="horizontal" >
                    <Box sx={{display:'flex',alignItems:'center'}}>
                        <TextField size="small" label='New operation name' />
                        <IconButton  sx={{borderRadius:1, }}>
                            <CheckIcon sx={{backgroundColor:'#1976d2',fontSize:36,borderRadius:1, color:'white'}} />
                        </IconButton>
                    </Box>
                    
                </Collapse>
                
            </Box>
            
        </Box>
    )
}