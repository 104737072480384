import { Box, Grow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SecurityIcon from '@mui/icons-material/Security';
import {Link} from 'react-router-dom';


export default function Unauthorized(){

    const [landed, setLanded] = useState(false);

    useEffect(() => {
      setLanded(true);
    
    }, [])
    

    return (
        <div className="d-flex justify-content-center align-items-center">
            <Grow in={landed}>
                <Box bgcolor={"lightgray"} sx={{width: '75ch', borderRadius: 1, p:2}}>
                    <div className="d-flex justify-content-center align-items-center">
                        {/* Insert Icon here */}
                        <SecurityIcon sx={{color: "white", fontSize:40}}/>
                        <Typography variant="h4" color='white'>
                            401 Unauthorized
                        </Typography>
                    </div>
                    <Box bgcolor={"white"} sx={{p:1, borderRadius: 2}}>
                        <div className="d-flex justify-content-center align-items-center" >
                            <Typography variant="body1" color='lightgray'>
                                Unauthorized access to a protected page. Make sure you are suppoused to have access and try logging in again.
                                 Use the links below to get back to the site.                                
                                
                            </Typography>
                        </div>
                        <br />
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/">Contact</Link>
                            </li>
                            <li>
                                <Link to="/">Ticket</Link>
                            </li>
                        </ul>
                    </Box>
                    
                </Box>
            </Grow>
        </div>
        
    )
}