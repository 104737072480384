//https://www.grapecity.com/wijmo/demos/Input/Popup/Dialogs/react
//Date picker
//puhelin näkymä
//kuva tiedosto
//puhelin applikaatio separaatio

//12.9
/*
Tämän katsaukselle on taas jälleen pienehköä edistyny tällä mallinnus kappale saralla
Saatu aikaan: Validaatiota, pohjustus kaavoista datan keräämisen

Kysymyksiä:
- Projektin ja raportin suhde
    - MvP ja työraportti?
    - demo ja ohjelmistosuunnitelma
    - 

- Jatketaanko palaverien muodossa katsauksia?
    - vaihtoehtoina tilannepäivitys säpolla
    -  
    -
*/

import React, { Component, useEffect, useState } from 'react';

import {Link, useParams, useNavigate, useLocation} from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../api/axios'
import { useScrollTrigger, Typography, Box, Stack, Menu, MenuItem, InputAdornment, Input, Collapse, Fade, ToggleButton, ToggleButtonGroup  } from '@mui/material';
import Button from '@mui/material/Button';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import WindowIcon from '@mui/icons-material/Window';
import TableRowsIcon from '@mui/icons-material/TableRows';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderIcon from '@mui/icons-material/Folder';
import CircleIcon from '@mui/icons-material/Circle';

import { useMediaQuery } from '../api/utils';


import IconButton from '@mui/material/IconButton';
import useAuth from '../hooks/useAuth';
import { UserHasPermissions } from '../api/utils';
import FormCardElement from './form-card-element';
import FormFolder from './form-folder.component';
import QueryList from './query-list.component';

import FormListGridView from './form-list-gridview.component';
import FormListListView from './form-list-listview.component';
import FormListMixedView from './form-list-mixedview.component';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const _folders = [
    {"name":"Home", "_id":'0', "isroot":true, "color":"#ffffff","parent":"","children":[]},
    
]

const viewMode = Object.freeze({
    Grid: 0 ,
    List: 1,
    Mixed: 2,
})

export default function FormList() {
    const {auth} = useAuth();
    const [formData, setFormData] = useState([]);
    const [formQueries, setFormQueries] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [formTitle, setFormTitle] = useState("");
    const [formDesc, setFormDesc] = useState("");
    const [titleIsEmpty, setTitleIsEmpty] = useState(true);
    const [success_create, setSuccess_create] = useState(false);
    const [fileCreateDDElAnchor, setFileCreateDDElAnchor] = useState(null);

    const [deleteAssurance, setDeleteAssurance] = useState(false);
    const [formToBeDeleted, setFormToBeDeleted] = useState(null);
    const [loadingForms, setLoadingForms] = useState(true);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [filterString, setFilterString] = useState('')

    
    const [folders, setFolders] = useState(_folders);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [foldersRootified, setFoldersRootified] = useState(false);
    const [loadingFolders, setLoadingFolders] = useState(true);
    const [openFolderCreatePU, setOpenFolderCreatePU] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    const [folderColor, setFolderColor] = useState('#ffffff');
    const handleFolderColorChange = (event, newColor) => {
        setFolderColor(newColor);
    }

    const [currentViewMode, setCurrentViewMode] = useState(viewMode.Grid);
    const handleViewModechange = (e, mode) => {
        setCurrentViewMode(mode);
    }

    const navigate = useNavigate();
    const location = useLocation();
    const fileCreateDDopen = Boolean(fileCreateDDElAnchor);

    useEffect(() => {
        if(formTitle.length>1){
            setTitleIsEmpty(false)
        } else {
            setTitleIsEmpty(true);
        }
        

    })



    
    const getQueryObjById = (id) => {
        const data = formQueries.find((row) => row.form_pair_id === id);
        if(data !== undefined && Object.keys(data).length > 0){
            return data
        }
        return null;
    }

    const getFillCounter = (id) => {
        
        const found = formQueries.find((row) => row.form_pair_id === id);
        if(found !== undefined && found.fill_counter !== undefined){
            return found.fill_counter;
        }
        return "NaN";
        /*
        formQueries.map((row, i) => {
            
            if(row.form_pair_id !== undefined){
                
                if(row.form_pair_id === id){
                    console.log("match "+"paired id: "+row.form_pair_id+", row id: "+id+", fill counter: "+row.fill_counter)
                    return row.fill_counter;
                }else{
                    return "NaN";
                }
            }
        })
        */
    }

    const getFormsAndFolders = async() =>{
        setLoadingForms(true);
        setLoadingFolders(true);
        //setLoadingFolders(true);

        let tforms = await fetchForms();
        let tqueries = await fetchQueries();
        let tfolders = await fetchFolders();

        setFolders([_folders[0],...tfolders]);
        setCurrentFolder(folders.find((folder) => folder.isroot === true))
        
        
        setLoadingFolders(false);
        console.log(tforms,tfolders)
        
    }

    async function fetchQueries(){
        try {
            const res = await axios.get('/queries', {params: {"server":auth.server}});
            console.log(res.data)
            setFormQueries(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchForms(){
        //setLoadingForms(true);
        try {
            try {
                const server = "demo";
                const response = await axios.get('/forms/', {params: {"server":auth.server}});
                setFormData(response.data);
                console.log(response.data);
                return response.data
            } catch (error) {
                console.log(error);
            }
        } finally {
            setLoadingForms(false);

            console.log("fetched forms");
        }
    }

    const idklmao = async() => {
        try {
            try {
                const server = "demo";
                const response = await axios.get('/forms/', {params: {"server":auth.server}});
                setFormData(response.data);
                console.log(response.data);
                return response.data
            } catch (error) {
                console.log(error);
            }
        } finally {
            setLoadingForms(false);

            console.log("fetched forms");
        }
    }

    async function fetchFolders(){
        
            try {
                const res = await axios.get('/folders',{params: {"server":auth.server}});
                return res.data
            } catch (error) {
                console.log(error);
            }
        
    }

    useEffect(()=>{
        getFormsAndFolders();
  
        
        if(location.state !== null){
            if(location.state.success_create){
                setSuccess_create(location.state.success_create);
            }
            if(location.state.lastview){
                setCurrentViewMode(location.state.lastview)
            }
        }
        

    },[])


    

    function filterListItems() {
        /*
        if((filterString.length > 0 && filterString !== undefined)){
            let items = formData.filter(form => form.name.includes(filterString))
            setFilteredItems(items)
        } else {
            setFilteredItems(formData);
        }
        */
    }


    const updateFilterString = (e) => {
        setFilterString(e.target.value);
        filterListItems();
    }

    const onTitleChange = (event) => {
        setFormTitle(event.target.value);

    }

    const onDescChange = (event) => {
        setFormDesc(event.target.value);
    }

    const openCreateFormVerif = () =>{
        setFileCreateDDElAnchor(null)
        setFormDesc("");
        setFormTitle("");
        setPopupOpen(true);

    }

    const handleOpenFolderCreatePU = () =>{
        setOpenFolderCreatePU(true);
    }

    const continueToCreateForm = (event) => {
        //validate
        if(!titleIsEmpty){
            setPopupOpen(false);
            //localStorage.setItem("tempformname",formTitle);
            if(currentFolder){
                navigate("/create-form", {state:{"formname":formTitle,"folder":currentFolder,"lastview":currentViewMode}});
            }
            
        }
    }

    const closeCreateFormVerif = () =>{
        setPopupOpen(false);
    }

    

    //popup close
    const handleClose_formcreatepu = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccess_create(false);
      };
    const handleClose_formdeletepu = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

        setDeleteSuccess(false);
    };

   

    const closeSuretodelete = () => {
        setDeleteAssurance(false);
        setFormToBeDeleted(null);
    }

    const closeCreateFolderPU = () => {
        setOpenFolderCreatePU(false);
    }

    const saveNewFolder = () => {
        const newFolder = {
            "parent":currentFolder._id,
            "isroot":false,
            "color":folderColor,
            "name":newFolderName,
            "server":auth.server,
        }
        axios.post("/folders/add", newFolder)
        .then((res) => {
            console.log(res.data.message)
            console.log(res.data.back)
            const child = res.data.back
            

            setFolders([
                ...folders,
                child
            ])
        })
        .catch((err) => {
            console.log(err)
        })
        closeCreateFolderPU();
    }

    const addFolderCb = (folder) => {
        setFolders([
            ...folders,
            folder
        ])
    }

    function suretodeletepopup(id){
        if(formToBeDeleted === null){
            console.log(id)
            setDeleteAssurance(true);
            setFormToBeDeleted(id);
        }else {
            console.log("async select denied");
        }
        
    }
    const openFileCreateDD = (e) => {
        setFileCreateDDElAnchor(e.currentTarget);
    }
    const closeFileCreateDD = () => {
        setFileCreateDDElAnchor(null);
    }

    const DeleteForm = () => {
        setDeleteSuccess(false);
        //setLoadingForms(true);
        if(formToBeDeleted && formToBeDeleted !== undefined){
            console.log(formToBeDeleted);
            
            axios.post('/queries/removebypairid/'+formToBeDeleted.toString())
            .then(res => {
                console.log(res.data); 
                console.log(formToBeDeleted);
                //if delete successfull -> delete data
            })
            .catch(err => console.log(err))

            axios.post('/forms/remove', {id: formToBeDeleted})
            .then(res => {
                console.log(res.data); 
                console.log(formToBeDeleted);
                //if delete successfull -> delete data
            })
            .catch(err => console.log(err))
            .finally(() => {
                var temparray = [...formData];
                let index = 0;
                temparray.map((obj, i) => {
                    if(obj._id === formToBeDeleted){
                        index = i;
                    }
                })
                if (index !== -1) {
                    temparray.splice(index, 1);
                    setFormData(temparray);
                    console.log(formData);
                }
                setFormToBeDeleted(null)
                setDeleteAssurance(false);
                setDeleteSuccess(true);
                //setLoadingForms(false);
                //fetchForms();
                
            });
        }
        
    }

    

    const openFolder = (folder) => {
        setCurrentFolder(folder);
        setFilterString('')
        console.log(folder);
    }

    const openForm = (_obj) => {
        navigate("/fill/"+_obj._id)
    }

    //Convert Userhaspermissions to boolean

    //📁 folder sort functions

    const currentFolderChildren = () => {
        // 🤓 parses current folder children array and returns array of objects based on refrences in that children array
        
        if(currentFolder && currentFolder !== undefined && folders !== undefined){
            let tempcurfolder = currentFolder;
            //let foldersArr = [];
            //let formsArr = [];
            let content = []
            
            let formdataclone = formData;
            formdataclone.map((form, i) => {
                if(form.folder === tempcurfolder._id){
                    content.push({"data":form, "type":"form"})
                }
            })

            let childfolders = folders
            
            childfolders.map((folder, i ) => {
                if(folder.parent === tempcurfolder._id){
                    content.push({"data":folder, "type":"folder"})
                }
            })
            

            console.log(content);
            return content;
        }else {
            return null
        }
    }


    // 🌲 Compatibility check after fetching forms.
    // double checking if any fetched folder has children by the id of forms fetched, if not -> add them to root folder
    // (backwards compatibility function for forms created before folders were deployed)
    const rootifyForms = (tforms,tfolders) => {
        // store a token in cookies to check if this has been checked already this session 🍪
        if(tforms !== null && tforms !== undefined){
            console.log("reached execution");
            const tempformdata = tforms;
            let formsToAdd = [];
            console.log(tempformdata);
            for (let i = 0; i < tempformdata.length; i++) {
                const form = tempformdata[i];
                //loop through folders
                for (let j = 0; j < tfolders.length; j++) {
                    const tempfolder = tfolders[j];
                    console.log(tempfolder);
                    let test = tempfolder.children.find((child) => child.split(":")[0]==='form'&&child.split(":")[1]===form._id)
                    //if test failed, undefined is returned, add form as child to root
                    console.log(test);
                    if(test === undefined){
                        formsToAdd.push('form:'+form._id);
                    }
                }
            }
            console.log(formsToAdd);
            setFolders(prev => {
                const newFolders = [...prev];
                newFolders[0] = {
                    ...newFolders[0],
                    children: [...newFolders[0].children, ...formsToAdd]
                };
                return newFolders
            });
        }
        
    }

    const handleNewFormNameOnChange = (e) => {
        setNewFolderName(e.target.value)
    }

    //🔗 Link functions

    const BuildFolderLink = () => {
        //current folder null check done FE, double check
        if(currentFolder !== null && currentFolder !== undefined && currentFolder != false){
            let tempfolder = currentFolder;
            let parent = folders.find((folder) => folder._id === tempfolder.parent);
            let product = [];
            while(tempfolder !== '' && tempfolder !== null  && tempfolder !== undefined && !tempfolder.isroot){
                
                    product.push({"name":tempfolder.name,"id":tempfolder._id})
                    tempfolder = parent;
                    if(tempfolder !== null && tempfolder !== undefined){
                        parent = folders.find((folder) => folder._id === tempfolder.parent);
                    }
                
            }
            product.push({"name":"Home", "id":"0"})
            product.reverse();
            return(
                <Box sx={{display:'flex'}}>
                    {(product.map((obj, index) => {
                        return(
                            <Fade in={true} style={{transitionDelay:(100+100*index).toString()+"ms"}} key={obj.id}>
                                <Box sx={{display:'flex', alignItems:'center'}}>
                                    <Typography variant='h6' sx={{fontFamily:'Open Sans', marginRight:1}} component={Link}  onClick={() => navigateFolderLink(obj.id)}>{obj.name}</Typography>
                                    <Typography variant='h6' sx={{fontWeight:'bold',display:index===product.length-1 ? 'none': null, marginRight:1}}>/</Typography>
                                </Box>
                            </Fade>
                            
                            
                        )
                    }))}
                    
                </Box>
            )
        }else{
            return null
        }
    }

    const folderChildrenAmount = (id) => {
        if((formData&&formData!== undefined)&&(folders&&folders!==undefined)){
            let amount = 0;
            let childfolders = folders;
            folders.map((folder, i) => {
                if(folder._id === id){
                    amount++;
                }
            })
            
            let childforms = formData;
            childforms.map((form, i) => {
                if(form.folder === id){
                    amount++;
                }
            })
            return amount
        } else {
            return 0
        }
        

    }

    const moveFolderChildren = async (children, destination) => {
        //children will be in form 
        /*
        child:{
            type: "folder" or "form",
            data: folder.obj or form.obj
        }
        */
        //save ids in different arrays, handle arrays seperately
        if(children !== undefined && children.length > 0){
            let form_ids_tobedeleted = []
            let folder_ids_tobedeleted = []
            console.log("landed");
            children.map((child) => {
                if(child.type === "form"){
                    form_ids_tobedeleted.push(child.data._id);
                }else if(child.type === "folder"){
                    folder_ids_tobedeleted.push(child.data._id);
                }
            })

            if(form_ids_tobedeleted.length > 0){
                await axios.post("/forms/updateMany", {server:auth.server,idlist:form_ids_tobedeleted,newfolder:destination})
                    .then(res => {console.log(res)})
                    .catch(err => {console.log(err)});
                
            }
            if(folder_ids_tobedeleted.length > 0){
                await axios.post("/folders/updateMany", {server:auth.server,idlist:folder_ids_tobedeleted,newfolder:destination})
                    .then(res => {console.log(res)})
                    .catch(err => {console.log(err)});
            }
            /*
            const temforms = [...formData]
            const updatedfordata = temforms.map((fomrdata) => {
                if(form_ids_tobedeleted.includes(fomrdata.folder)){
                    return({
                        ...fomrdata,
                        folder:destination
                    })
                }else{
                    return({...fomrdata})
                }
                
            })
            setFormData(updatedfordata);
            */
            //console.log(updatedfordata);
            getFormsAndFolders();
        }else{
            console.log("failed")
        }
        
        //
        
    } 


    const updateChildrenCB = async (children) => {
        //children will be in form 
        /*
        child:{
            type: "folder" or "form",
            data: folder.obj or form.obj
        }
        */
        //save ids in different arrays, handle arrays seperately
        if(children !== undefined && children.length > 0){
            let form_ids_tobedeleted = []
            let folder_ids_tobedeleted = []
            children.map((child) => {
                if(child.type === "form"){
                    form_ids_tobedeleted.push(child.data._id);
                }else if(child.type === "folder"){
                    folder_ids_tobedeleted.push(child.data._id);
                }
            })

            if(form_ids_tobedeleted.length > 0){
                await axios.post("/forms/removeMany", {server:auth.server,idlist:form_ids_tobedeleted})
                    .then(res => {console.log(res)})
                    .catch(err => {console.log(err)});
                await axios.post("/queries/removeMany", {server:auth.server,idlist:form_ids_tobedeleted})
                    .then(res => {console.log(res)})
                    .catch(err => {console.log(err)});
            }
            if(folder_ids_tobedeleted.length > 0){
                axios.post("/folders/removeMany", {server:auth.server,idlist:folder_ids_tobedeleted})
                    .then(res => {console.log(res)})
                    .catch(err => {console.log(err)});
            }
            getFormsAndFolders();
        }
        
        //
        
    } 
    
    const navigateFolderLink = (id) => {
        console.log(folders);
        let navtarget = folders.find((folder) => folder._id === id)
        if(currentFolder._id !== navtarget._id && navtarget !== undefined){
            setCurrentFolder(navtarget);
        }
    }

        return (
            <Box>
                <Box sx={{display:'flex', justifyContent: 'space-between' }}>
                    <Box sx={{display:'flex', justifyContent: 'start'}}>
                        <Button onClick={openFileCreateDD} id='filecreatebutton' variant='contained' sx={{marginLeft: 1.2, display:currentViewMode!==viewMode.Mixed?(null):("None")}}
                            aria-controls={fileCreateDDopen ? 'filecreatemenu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={fileCreateDDopen ? 'true' : undefined}
                            endIcon={<KeyboardArrowDownIcon/>}
                        >New</Button>

                        <Menu
                            id='filecreatemenu'
                            MenuListProps={{
                                'aria-labelledby': 'filecreatebutton'
                            }}
                            anchorEl={fileCreateDDElAnchor}
                            open={fileCreateDDopen}
                            onClose={closeFileCreateDD}
                        >
                            <MenuItem onClick={openCreateFormVerif}>
                                <AssignmentIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                Form
                            </MenuItem>
                            <MenuItem onClick={handleOpenFolderCreatePU}>
                                <FolderIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                Folder
                            </MenuItem>
                            <MenuItem>
                                <TextSnippetIcon sx={{marginRight: '18px', color:'gray', fontSize: 18}}/>
                                Text
                            </MenuItem>
                        </Menu>
                        
                    </Box>
                    <Box sx={{display:currentViewMode!==viewMode.Mixed?('flex'):("None"), alignItems:'center', } }
                        component="form"
                        autoComplete='off'
                        onSubmit={e => {e.preventDefault();}}
                        noValidate
                    >
                        <TextField variant='standard' placeholder='Search' sx={{width: {xs:'100px', sm:'300px', md:'500px'}}}
                            onChange={updateFilterString}
                            value={filterString}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <IconButton onClick={() => {setFilterString('')}}>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center', width: 'fit-content'}}>
                        <ToggleButtonGroup
                            value={currentViewMode}
                            exclusive
                            onChange={handleViewModechange}
                        >
                            <ToggleButton value={viewMode.Grid}>
                                <WindowIcon/>
                            </ToggleButton>
                            <ToggleButton value={viewMode.List}>
                                <TableRowsIcon/>
                            </ToggleButton>
                            <ToggleButton value={viewMode.Mixed}>
                                <SpaceDashboardIcon/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
                
                <Dialog fullWidth={true} maxWidth="sm" open={popupOpen} onClose={closeCreateFormVerif}>
                    <DialogTitle>Create form</DialogTitle>
                     <DialogContent>
                        <DialogContentText>Start by giving your form a title!</DialogContentText>
                        <div>
                            
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Title*"
                                onChange={onTitleChange}
                                type="email"
                                fullWidth
                                variant="standard"
                                autoComplete='off'
                            />
                        </div>
                        
                     </DialogContent>
                     <DialogActions>
                        <div className='d-flex align-items-center'>
                            <button className="btn btn-primary btn-space me-1" onClick={closeCreateFormVerif}>Back</button>
                            {titleIsEmpty ? (<button className="btn btn-primary btn-space disabled" >Continue</button>) : (<button className="btn btn-primary btn-space" onClick={continueToCreateForm}>Continue</button>)}
                        </div>
                     </DialogActions>
                </Dialog>   
                <br />

                {/* IN CASE OF ERROR: "loadingForms||loadingFolders||currentViewMode != viewMode.Grid"
                    used to be loadingForms&&loadingFolders&&currentViewMode != viewMode.Grid
                    IDK I CANT DO BASIC LOGICS
                */}
                <Collapse in={currentFolder !== null && filterString.length === 0 &&filterString===''}>
                    {(loadingForms||loadingFolders||currentViewMode != viewMode.Grid)?(null):(
                        <Box sx={{marginLeft:2, marginBottom:1, height:"30px", alignItems:'center'}}>
                            <BuildFolderLink/>
                        </Box>
                    )

                    }
                    
                </Collapse>
                <br />
                
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    {(!loadingForms&&!loadingFolders&&currentFolder!==undefined) //+ Loading folders
                    ?
                    (
                       currentViewMode == viewMode.Grid
                       ?
                       (
                        <FormListGridView
                            formData={formData}
                            currentFolder={currentFolder}
                            currentFolderChildren={currentFolderChildren}
                            folderChildrenAmount={folderChildrenAmount}
                            openFolder={openFolder}
                            filterString={filterString}
                            suretodeletepopup={suretodeletepopup}
                            getFillCounter={getFillCounter}
                            getQueryObjById={getQueryObjById}
                            auth={auth}
                            folders = {folders}
                        />
                       ):
                       (
                        currentViewMode== viewMode.List
                        ?
                        (
                            <FormListListView
                                formData={formData}
                                currentFolder={currentFolder}
                                currentFolderChildren={currentFolderChildren}
                                folderChildrenAmount={folderChildrenAmount}
                                openFolder={openFolder}
                                filterString={filterString}
                                suretodeletepopup={suretodeletepopup}
                                getFillCounter={getFillCounter}
                                getQueryObjById={getQueryObjById}
                                auth={auth}
                                folders = {folders}
                            />
                        ):
                        (
                            <FormListMixedView
                                formData={formData}
                                currentFolder={currentFolder}
                                setCurrentFolder={navigateFolderLink}
                                currentFolderChildren={currentFolderChildren}
                                folderChildrenAmount={folderChildrenAmount}
                                currentViewMode={currentViewMode}
                                openFolder={openFolder}
                                filterString={filterString}
                                suretodeletepopup={suretodeletepopup}
                                getFillCounter={getFillCounter}
                                getQueryObjById={getQueryObjById}
                                updateChildrenCB={updateChildrenCB}
                                addFolderCb={addFolderCb}
                                moveFolderChildren={moveFolderChildren}
                                auth={auth}
                                folders = {folders}
                            />
                        )
                       )
                    )
                    :
                    (
                        /* Display loading */
                        <Box sx={{width: '100%'}}>
                            <LinearProgress/>
                            <Typography variant='subtitle1'>
                                Loading Forms data...
                            </Typography>
                        </Box>
                    )
                    }

                    <Dialog fullWidth={true} open={deleteAssurance} onClose={closeSuretodelete}>
                        <DialogTitle>Delete Form</DialogTitle>
                            <DialogContent>
                                <DialogContentText>Are you sure you want to delete this form</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Box sx={{justifyContent:'start', alignItems:'center'}}>
                                    <Button variant='contained' onClick={closeSuretodelete}>
                                        Back
                                    </Button>
                                    <Button variant='contained' color='error' onClick={DeleteForm}>
                                        Delete
                                    </Button>
                                </Box>
                            </DialogActions>
                    </Dialog>
                    {/* Create folder dialog 📁 */}   
                    {!currentFolder ? (null) : (
                        <Dialog fullWidth={true} open={openFolderCreatePU} onClose={closeCreateFolderPU}>
                            <DialogTitle>Create Folder</DialogTitle>
                            <DialogContent>
                                <DialogContentText>Creating folder inside folder: {currentFolder.name}</DialogContentText>
                                <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Title*"
                                onChange={handleNewFormNameOnChange}
                                type="email"
                                fullWidth
                                variant="standard"
                                autoComplete='off'
                            />
                                
                                
                            </DialogContent>
                            <DialogContent>
                                <DialogContentText>
                                    Folder color
                                </DialogContentText>
                                <Stack>
                                    <ToggleButtonGroup size='small' value={folderColor} onChange={handleFolderColorChange} exclusive={true}>
                                        <ToggleButton value="#ffffff" key="#ffffff">
                                            <CircleIcon sx={{color:'#ffffff', fontSize:30}}/>
                                        </ToggleButton>
                                        <ToggleButton value="#84cdee" key="#84cdee">
                                            <CircleIcon sx={{color:'#84cdee',fontSize:30}}/>
                                        </ToggleButton>
                                        <ToggleButton value="#ff9b9b" key="#ff9b9b">
                                            <CircleIcon sx={{color:'#ff9b9b',fontSize:30}}/>
                                        </ToggleButton>
                                        <ToggleButton value="#c6c1ff" key="#c6c1ff">
                                            <CircleIcon sx={{color:'#c6c1ff',fontSize:30}}/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Box sx={{justifyContent:'start', alignItems:'center'}}>
                                    <Button onClick={closeCreateFolderPU} variant='contained' >
                                        Exit
                                    </Button>
                                    <Button onClick={saveNewFolder} variant='contained' color='primary' >
                                        Create
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Dialog>
                    )}
                    
                    
                    <Snackbar open={success_create} onClose={handleClose_formcreatepu} autoHideDuration={4000}>
                        <MuiAlert severity='success' onClose={handleClose_formcreatepu} elevation={6} >
                            Form created successfully!
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={deleteSuccess} onClose={handleClose_formdeletepu} autoHideDuration={4000}>
                        <MuiAlert severity='warning' onClose={handleClose_formdeletepu} elevation={6} >
                            Deleted form successfully!
                        </MuiAlert>
                    </Snackbar>
                </Box>
            </Box>
        )
    }