import axios from 'axios';
import {API_URL} from "./environtment";
//const BASE_URL = 'http://localhost:5000';


//const HOSTING_URL = 'https://demohiava-backend.onrender.com';

export default axios.create({
    baseURL: API_URL
});

export const axiosPrivate = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});