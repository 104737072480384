//ERROR: "Cannot read properties of undefined (reading 'params')"
// tutustu uuteen router id hommaan asda sd


// kerää formdatarow elementeistä input ja postaa db

import React, { Component, Fragment, useEffect, useState } from 'react';
import FillDataRow from './fill-datarow.component.js'

import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from '../api/axios'


import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, Box, Typography, Chip, Tooltip, Button, CircularProgress, IconButton, Grow} from '@mui/material';
import Switch from '@mui/material/Switch';
import DataRow from './datarow.component.js';
import useAuth from '../hooks/useAuth.js';
import { UserHasPermissions } from '../api/utils.js';
import { green, red } from '@mui/material/colors';
import { Reorder } from 'framer-motion';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

    


export default function FormFill({formdata, queryTable, readOnly, injectQueryData, _rememberViewMode}) {

    const [rowdata, setRowdata] = useState([]);
    const [editRowdata, setEditRowData] = useState([])
    const [formId, setFormId] = useState();
    const [editRowItem, setEditRowItem] = useState([])
    const [editRowCounter, setEditRowCounter] = useState(1);
    const [editIDCounter, setEditIDCounter] = useState()
    const [querydata, setQueryData] = useState([]); //query data got from input -> sent to DB
    const [formname, setFormname] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [_queryTableData, _setQueryTableData] = useState({}); //query data injected from formList to check status of query table
    const [copyFormOpen, setCopyFormOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [validForSubmit, setValidForSubmit] = useState(false);
    const [validationPopup, setValidationPopup] = useState(false);
    const [rememberViewMode, setRememberViewMode] = useState(null)

    const {id} = useParams();
    const {auth} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setRememberViewMode(_rememberViewMode);
    }, [])
   

    useEffect(() => {
        if(formdata !== null && formdata !== undefined){
             
            if(readOnly){
                setRowdata(formdata.build_instruction);
                setFormname(formdata.name);
                setWithRowCallBack(injectQueryData);
                console.log(injectQueryData);
                //setQueryData(injectQueryData)
            } else {
                setRowdata(formdata.build_instruction);
                setFormname(formdata.name);
                setFormId(formdata._id);
            }
        }
        
        /*
        axios.get('/forms/'+id)
        .then(response => {
            setRowdata(response.data.build_instruction);
            setFormname(response.data.name);
            setFormId(response._id);
            
        })
        .catch(function (error) {
            console.log(error);
        })
        */
        const quu = {
            "pairid": formId
        }
        
        if(queryTable!==null && queryTable !== undefined){
            _setQueryTableData(queryTable)
        }
        checkFillFormValidation()
        if(!readOnly){

            if(!validForSubmit){
                setTimeout(function() { 
                    if(!validForSubmit){
                        setValidationPopup(true);
                    }
                 }, 5000);
            }
        }
        
        console.log(rowdata);
        console.log(querydata);
        setEditMode(false);
    }, [])
    
    useEffect(() => {
        checkFillFormValidation();
    }, [querydata])
    


    const checkFillFormValidation = () => {
        //iterate datarows, check no empty datarows exist

        //idea maybe give more validation criteria with additional info
        const temp = querydata;
        let valid = true;
        if(querydata === null || querydata.length === 0){
            valid = false;
        }else {
            temp.map((row) => {
                //console.log(row);
                if(row.input_required){
                    if(row.input_type === 'List'){
                        row.input.map((_row) => {   
                            if(_row.input_value === ''){
                                valid = false;
                            }
                        })
                    }else {
                        if(row.input === ''){
                            valid = false;
                        }
                    }
                }
            })
        }
        if(valid) {setValidationPopup(false)}
        setValidForSubmit(valid);
    }

    const getHighestID = () => {
        let temp = 0;
        rowdata.map((row, index) => {
            if(Number(row.id) > temp){
                temp = Number(row.id)
            }
        })
        return temp;
    }

    const setWithRowCallBack = (qrs) => {
        console.log(qrs.query);
        
        const queryslen = Object.keys(qrs.query).length;
        console.log(queryslen);
        for(let i = 0; i < queryslen; i++){
            rowCallback(qrs.query[i])
            /*
            setQueryData(querydata=> [...querydata, {
                'query':Object.keys(qrs)[0][i].query,
                'input':Object.keys(qrs)[0][i].input,
                'input_type':Object.keys(qrs)[0][i].input_type
            }])
            */
        }
        console.log(querydata);
    }

    const returnQueryRow = (row) => {
        console.log(row.property_name);
        
        const result = querydata.find(queryrow => queryrow.query === row.property_name);
        console.log(result)
        return result;
    }

    const rowCallback = (value) =>{
        if(querydata && !querydata.length){
            //row data sets is empty, push item
            setQueryData(querydata => [...querydata, value])
            console.log(querydata);
        } else {
            const updatedQuerydata = querydata.map(queryrow => {
                if(queryrow.query === value.query){
                    return {
                        ...queryrow,
                        input: value.input,
                        input_type: value.input_type
                    };
                } else {
                    return queryrow;
                }
            })
            setQueryData(updatedQuerydata);

            if (!querydata.find(obj => obj.query === value.query)){
                //this.state.rowDatasets.push(value);
                setQueryData(querydata => [...querydata, value])
            }

        }
        console.log(querydata)
    }

    const handleRowAdd = () =>{
        setEditRowCounter(editRowCounter => editRowCounter + 1);
        setEditIDCounter(editIDCounter => editIDCounter + 1);
        setEditRowData(editRowData => [...editRowData, 
            {'row':editRowCounter+1,
            'id': editIDCounter
        }])
        console.log(editRowCounter);
    }

    const editRowAdd = () =>{
        
        setEditIDCounter(editIDCounter => editIDCounter + 1);
        setEditRowItem(editRowItem => [...editRowItem, 
            {'row':editRowCounter,
            'id': editIDCounter
        }])
        console.log("editrow:"+editRowItem);
    }

    const editRowCallback = (value) =>{
        if(editRowdata && !editRowdata.length){
            //row data sets is empty, push item
            //this.state.rowDatasets.push(value)
            setEditRowData(editRowdata => [...editRowdata, value])
            console.log(editRowdata);
        } else {
            const updatedRowdatasets = editRowdata.map(rowdata => {
                if(rowdata.id === value.id){
                    return {
                        ...rowdata,
                        property_name: value.property_name,
                        property_type: value.property_type,
                        require_field: value.require_field,
                        additional_info: value.additional_info
                    };
                } else {
                    return rowdata;
                }
            })
            setEditRowData(updatedRowdatasets);

            /*
            if (!editRowdata.find(obj => obj.id === value.id)){
                setEditRowData(editRowdata => [...editRowdata, value])
            }
            */
        }
        console.log(editRowdata)
    }

    const uploadImgToCloudinary = async(file) => {
        const formdata = new FormData()
        formdata.append("file", file);
        formdata.append("upload_preset", "demoHiava");
        let data ="";
        await axios.post("https://api.cloudinary.com/v1_1/dvdppzf2v/image/upload", formdata)
        .then((res) => {
            data = res.data.url;
            
        });
        console.log(data);
        return data;
    }

    const formSubmitSave = async () =>{
        setSubmitting(true);
        const temp = querydata
        console.log(temp);

        const updatedarr = await Promise.all(temp.map(async (query) => {
            if(query.input_type !== 'Image'){
                return query;
            }else if(!query.input_required && (query.input === null || query.input === "")){
                return query;
            }
            const _data = await uploadImgToCloudinary(query.input)
            console.log(_data);
            return {
                ...query,
                input: _data
            }
                
        }))
        
        console.log(updatedarr);
        setQueryData(updatedarr);

        const query = {
            //userid which user left the query
            query: updatedarr,
            formname: formname,
            server:auth.server
        }
        console.log(query);

        axios.post('/queries/add-query-data', query)
        .then(res => {
            console.log(res.data);
            setSubmitting(false);
        })
        .catch(err => {
            console.log(err);
        })
        navigate("/forms", {
            state: {
                success_create: false,
                lastview: rememberViewMode
            }
        });
        
    }

    const moveRowDown = (rownum) => {
        //replace rowDatasets with current working row data array
        
        if(rownum !== undefined && rownum !== editRowdata.length-1){
            let temp1 = editRowdata[rownum];
            let temp2 = editRowdata[rownum+1];
            
            setEditRowData(editRowdata.map((row, i) => {
                if(i == rownum){
                    return temp2;
                } else if(i == rownum+1){
                    return temp1;
                }else{
                    return row;
                }
            })

            )
        }
    }   
    const moveRowUp = (rownum) => {
        //replace rowDatasets with current working row data array
        
        if(rownum !== undefined && rownum !== 0){
            let temp1 = editRowdata[rownum];
            let temp2 = editRowdata[rownum-1];
            setEditRowData(editRowdata.map((row, i) => {
                if(i == rownum){
                    return temp2;
                } else if(i == rownum-1){
                    return temp1;
                }else{
                    return row;
                }
            })

            )
        }
    }

    const toggleEditModeOn = () => {
        //add cat
        setEditRowCounter(rowdata.length);
        
        setEditRowData(rowdata);
        if(editIDCounter === undefined || editIDCounter === null){
            setEditIDCounter(getHighestID()+1);
            console.log("SETTING HIGHEST ID "+getHighestID());
        }
        console.log("editrows: "+editRowdata);
        setEditMode(true);
    }
    const toggleEditModeOff = () => {
        setEditMode(false);
        setEditRowCounter(1);
        setEditRowItem([]);
        
    }

    const rowDeleteCallfunction = (e) => {
        popfromRowDatasets(e);
    }

    function popfromRowDatasets(target){
        var temparr = [...editRowdata]
        if(editRowdata.length > target.row && target.row >= 0){

            var whaa = temparr.splice(target.row, 1)
            console.log(whaa);
            console.log(temparr);
            const updatedRowdatasets = temparr.map((rowdata, index) => {
                
                return {
                    ...rowdata,
                    row: index+1
                };
                
            })
            console.log(updatedRowdatasets);
            setEditRowData(updatedRowdatasets);
            //setRowDataSets(rowDatasets.filter((o, i) => target.id-1 !== i));
            setEditRowCounter(editRowCounter => editRowCounter-1);
            console.log(editRowdata);
        }
    }

    const saveFormEdit = () => {
        if(_queryTableData.query_data && _queryTableData.query_data.length > 0){
            //The edited form contains data in previous version. Proceed appropriately
            console.log(_queryTableData.name+" contains "+_queryTableData.query_data.length+" existing filled queries");
        }else {
            //the edited form contains no data in previous version.
            console.log(_queryTableData.name+" contains no existing filled queries");
            axios.post("/forms/updateById", {"id":id,"build_instruction": editRowdata,"server":auth.server})
            .then(res => console.log(res.data))
            .catch(err => console.log(err));
        }
        setRowdata(editRowdata);
        toggleEditModeOff();
    }

    function debu() {
        console.log(rowdata);
    }

        return (
            <Box sx={{backgroundColor:"rgba(250,250,250,255)"}}>
                <Box>
                    
                </Box>
                <div className='d-flex justify-content-between text-white' >
                    <div className='d-flex align-items-center ms-2'>
                        
                    </div>
                    <div className='d-flex align-items-center '>
                        
                    </div>
                    <Box sx={{display:readOnly ? 'none' : 'flex', alignItems:'center'}}  >
                            {UserHasPermissions(auth.permissions, ["Edit"]) 
                            ? 
                                editMode
                                ? 
                                    <ButtonGroup variant='contained' arial-label='Actions button group'>
                                        <Button onClick={() => toggleEditModeOff()} variant="contained" color='error' >Cancel</Button>
                                        <Button onClick={saveFormEdit} variant="contained" color='success' >Save</Button>
                                        <Button onClick={debu} variant="contained" endIcon={<HelpRoundedIcon/>}>Details</Button>
                                        
                                    </ButtonGroup>
                                
                                : 
                                
                                    <ButtonGroup variant='contained' arial-label='Actions button group'>
                                        <Button onClick={toggleEditModeOn} variant="contained" color='warning' >Edit</Button>
                                        {submitting ?
                                        (
                                            <Box>
                                                <Button variant="contained" endIcon={<CheckCircleRoundedIcon/>} disabled={submitting} >Submit</Button>
                                                <CircularProgress 
                                                    size={30}
                                                    sx={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    marginLeft: '-45px',
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <Button onClick={formSubmitSave} variant="contained" endIcon={<CheckCircleRoundedIcon/>} disabled={!validForSubmit} >Submit</Button>
                                        )

                                        }
                                        
                                    </ButtonGroup>
                                
                            : 
                            <ButtonGroup variant='contained' arial-label='Actions button group'>
                                <Button onClick={debu} variant="contained" endIcon={<HelpRoundedIcon/>}>Details</Button>
                                <Button onClick={formSubmitSave} variant="contained" endIcon={<CheckCircleRoundedIcon/>} disabled={submitting} >Submit</Button>
                            </ButtonGroup>
                        }
                        
                    </Box>
                </div>
                <Grow in={validationPopup && !validForSubmit} style={{transformOrigin:'0 0 0', transition: "all 0.5s ease"}} {...(validationPopup ? {timeout: 1000} : {})}>
                    <Box sx={{
                        display:'flex', 
                        height: '30px',
                        alignContent:'center', 
                        justifyContent:'end',
                        transition: "all 0.5s ease",
                        WebkitTransition: "all 0.5s ease",
                        MozTransition: "all 0.5s ease" ,
                        
                        }}>
                            <Box sx={{display:'flex',backgroundColor:'#ff4f58', marginRight:2, padding:1, justifyContent:'center', alignItems:'center', borderRadius:3}}>
                                <Typography sx={{ fontSize:'13px', color:'white', letterSpacing:'2px' , transition: "all 0.5s ease", marginRight:1}}>
                                    Fill all rows marked with '!'
                                </Typography>
                                <IconButton onClick={() => {setValidationPopup(false)}}>
                                    <HighlightOffIcon sx={{fontSize:20, color:'white', }}/>
                                </IconButton>
                                
                            </Box>
                        
                    </Box>
                </Grow>
                
                <br />
                {editMode
                ? 
                <div>
                    <div className="container-fluid">
                        <Grid container spacing={1} columns={16} rowGap={0.75} direction={'column'} style={{height:"100%"}}>
                        <Grid container xs={16} columnSpacing={1} padding={'8px 0px 4px 0px'} layout={'row'}>
                            <Grid xs={1}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                        #
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid xs={6}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Property Name
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Name
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={5}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Property Type
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                            Type
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={2}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                            Require field
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Tooltip title="Require field">
                                            <PriorityHighIcon />
                                        </Tooltip>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={2}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{ justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold',fontFamily:'Open Sans'}}>
                                            Row Operations
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Tooltip title="Row Operations">
                                            <SettingsIcon />
                                        </Tooltip>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                            
                            <Reorder.Group
                                axis='y'
                                values={editRowdata}
                                onReorder={setEditRowData}
                            >
                            {editRowdata.map((row, index)=> {
                                return(
                                    <DataRow 
                                    key={row.id} 
                                    id={row.id}
                                    maxrows={editRowdata.length}
                                    rownum={index}
                                    rowdeleteCB={rowDeleteCallfunction}
                                    callback={editRowCallback}
                                    moveRowDown={moveRowDown}
                                    moveRowUp={moveRowUp}
                                    injectData={row} 
                                    _item={row}
                                    ></DataRow>
                                )
                            })}
                            </Reorder.Group>
                            
                        </Grid>
                    </div>
                    <div className='d-flex align-items-center justify-content-center ' >
                        <AddCircleIcon onClick={handleRowAdd} fontSize='large' sx={{fontSize: 70, }} color="primary" ></AddCircleIcon>
                    </div>
                </div>

                : 
                    <div className="container-fluid">
                        <Grid container spacing={1} rowGap={1.5} columns={16} direction={'column'} style={{height:"100%"}}>
                        <Grid container xs={16} columnSpacing={1} padding={'8px 0px 4px 0px'} layout={'row'}>
                            <Grid xs={1}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                        #
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid xs={7}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Property Name
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Name
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={8}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Property Type
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                            Type
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>

        
                        </Grid>
                            {rowdata !== undefined && rowdata !== null ? (
                                readOnly ? (
                                    querydata !== undefined && querydata !== null ? (
                                        rowdata.map((row, index) => {
                                            const temp = returnQueryRow(row);
                                            console.log(temp);
                                            return(
                                                <FillDataRow
                                                key={index}
                                                instruction={row}
                                                querycallback={rowCallback}
                                                isReadOnly={true}
                                                injectQueryValue={temp}
                                                />
                                            )
                                        })
                                    ): (null)
                                    
                                ) : (
                                    rowdata.map((row, index) => {
                                        return(
                                            <FillDataRow
                                            key={index}
                                            instruction={row}
                                            querycallback={rowCallback}
                                            />
                                        )
                                    })
                                )
                                
                            ) : (
                                null
                            )}
                                
                            
                            
                        </Grid>
                    </div>
                }
                
                
            </Box>
        )
}
