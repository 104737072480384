import { Box, Grid, Paper, styled, Button, Typography, Fade, IconButton,List, ListItem} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import { useEffect, useState , useRef, useContext,useCallback} from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DatasetIcon from '@mui/icons-material/Dataset';

export default function WorkStepsSidebar({stageSwitch, currentStage}) {

    

    return(
        <Box sx={{display:'flex',width:'100%', height:'100%', justifyContent:'center'}}>
            <List>
                <ListItem 
                sx={{
                    backgroundColor:currentStage == 0 ? '#e3e3e3' : null, 
                    borderRadius:3,
                    transition: "all 0.5s ease",
                }}>
                    <Box sx={{backgroundColor:'lightblue', borderRadius:2}}>
                        <IconButton sx={{borderRadius:2}} onClick={() => stageSwitch(0)}>
                            <DashboardIcon sx={{fontSize:'45px', color:'white'}}/>
                        </IconButton>
                    </Box>
                </ListItem>
                <ListItem
                sx={{
                    backgroundColor:currentStage == 1 ? '#e3e3e3' : null, 
                    borderRadius:3,
                    transition: "all 0.5s ease",
                }}>
                    <Box sx={{backgroundColor:'lightgreen', borderRadius:2}}>
                        <IconButton sx={{borderRadius:2}} onClick={() => stageSwitch(1)}>
                            <DatasetIcon sx={{fontSize:'45px', color:'white'}}/>
                        </IconButton>
                    </Box>
                </ListItem>
            </List>
        </Box>
    )
}