import React, { Component, Fragment, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent,DialogTitle, Button,
 Typography, Grid, List, ListItem, Box, TextField, Divider, Paper,
  Select, Switch, FormControlLabel, FormControl, InputLabel, OutlinedInput , Chip, MenuItem, Collapse, FormHelperText } from '@mui/material';

export default function CreateFormSettings({init, open, handleClose, saveSettings, formName}){
    //Settings popup form for CreateForm and EditForm views
    //onSave component callbacks object with new settings (Consider initializing similiar setting object in both views before hand)
    /*
        "Settings": {
            "FormDetails":{
                "FormName":"String",
                "FormIsPrivate":"Bool"
                "FormPermissions":"Array",
                
            },
            "Display":{
                "RowGap":"float",

            }
        }
    */

    const [privateForm, setPrivateForm] = useState(false);
    const [userGroupsFill, setUserGroupsFill] = useState([]);
    const [userGroupsEdit, setUserGroupsEdit] = useState([]);
    const [newFormName, setNewFormName] = useState("");
    const [rowGap, setRowGap] = useState(0.5)


    useEffect(() => {
      setNewFormName(formName);
    }, [])
    

    const handleChangeUsersEdit = (e) => {
        const {
            target: {value},
        } = e;
        setUserGroupsEdit(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeRowGap = (e) => {
        setRowGap(e.target.value);
    }

    const handleChangeUsersFill = (e) => {
        const {
            target: {value},
        } = e;
        setUserGroupsFill(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onChangeFormName = (e) => {
        setNewFormName(e.target.value);
         
    }

    const onChangePrivateForm = (e) => {
        setPrivateForm(e.target.checked);
    }

    const submitSettings = () => {
        if((newFormName ==="" || newFormName ===null ) || (privateForm && (userGroupsFill.length == 0))){
            console.log("Validation failed")
        } else {
            const settings = {
                "formName": newFormName,
                "privateForm": privateForm,
                "fillGroups": userGroupsFill,
                "editGroups": userGroupsEdit,
                "rowGap":rowGap
            }
            saveSettings(settings);
            handleClose();
        }
        
    }

    return(
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={handleClose}
            scroll='paper'
            arial-aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
        >
            <DialogTitle id='scroll-dialog-title'>Form Settings</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={2}>
                    <Grid item xs={3} >
                        <Paper sx={{position:'sticky', top: 0}} elevation={3}>
                            <List>
                                <ListItem>
                                    <Typography>Form details</Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography>Display</Typography>
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={9}>
                        <Box>
                            <Paper sx={{padding: 3}}>
                                <List sx={{}}>
                                    <Box>
                                        <Typography sx={{fontWeight:'bold'}} variant='h5'>Form details</Typography>  
                                        <Divider light={false}></Divider>
                                        <br />
                                    </Box>
                                    <ListItem>
                                        <Box>
                                            <Typography variant='h6'>Form name</Typography>  
                                            <TextField 
                                                onChange={onChangeFormName}
                                                required
                                                sx={{paddingLeft:2}}
                                                value={newFormName}
                                            />
                                        </Box>
                                    </ListItem>
                                    <br />
                                    <ListItem>
                                        <Box>
                                            <Typography variant='h6'>Permissions</Typography>  
                                            <Box sx={{paddingLeft:2}}>
                                                <FormControlLabel control={<Switch onChange={onChangePrivateForm}/>} label='Private form'/>
                                                <Typography variant='body1'> Making form private will limit the users who can see and fill the form </Typography>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                    <br />
                                    <ListItem>
                                        
                                        <br />
                                            <Collapse in={privateForm}>
                                                <Box>
                                                    <Typography variant='h6'>User Groups</Typography> 
                                                    <Box sx={{paddingLeft:2}}>
                                                        
                                                        <FormControl sx={{m: 1, width: 300}} disabled={!privateForm} >
                                                            <InputLabel id="fill-group-label">Fill Groups</InputLabel>
                                                            <Select
                                                                labelId='fill-group-label'
                                                                id='fill-group'
                                                                multiple
                                                                value={userGroupsFill}
                                                                onChange={handleChangeUsersFill}
                                                                input={<OutlinedInput id='select-fill-group' label='Fill Groups'/>}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap:0.5}}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value}/>
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            >
                                                                <MenuItem value={'Default'}>Default</MenuItem>
                                                                <MenuItem value={'Groups1'}>Groups1</MenuItem>
                                                                <MenuItem value={'Groups2'}>Groups2</MenuItem>
                                                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select user groups that can see and fill the form</FormHelperText>
                                                        </FormControl>
                                                        <FormControl sx={{m: 1, width: 300}} disabled={!privateForm} >
                                                            <InputLabel id="edit-group-label">Edit Groups</InputLabel>
                                                            <Select
                                                                labelId='edit-group-label'
                                                                id='edit-group'
                                                                multiple
                                                                value={userGroupsEdit}
                                                                onChange={handleChangeUsersEdit}
                                                                input={<OutlinedInput id='select-edit-group' label='Edit Groups'/>}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap:0.5}}>
                                                                        {selected.map((value) => (
                                                                            <Chip key={value} label={value}/>
                                                                        ))}
                                                                    </Box>
                                                                )}
                                                            >
                                                                <MenuItem value={'None'}>None</MenuItem>
                                                                <MenuItem value={'Default'}>Default</MenuItem>
                                                                <MenuItem value={'Groups1'}>Groups1</MenuItem>
                                                                <MenuItem value={'Groups2'}>Groups2</MenuItem>
                                                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Select user groups that can edit the form aswell</FormHelperText>
                                                        </FormControl>
                                                        {userGroupsFill.length > 0 ||  userGroupsEdit.length > 0 ? (
                                                            <Box>
                                                                <Button onClick={() => {
                                                                    //Set arrays to retrieved settings
                                                                    setUserGroupsFill([])
                                                                    setUserGroupsEdit([])
                                                                }} color='error'>Discard Changes</Button>
                                                            </Box>
                                                            
                                                        ): (
                                                            null
                                                        )
                                                        
                                                        }
                                                        
                                                        <Typography variant='body1'> Selected user permissions groups can fill and edit the form. (Edit groups inherit fill permission by default) </Typography>
                                                    </Box> 
                                                </Box>
                                            </Collapse>
                                    </ListItem>
                                    <Divider light={false}></Divider>
                                    <br />
                                <Box>
                                    <Typography sx={{fontWeight:'bold'}} variant='h5'>Display</Typography>  
                                    <Divider light={false}></Divider>
                                    <br />
                                </Box>
                                <ListItem>
                                    <Box>
                                        <Typography variant='h6'>Row gap</Typography>  
                                    
                                     
                                        <Box sx={{paddingLeft:2, height: 300}}>
                                            <FormControl sx={{m:1, width:150}}>
                                                <InputLabel id='row-gap-label'>Row Gap</InputLabel>
                                                <Select
                                                    labelId='row-group-label'
                                                    id='edit-group'
                                                    label="Row Gap"
                                                    value={rowGap}
                                                    onChange={handleChangeRowGap}
                                                >
                                                    <MenuItem value={0.25}>0.25</MenuItem>
                                                    <MenuItem value={0.50}>0.50</MenuItem>
                                                    <MenuItem value={0.75}>0.75</MenuItem>
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={1.25}>1.25</MenuItem>
                                                    <MenuItem value={1.50}>1.50</MenuItem>
                                                    <MenuItem value={1.75}>1.75</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                </Select>
                                                <FormHelperText>Adjusts the distance between rows. Default is 0.5</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </ListItem>
                                </List>
                                
                            </Paper>
                            
                        </Box>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                    <Button>Debug</Button>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={submitSettings}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}