import { Box, Grid, Paper, styled, Button, Typography, Fade, IconButton} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import noimg from "../imgs/dummy_650x100_ffffff_cccccc.png"
import prujubäkkis from "../imgs/prujubäkkis1.PNG"
import LoginIcon from '@mui/icons-material/Login';
import { useEffect, useState , useRef, useContext,useCallback} from "react";
import { useMediaQuery } from "../api/utils";

import 'reactflow/dist/style.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconNodePlus from "../reactsvgicons/node-plus";

import ReactFlow, {Controls, Background, Panel, applyNodeChanges,
    applyEdgeChanges, useEdgesState, addEdge} from 'reactflow'

const initnodes = [
    {
        id: '0',
        position: {x:0, y:0},
        data: {label:'Node 1'}
    },
]



export default function WorkflowGuideDemo() {

    const [addIsSelected, setAddIsSelected] = useState(false);
    const [currentId, setCurrentId] = useState(0);
    const [nodes, setNodes] = useState(initnodes);
    const [edges,setEdges, onEdgesChange] = useEdgesState([])

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes],
    );
    
    const onConnect = useCallback(
        (connection) => {
          setEdges((oldEdges) => addEdge(connection, oldEdges));
        },
        [setEdges],
      );
    
    

    const handleAddSelect = () => {
        if(addIsSelected) { setAddIsSelected(false);} else {setAddIsSelected(true);}
    }

    const handleAddNode = () => {
        let id = currentId + 1;
        setNodes(
            [...nodes,
            {
                id: `${id}`,
                data: {label: `Node ${id+1}`},
                position: {x:0, y:0},
            }]
        )
        console.log(nodes);
        setCurrentId(id);
        setAddIsSelected(false);
    }
    

    return(
        <Box sx={{display:'flex', width:'99vw', height:'90vh',}}>
            
            <ReactFlow 
                nodes={nodes}
                onConnect={onConnect}
                edges={edges}
                onNodesChange={onNodesChange}
            >
                <Panel position="topleft">
                    <IconButton onClick={handleAddSelect}>
                        <AddCircleIcon sx={{fontSize:'70px', color:addIsSelected ? '#1976d2' : 'lightblue',  transform: addIsSelected ? ('rotate(45deg)') : ('rotate(0deg)')}} style={{transition:'all 0.5s ease'}}/>
                    </IconButton>
                    <Fade in={addIsSelected} style={{marginLeft:15}}>
                        <IconButton onClick={handleAddNode}>
                            <IconNodePlus style={{fontSize:'45px'}}/>
                        </IconButton>
                    </Fade>
                </Panel>
                <Background />
                <Controls />
            </ReactFlow>
        </Box>
        
        
    )
}