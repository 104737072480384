import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import RequireAuth from './components/RequireAuth';
import PersistentLogin from './components/PersistentLogin';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Navbar from './components/navbar.component';
import FormList from "./components/form-list.component";
import CreateForm from "./components/create-form.component";
import FormFill from "./components/form-fill.component";
import QueryList from "./components/query-list.component";
import LoginPage from "./components/LoginPage.component";
import Unauthorized from './components/unauthorized.component';
import LandingPage from './components/LandingPage.component';
import AccountManagement from './components/account-management.component';
import TicketsView from './components/tickets-view.component';
import CreateTicket from './components/create-ticket.component';
import SelectedTicket from './components/selected-ticket.component';
import OpenForm from './components/open-form.component';
import WorkflowGuideIntro from './components/workflowguideintro.component';
import WorkflowGuideDemo from './components/workflowguidedemo.component';
import AccountPermissions from './components/account-permissions.component';
import WorkSteps from './components/worksteps.component';
import Layout from './components/Layout';



function App() {
  return (
      <Routes>
        <Route path='/' element={<Layout/>}>
          {/* Acessible without auth */}
          <Route path='/' exact element={<LandingPage/>}/>
          <Route path="/login" exact element={<LoginPage />}/>
          <Route path="/unauthorized" exact element={<Unauthorized />}/>
          <Route path='/account' exact element={<AccountManagement/>}/>

          {/* Authentication required */}
          <Route element={<PersistentLogin/>}>
            <Route element={<RequireAuth allowedPerms={['ViewForms']}/>}>
              <Route path="/forms" exact element={<FormList/>}/>
            </Route>
              {/*Permissions: View Forms, Create Forms */}
            <Route element={<RequireAuth allowedPerms={['ViewForms','Create']}/>}>
              <Route path="/create-form" exact element={<CreateForm/>}/>
            </Route>
              {/*Permissions: View Forms, Fill Forms */}
            <Route element={<RequireAuth allowedPerms={['ViewForms', 'Fill']}/>}>
              <Route path='/fill/:id' exact element={<FormFill/>}/>
            </Route>
            {/*Permissions: View Forms, Fill Forms */}
            <Route element={<RequireAuth allowedPerms={['ViewForms', 'Fill']}/>}>
              <Route path='/form/:id' exact element={<OpenForm/>}/>
            </Route>
              {/*Permissions: WorkSteps */}
            <Route element={<RequireAuth allowedPerms={['ViewQueries']}/>}>
              <Route path='/steps/dashboard' exact element={<WorkSteps/>}/>
            </Route>

            <Route element={<RequireAuth allowedPerms={["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"]}/>}>
              <Route path='/tickets' exact element={<TicketsView/>}/>
            </Route>
            <Route element={<RequireAuth allowedPerms={["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"]}/>}>
              <Route path='/tickets/create' exact element={<CreateTicket/>}/>
            </Route>
            <Route element={<RequireAuth allowedPerms={["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"]}/>}>
              <Route path='/tickets/:id' exact element={<SelectedTicket/>}/>
            </Route>
            <Route element={<RequireAuth allowedPerms={["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"]}/>}>
              <Route path='/workflowguide/intro' exact element={<WorkflowGuideIntro/>}/>
            </Route>
            <Route element={<RequireAuth allowedPerms={["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"]}/>}>
              <Route path='/workflowguide/demo' exact element={<WorkflowGuideDemo/>}/>
            </Route>
            <Route element={<RequireAuth allowedPerms={["ViewForms","ViewQueries","Create","Edit","Delete","Fill","Queries","Authorization"]}/>}>
            <Route path='/account/permissions' exact element={<AccountPermissions/>}/>
            </Route>
          </Route>
          
        {/* Missing p */}
        </Route>
        
      </Routes>
  );
}
//<Route path="/" exact element={<FormEdit />}/>
//<Route path="/" exact element={<FormFill />}/>

export default App;

//Tilojen pohjan mitoitus (avaruus, kuvio, )
//mahdollisesti tempalteja create formiin 
//Kaavakkeiden kansiot, spesifioitu käyttöoikeus