//source https://reactsvgicons.com/
// icon:filetype-json | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from "react";

function IconFiletypeJson(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM4.151 15.29a1.176 1.176 0 01-.111-.449h.764a.578.578 0 00.255.384c.07.049.154.087.25.114.095.028.201.041.319.041.164 0 .301-.023.413-.07a.559.559 0 00.255-.193.507.507 0 00.084-.29.387.387 0 00-.152-.326c-.101-.08-.256-.144-.463-.193l-.618-.143a1.72 1.72 0 01-.539-.214 1.001 1.001 0 01-.352-.367 1.068 1.068 0 01-.123-.524c0-.244.064-.457.19-.639.128-.181.304-.322.528-.422.225-.1.484-.149.777-.149.304 0 .564.05.779.152.217.102.384.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 00-.12-.258.624.624 0 00-.246-.181.923.923 0 00-.37-.068c-.216 0-.387.05-.512.152a.472.472 0 00-.185.384c0 .121.048.22.144.3a.97.97 0 00.404.175l.621.143c.217.05.406.12.566.211a1 1 0 01.375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 01-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 01-.478-.252 1.13 1.13 0 01-.29-.375zm-3.104-.033a1.32 1.32 0 01-.082-.466h.764a.576.576 0 00.074.27.499.499 0 00.454.246c.19 0 .33-.055.422-.164.091-.11.137-.265.137-.466v-2.745h.791v2.725c0 .44-.119.774-.357 1.005-.237.23-.565.345-.985.345a1.59 1.59 0 01-.568-.094 1.145 1.145 0 01-.407-.266 1.14 1.14 0 01-.243-.39zm9.091-1.585v.522c0 .256-.039.47-.117.641a.862.862 0 01-.322.387.877.877 0 01-.47.126.883.883 0 01-.47-.126.87.87 0 01-.32-.387 1.55 1.55 0 01-.117-.641v-.522c0-.258.039-.471.117-.641a.87.87 0 01.32-.387.868.868 0 01.47-.129c.177 0 .333.043.47.129a.862.862 0 01.322.387c.078.17.117.383.117.641zm.803.519v-.513c0-.377-.069-.701-.205-.973a1.46 1.46 0 00-.59-.63c-.253-.146-.559-.22-.916-.22-.356 0-.662.074-.92.22a1.441 1.441 0 00-.589.628c-.137.271-.205.596-.205.975v.513c0 .375.068.699.205.973.137.271.333.48.589.626.258.145.564.217.92.217.357 0 .663-.072.917-.217.256-.146.452-.355.589-.626.136-.274.205-.598.205-.973zm1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676h-.032z"
      />
    </svg>
  );
}

export default IconFiletypeJson;
