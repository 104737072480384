import { styled } from '@mui/material/styles';
import axios from '../api/axios'
import { Typography, Box, Menu, MenuItem, Grid, Paper, IconButton, Button, Tooltip, Zoom } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ReplyIcon from '@mui/icons-material/Reply';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs'

 
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export default function FormCardElement({form, userCanDelete, startDeletePopup, queryTable, delay}){
    

    const [anchorElement, setAnchorElement] = useState(null);
    const [hoverActive, setHoverActive] = useState(false);
    const [newDate,setNewDate] = useState();
    const [textTargetWidth, setTextTargetWidth] = useState(0);
    const [isSelected, setIsSelected] = useState(false);
    


    const settingsIsOpen = Boolean(anchorElement);
    const navigate = useNavigate();
    const refItem = useRef();
     
    
    const getHoverColor = () => {
        if(hoverActive){
            return "linear-gradient(to bottom,rgba(255,255,255,255), rgba(33, 150, 243, 0.5))"
        }else {
            return "#fff"
        }
    }

     

    const determineTargetWidth = (ref) => {
        
        if(ref.current){
            setTextTargetWidth(ref.current.offsetWidth/2);
        }
    }

    useEffect(() => {
         
        determineTargetWidth(refItem);
    }, [])
    

    const formatDate = (date) => {
        console.log(date);
        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getYear();
    
        var formattedDay = day < 10 ? "0" + day : day;
        var formattedMonth = month < 10 ? "0" + month : month;
    
        return formattedDay+"/"+formattedMonth+"/"+year;
    }
    

     // opens settings for form element 
    const openSettings = (event) => {
        setAnchorElement(event.currentTarget);
    }
    const closeSettings = () => {
        setAnchorElement(null);
    }

    return(
        <Zoom in={true} style={{transitionDelay: delay}}>
            <Item 
                ref={refItem}
                onMouseEnter={() => setHoverActive(true)} 
                onMouseLeave={() => setHoverActive(false)}
                sx={{
                    backgroundColor:'rgba(255,255,255,0.6)',
                    filter: hoverActive ? 'brightness(97%)':null,
                    //background: hoverActive ? "rgba(33, 150, 243, 0.05)" : "white",
                    transition: "all 0.5s ease",
                    WebkitTransition: "all 0.5s ease",
                    MozTransition: "all 0.5s ease"
                }}
            >
                <Box sx={{display:'flex', justifyContent: 'space-between', marginBottom:1}}>
                    <Box sx={{ width: textTargetWidth}}>
                        <Typography   noWrap={true} variant='h5' sx={{color:'black'}}>
                            {form.name}
                        </Typography>
                    </Box>
                    
                    <div>
                        <IconButton 
                            id="basic-button"
                            size='small' 
                            sx={{color:'gray' }}
                            aria-controls={settingsIsOpen ? 'basic-menu' : undefined} 
                            aria-haspopup="true"
                            aria-expanded={settingsIsOpen ? 'true' : undefined}
                            onClick={openSettings}
                        >
                            <MoreHorizIcon
                                
                            />
                        </IconButton>  
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElement}
                            open={settingsIsOpen}
                            onClose={closeSettings}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            
                        >
                            {/* Show Settings conditionally */}
                            <MenuItem disabled={!userCanDelete} onClick={event => {
                                closeSettings();
                                startDeletePopup(form.id);

                                }}>Delete</MenuItem>
                        </Menu>
                    </div>
                </Box >
                <Box sx={{display:'flex', justifyContent:'space-between', marginBottom:0.5}}>
                    <Box sx={{display:'flex'}}>
                        <Tooltip title='Form creation date'>
                            <CalendarMonthIcon />
                        </Tooltip>
                        <Typography sx={{marginLeft:1}}>
                            {dayjs(form.timestamp).format('DD/MM/YYYY')}
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'start'}}>
                        <Tooltip title='Form length'>
                            <TableRowsIcon />
                        </Tooltip>
                         
                        <Typography sx={{marginLeft:1}}>
                            {form.build_instruction.length}
                        </Typography>
                    </Box>
                </Box>
                
                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex'}}>
                        <Tooltip title='Form created by'>
                            <PersonIcon />
                        </Tooltip>
                        
                        <Typography sx={{marginLeft:1}}>
                            {form.creator}
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'start' }}>
                        
                    </Box>
                </Box>
                <br />
                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', justifyContent:'start' }}>
                        
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'end' }}>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <Tooltip title={'Form has '+form.fill_counter+' entries'}>
                                <ReplyIcon />
                            </Tooltip>
                            
                            <Typography sx={{marginLeft:1}}>
                                {form.fill_counter}
                            </Typography>
                        </Box>
                        
                        <Button sx={{marginLeft:2}} onClick={() => navigate("/form/"+form.id, {state: {queryTable: queryTable}})} size='small' variant="contained" endIcon={<TextSnippetIcon/>}>Open Form</Button>
                    </Box>
                </Box>
                <div className='d-flex flex-direction-row justify-content-end'>
                    
                    
                </div>
                
                
            </Item>
        </Zoom>
    )
}