import React, { Component, useEffect, useState } from 'react';
import { useScrollTrigger, Typography, Box, Stack, Tooltip, Menu, MenuItem, InputLabel, Input, Button, TextField, Divider, Select, OutlinedInput, FormControl, FormHelperText, Chip, List, ListItem, ListItemButton, ListItemText, ListItemAvatar } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, useParams } from 'react-router-dom';


export default function SelectedTicket(){
    const {auth} = useAuth()
    const [imageOpen, setImageOpen] = useState(false)
    const [textComment, setTextComment] = useState('')
    const [TicketData, setTicketData] = useState()
    const [comments, setComments] = useState([])
    const [LoadingTicketData, setLoadingTicketData] = useState(true)
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        setLoadingTicketData(true);
        axios.get('/tickets/'+id, {params:{"server":auth.server}})
        .then(res => {
            setTicketData(res.data);
            setComments(res.data.comments)
        })
        .catch((err) => {console.log(err)})
        .finally(() => {setLoadingTicketData(false);
            console.log(TicketData)})
    }, [])


    const comment_onchange = (e) => {
        setTextComment(e.target.value);
    }

    const toggleImage = () => {
        setImageOpen(!imageOpen);
    }

    

    const postComment = () => {
        let _date = new Date(Date.now());
        
        const comment = {
            title: TicketData.title,
            text: textComment,
            user: auth.user,
            date: _date,
            server:auth.server
        }
        axios.post('/tickets/add-comment', comment)
        .then(res => {
            console.log(res);
            setComments([
                ...comments,
                {
                    text: textComment,
                    user: auth.user,
                    date: _date
                }
            ])
        })
        .catch(err => {
            console.log(err)
        })
        setTextComment('');
    }

    const returnBack = () => {
        navigate('/tickets')
    }


    return(
        !LoadingTicketData ?
        (
            <Box
            component={'form'}
            autoComplete='off'
            sx={{
                mx: 'auto', 
                width:1500,
                
                border: '1px solid',
                borderColor: '#f5f5f5',
            }}
            >
                
                
                <Box  >
                    <Button onClick={returnBack} variant='outlined' startIcon={<ArrowBackIosNewIcon/>}>Back</Button>
                    <Typography sx={{display:'flex' ,justifyContent:'center', justifyItems:'center'}} variant='h4'>Ticket view</Typography>
                </Box>
                <br />
                <Divider variant='middle' />
                <br />
                <Box sx={{display: 'flex'}}>
                    <TextField
                        sx={{width:400}}
                        required
                        label="Title"
                        InputProps={{readOnly: true}}
                        value={TicketData.title}
                        variant='filled'
                    />
                    <Divider orientation='vertical'/>
                    <TextField
                        sx={{width:400}}
                        required
                        label="Product"
                        InputProps={{readOnly: true}}
                        value={TicketData.product}
                        variant='filled'
                    />
                </Box>
                <br />
                <Box sx={{display: 'flex'}}>
                    <TextField
                        sx={{width:400}}
                        required
                        label="Subject"
                        InputProps={{readOnly: true}}
                        value={TicketData.subject}
                        variant='filled'
                    />
                    <Divider orientation='vertical'/>
                    <TextField
                        sx={{width:400}}
                        required
                        label="Severity"
                        InputProps={{readOnly: true}}
                        value={TicketData.severity}
                        variant='filled'
                    />
                </Box>
                <Box>
                    <Box sx={{display:'flex',border: '1px solid',
                    borderColor: '#f5f5f5', width: '800px'}}>

                        <Tooltip title='Posted by User'>
                            <PersonIcon sx={{color:'gray'}}/>
                        
                        <Box sx={{display:'inline-block', width:'200px'}}>
                            <Typography variant='subtitle1' color={'gray'}>
                                {TicketData.user}
                            </Typography>
                        
                        </Box>
                        </Tooltip>
                    </Box>
                    <Box sx={{display:'flex',border: '1px solid',
                    borderColor: '#f5f5f5', width: '800px'}}>

                        <Box sx={{display:'inline-block', width:'200px'}}>
                            <Tooltip title="Post date">
                                <Typography variant='subtitle1' color={'gray'}>
                                    {TicketData.createdAt}
                                </Typography>
                            </Tooltip>
                            
                        </Box>
                        
                    </Box>
                </Box>
                
                <br />
                
                {TicketData.image =="" || TicketData.image ==null?"": 
                imageOpen ? (
                    <Box sx={{border: '1px solid', borderColor: 'gray'}} >
                        <Typography variant='subtitle1'>Image file attachments (1)</Typography>
                        <img style={{maxWidth: '1495px'}} onClick={toggleImage} src={TicketData.image} />
                        <br />
                        <Typography variant='overline'>Click the image to shrink</Typography>
                    </Box>
                ) : (
                    (    
                        <Box sx={{border: '1px solid', borderColor: 'gray'}}>
                            <Typography variant='subtitle1'>Image file attachments (1)</Typography>
                            <img style={{}} onClick={toggleImage} src={TicketData.image} width={100} height={100} />
                            <br />
                            <Typography variant='overline'>Click the image to expand</Typography>
                        </Box>
                    )
                )}
                
                <br />
                
                <Divider variant='middle' textAlign='left'></Divider>
                <br />
                <Box
                    width='100%'
                >
                    <TextField
                        multiline
                        label='Text Content'
                        minRows={5}
                        sx={{width: '100%'}}
                        InputProps={{readOnly: true}}
                        value={TicketData.textcontent}
                        variant='filled'
                    />
                    
                </Box>
                <br />
                <br />
                <Divider variant='middle' textAlign='Center'>
                    <Typography variant='h6'>
                        Post a Comment
                    </Typography>
                </Divider>
                <br />
                
                <Box width='100%'>
                <TextField
                        multiline
                        label='Comment'
                        minRows={5}
                        sx={{width: '100%'}}
                        value={textComment}
                        onChange={comment_onchange}
                        
                />
                <Button variant='contained' onClick={postComment}>Post Comment</Button>
                </Box>
                <br />
                <br />
                <Divider textAlign='left'>
                    <Typography variant='body1'>
                        Comments: {comments.length}
                    </Typography>
                </Divider>
                <Box width='100%'> 
                    <List>
                        {comments.map((comment, index) =>{
                            return (
                                <ListItem key={comment._id}>
                                    <Box width='100%'>
                                        <Box sx={{display: 'flex'}}>
                                            <PersonIcon />
                                            <Typography variant='subtitle1'>
                                                {comment.user}
                                            </Typography>
                                        </Box>
                                        <br />
                                        <Box>
                                            <TextField

                                                multiline
                                                label='Comment'
                                                minRows={3}
                                                sx={{width:'100%'}}
                                                inputProps={{readOnly: true}}
                                                value={comment.text}
                                                >

                                            </TextField>
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle1' color='gray'>
                                                {comment.date.toString()}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    
                                    
                                </ListItem>
                            )
                        })
                        }     
                    </List>
                    
                </Box>
            </Box>
        ) :
        (
            <Box
                component={'form'}
                autoComplete='off'
                sx={{
                    mx: 'auto', 
                    width:1500,
                    
                    border: '1px solid',
                    borderColor: '#f5f5f5',
                }}
            >
            Loading...
            </Box>
        )
        
    )
}