
import React, { Component, Fragment, useEffect, useState } from 'react';
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom';
import { Box, ButtonGroup, Button,Typography,CircularProgress, Divider, Grid, ToggleButtonGroup, ToggleButton,Stack, Fade } from '@mui/material';
import axios from '../api/axios'
import FormFill from './form-fill.component';
import QueryList from './query-list.component';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useAuth from '../hooks/useAuth';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
 

/* Wrapper for:
    form fill
    Responses
*/


export default function OpenForm(){
    const stages = Object.freeze({
        Questions: 0,
        Answers: 1,
        Settings: 2
    })

    const [rememberViewMode, setRememberViewMode] = useState(null)
    const [formData, setFormData] = useState({})
    const [fetchedForm, setFetchedForm] = useState(false);
    const [fetchedQuery, setFetchedQuery] = useState(false);
    const [queryTable, setQueryTable] = useState([]);
    const [stage, setStage] = useState(stages.Questions);
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {auth} = useAuth();


    useEffect(() => {
        setStage(stages.Questions);
        if(location.state !== null && location.state.queryTable !== undefined){
            setQueryTable(location.state.queryTable);
            console.log(queryTable)
        }
        if(location.state.lastview !== undefined){
            setRememberViewMode(location.state.lastview)
        }
    }, [])

    const hangleChangeStage = (event, newStage) => {
        if(newStage !== null){
            setStage(newStage);
        }
    }
     
    useEffect(() => {
        //For the most up to date form data the data needs to be fetched ATLEAST every stage switch
        if(stage === stages.Questions){
             
            axios.get('/forms/'+id,{params: {"server":auth.server}})
            .then(response => {
                setFormData(response.data[0]);  
                console.log(response.data)
                setFetchedForm(true);
            })
            .catch(function (error) {
                console.log(error);
            })
            
        } else if(stage === stages.Answers){
            console.log(id );
            axios.get('/queries/getbypairid/'+id.toString(),{params:{"server":auth.server}})
            .then(res => {
                setQueryTable(res.data);
                setFetchedQuery(true);
                console.log("queries fetched");
                console.log(res.data);
                //console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
        }
        
    }, [stage])
    

    return(
        <Box >
            {/* Primary form info */}
            <Box sx={{backgroundColor:'white'}}>
                <Box sx={{display:{xs:'none', md:'flex'},justifyContent:'space-between'}}>
                    <Box sx={{width:100}}><Button onClick={() => {
                        navigate("/forms", {
                            state: {
                                success_create: false,
                                lastview: rememberViewMode
                            }
                        });
                    }} sx={{marginLeft:1}} variant="text" startIcon={<ArrowBackIosIcon/>}>Back</Button></Box>
                        <ToggleButtonGroup
                            sx={{border:0}}
                            
                            color='primary'
                            value={stage}
                            variant='contained'
                            exclusive
                            onChange={hangleChangeStage}
                            >
                            <ToggleButton sx={{border: 0, borderRight:'1px solid #cfcfcf'}} value={stages.Questions} ><Typography sx={{fontFamily: 'Open Sans', fontWeight: 'medium'}}>Questions</Typography></ToggleButton>
                            <ToggleButton sx={{border:0, borderRight:'1px solid #cfcfcf'}} value={stages.Answers} onClick={() => {setStage(stages.Answers)}} ><Typography sx={{fontFamily: 'Open Sans', fontWeight: 'medium'}}>Answers</Typography></ToggleButton>
                            <ToggleButton sx={{border:0}} value={stages.Settings} onClick={() => {setStage(stages.Settings)}} ><Typography sx={{fontFamily: 'Open Sans', fontWeight: 'medium'}}>Settings</Typography></ToggleButton>
                        </ToggleButtonGroup>
                    <Box sx={{width:100}}></Box>
                </Box>
                <Box sx={{display:{xs:'block', md:'none'},justifyContent:'center'}}>
                    <Box sx={{width:100, justifyContent:'center'}}><Button onClick={() => {navigate("/forms")}} sx={{marginLeft:1}} variant="text" startIcon={<ArrowBackIosIcon/>}>Back</Button></Box>
                    <ToggleButtonGroup
                        sx={{border:0}}
                        
                        color='primary'
                        value={stage}
                        variant='contained'
                        exclusive
                        onChange={hangleChangeStage}
                        >
                        <ToggleButton sx={{border: 0, borderRight:'1px solid #cfcfcf'}} value={stages.Questions} ><Typography sx={{fontFamily: 'Open Sans', fontWeight: 'medium'}}>Questions</Typography></ToggleButton>
                        <ToggleButton sx={{border:0, borderRight:'1px solid #cfcfcf'}} value={stages.Answers} onClick={() => {setStage(stages.Answers)}} ><Typography sx={{fontFamily: 'Open Sans', fontWeight: 'medium'}}>Answers</Typography></ToggleButton>
                        <ToggleButton sx={{border:0}} value={stages.Settings} onClick={() => {setStage(stages.Settings)}} ><Typography sx={{fontFamily: 'Open Sans', fontWeight: 'medium'}}>Settings</Typography></ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <br />
                <Divider sx={{opacity: 1, marginLeft: 1, marginRight: 1}}/>
                <Box sx={{width:"100%", display:{xs:'none', lg:'flex'}, justifyContent:'space-evenly'}}>
                    <Stack

                        direction="row"
                        divider={<Divider sx={{opacity:1}} orientation='vertical'/>}
                        spacing={20}
                    >
                        <Box sx={{marginLeft:1}}>
                            <Typography sx={{color:'gray', fontFamily:"Open Sans"}} noWrap={true} variant='body'>
                                Form name:
                            </Typography>
                            {!fetchedForm ? (null) : (
                                <Typography variant='h4' sx={{ fontFamily:"Open Sans"}}>
                                    {formData.name}
                                </Typography>
                            )}
                            <br />
                        </Box>
                        <Box sx={{marginLeft:1, }}>
                            <Grid container columns={2}>
                                <Grid item xs={1} sx={{ justifyContent:'start'}}>
                                    <Typography variant='body' noWrap={true} sx={{color:'gray', fontFamily:'Open Sans' }}>
                                        Created at:
                                    </Typography>
                                    <Box sx={{display:'flex'}}>
                                        <CalendarMonthIcon sx={{alignSelf:'center'}}/>
                                        
                                        <Typography variant='h5' sx={{color:'black', fontFamily:'Open Sans', marginLeft:1}}>
                                            {dayjs(formData.createdAt).format('DD.MM.YYYY')}
                                        </Typography>
                                    </Box>
                                    <Typography variant='body' sx={{color:'gray', fontFamily:'Open Sans', marginLeft:4}}>
                                        {dayjs(formData.createdAt).format('HH:mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ }}>

                                </Grid>
                            </Grid>
                            
                        </Box>
                        <Box sx={{marginLeft:1}}>
                            <Typography sx={{color:'gray', fontFamily:"Open Sans"}} noWrap={true} variant='body'>
                                Created by:
                            </Typography>
                            <Box sx={{display:'flex'}}>
                                <AccountCircleIcon sx={{alignSelf:'center'}}/>
                                {!fetchedForm ? (null) : (
                                    <Typography variant='h5' sx={{ fontFamily:"Open Sans", marginLeft:1}}>
                                        {formData.creator}
                                    </Typography>
                                )}
                            </Box>
                            
                            
                        </Box>
                    </Stack>
                    
                    {/*
                    <Grid container spacing={2} columnGap={0} columns={17}>
                        <Grid item xs={5} >
                            <Box sx={{marginLeft:1}}>
                                <Typography sx={{color:'gray', fontFamily:"Open Sans"}} noWrap={true} variant='body'>
                                    Form name:
                                </Typography>
                                {!fetched ? (null) : (
                                    <Typography variant='h4' sx={{ fontFamily:"Open Sans"}}>
                                        {formData.name}
                                    </Typography>
                                )}
                                <br />
                            </Box>
                        </Grid>
                        <Grid item xs={1} sx={{display:'flex', justifyContent:'center'}} >
                            
                            <Divider sx={{opacity:1}} orientation='vertical'/>
    
                        </Grid>
                        
                        <Grid item xs={5} >
                            <Box sx={{marginLeft:1, }}>
                                <Grid container columns={2}>
                                    <Grid item xs={1} sx={{ justifyContent:'start'}}>
                                        <Typography variant='body' sx={{color:'gray', fontFamily:'Open Sans' }}>
                                            Created at:
                                        </Typography>
                                        <Box sx={{display:'flex'}}>
                                            <CalendarMonthIcon sx={{alignSelf:'center'}}/>
                                            
                                            <Typography variant='h5' sx={{color:'black', fontFamily:'Open Sans', marginLeft:1}}>
                                                {dayjs(formData.createdAt).format('DD.MM.YYYY')}
                                            </Typography>
                                        </Box>
                                        <Typography variant='body' sx={{color:'gray', fontFamily:'Open Sans', marginLeft:4}}>
                                            {dayjs(formData.createdAt).format('HH:MM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ }}>

                                    </Grid>
                                </Grid>
                                
                            </Box>
                        </Grid>
                        
                        <Grid item xs={1} sx={{display:'flex', justifyContent:'center'}}  >
                            <Divider sx={{ opacity:1}} orientation='vertical'/>
                        </Grid>
                        <Grid item xs={5} >
                            <Box sx={{marginLeft:1}} >
                                
                                
                            </Box>
                        </Grid>
                    </Grid>
                    */}
                    
                </Box>
                <Box sx={{display:{md:'flex', lg:'none' }, width:'100%', justifyContent:'center'}}>
                    <Stack
                        direction="column"
                        divider={<Divider sx={{opacity:1}} orientation='horizontal'/>}
                        spacing={1}
                    >
                        <Box sx={{marginLeft:1}}>
                            <Typography sx={{color:'gray', fontFamily:"Open Sans"}} noWrap={true} variant='body'>
                                Form name:
                            </Typography>
                            {!fetchedForm ? (null) : (
                                <Typography variant='h4' sx={{ fontFamily:"Open Sans"}}>
                                    {formData.name}
                                </Typography>
                            )}
                            
                        </Box>
                        <Box>
                            <Typography variant='body' noWrap={true} sx={{color:'gray', fontFamily:'Open Sans' }}>
                                Created at:
                            </Typography>
                            <Box sx={{display:'flex'}}>
                                <CalendarMonthIcon sx={{alignSelf:'center'}}/>
                                
                                <Typography variant='h5' sx={{color:'black', fontFamily:'Open Sans', marginLeft:1}}>
                                    {dayjs(formData.createdAt).format('DD.MM.YYYY')}
                                </Typography>
                            </Box>
                            <Typography variant='body' sx={{color:'gray', fontFamily:'Open Sans', marginLeft:4}}>
                                {dayjs(formData.createdAt).format('HH:mm')}
                            </Typography>
                        </Box>
                        <Box sx={{marginLeft:1}}>
                            <Typography sx={{color:'gray', fontFamily:"Open Sans"}} noWrap={true} variant='body'>
                                Created by:
                            </Typography>
                            <Box sx={{display:'flex'}}>
                                <AccountCircleIcon sx={{alignSelf:'center'}}/>
                                {!fetchedForm ? (null) : (
                                    <Typography variant='h5' sx={{ fontFamily:"Open Sans", marginLeft:1}}>
                                        {formData.creator}
                                    </Typography>
                                )}
                            </Box>
                            
                            <br />
                        </Box>
                    </Stack>
                </Box>
            </Box>
            
            
            <Divider sx={{opacity: 1, marginLeft: 1, marginRight: 1}}/>
            <Box>
                {stage === stages.Questions  ? 
                (
                    !fetchedForm ? ( <Box sx={{display: 'flex', justifyContent:'center'}}><CircularProgress /></Box>) : (
                        <Fade in={stage === stages.Questions}  style={{ transitionDelay: stage === stages.Questions ? '250ms' : '0ms' }}>
                            <Box>
                                <FormFill 
                                    formdata={formData}
                                    queryTable={queryTable}
                                    injectQueryData={null}
                                    _rememberViewMode={rememberViewMode}
                                />
                            </Box>
                            
                        </Fade>
                        
                    )
                ):( stage === stages.Answers   ? (
                    <Fade in={stage === stages.Answers}  style={{ transitionDelay: stage === stages.Answers ? '250ms' : '0ms' }}>
                        <Box  >
                            {!fetchedQuery ? ( <Box sx={{display: 'flex', justifyContent:'center'}}><CircularProgress /></Box> ) : (
                                <QueryList
                                    queryfetch={queryTable}
                                    BIs={formData.build_instruction}
                                />
                            )}
                        </Box>
                    </Fade>
                    
                ) : (
                    <Box sx={{justifyContent:'center'}}>
                        <Typography sx={{fontWeight:'bold', fontFamily:'Open Sans'}}>
                            SETTINGS
                        </Typography>
                    </Box>
                ))}
                
                
            </Box>
        </Box>
        
    )
}