import { Outlet } from "react-router-dom";
import Navbar from "./navbar.component";

const Layout = () => {
    

    return (
        <main className="App">
            <Navbar />
            <br />
            <Outlet/>
        </main>
    )
}

export default Layout;