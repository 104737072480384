import { UserHasPermissions } from '../api/utils';
import FormCardElement from './form-card-element';
import FormFolder from './form-folder.component';
import QueryList from './query-list.component';
import { Box, Grid, Typography } from '@mui/material';


export default function FormListGridView({formData, folders, currentFolder, currentFolderChildren, folderChildrenAmount, openFolder, filterString, suretodeletepopup, getQueryObjById, getFillCounter, auth}) {




    return (
        <Box sx={{backgroundColor:currentFolder!==null?currentFolder.color:null,transition: "all 0.5s ease",borderRadius:5,
            display:'flex', width:'95%', justifyContent:'center', alignItems:'center'
        }}
        >
                            
            {(filterString === '' && filterString.length === 0) ? (
                //filter string empty, show current folder 🤖
                <Grid container gap={2.5} columns={15}>
                    {!currentFolder&&!currentFolderChildren?(null):(
                        
                        currentFolderChildren().length > 0 ? (
                            currentFolderChildren().map((obj,i) => {
                                if (obj.type ==='folder'){
                                    return(
                                    
                                        <Grid xs={15} sm={3} md={2.20} lg={2.25} xl={1.725} key={obj.data._id}>
                                    
                                            <FormFolder openFolderCB={openFolder} folder={obj.data}/>
                                            
                                        </Grid>
                                    )
                                }
                                else if(obj.type ==='form'){
                                    return(
                                        <Grid xs={15} sm={6.5} md={4.75} lg={4.75} xl={3.625}  key={obj.data._id}>
                                
                                            <FormCardElement 
                                            key={obj.data._id}
                                            delay={80*i}
                                            queryTable={getQueryObjById(obj.data._id)}
                                            //👇 form is for showing data on form cards, consider injecting only relevant data
                                            form={{
                                                name: obj.data.name,
                                                id: obj.data._id,
                                                build_instruction: obj.data.build_instruction,
                                                timestamp: obj.data.createdAt,
                                                creator: obj.data.creator,
                                                fill_counter: getFillCounter(obj.data._id)
                                            }}
                                            userCanDelete={UserHasPermissions(auth.permissions, ["Delete"])}
                                            startDeletePopup={suretodeletepopup}
                                            //openForm={openForm(obj._id)}
                                            />
                                            
                                            
                                        </Grid>
                                    )

                                }
                                
                            })
                        ):(
                            <Grid xs={12}>
                                {console.log(currentFolderChildren())}
                                <Box sx={{display:'flex',width:'100%', height:'100%', borderRadius:2,alignItems:'center', justifyContent:'center'}}>
                                    <Typography variant='h4' fontFamily={'Open Sans'} sx={{color:'gray'}} >
                                        Folder is currently empty! Load the page again or try adding new content!
                                    </Typography>
                                </Box>
                                            
                            </Grid>
                        )
                        
                    )}
                </Grid>
            ) : (
                // 🏃 filtering folders and forms based on filterstring
                <Grid container columnGap={2.5} columns={15}>
                {folders.filter(folder => folder.name.toString().toLowerCase().includes(filterString.toString().toLowerCase())&&folder._id!=="0").map((obj, index) => {
                    //folder.id !== 0 hides root folder 👻
                    return(
                        <Grid xs={15} sm={3} md={2.20} lg={2.25} xl={1.725} key={obj._id}>
                            
                            <FormFolder openFolderCB={openFolder} folder={obj} children_n={folderChildrenAmount(obj._id)}/>
                                
                        </Grid>
                    )
                })}

                {formData.filter(form => form.name.toString().toLowerCase().includes(filterString.toString().toLowerCase())).map((obj, index) => {
                    return (
                        
                        <Grid xs={15} sm={6.5} md={4.75} lg={4.75} xl={3.625} key={obj._id}>
                            
                            <FormCardElement 
                            key={obj._id}
                            delay={80*index}
                            queryTable={getQueryObjById(obj._id)}
                            //👇 form is for showing data on form cards, consider injecting only relevant data
                            form={{
                                name: obj.name,
                                id: obj._id,
                                build_instruction: obj.build_instruction,
                                timestamp: obj.createdAt,
                                creator: obj.creator,
                                fill_counter: getFillCounter(obj._id)
                            }}
                            userCanDelete={UserHasPermissions(auth.permissions, ["Delete"])}
                            startDeletePopup={suretodeletepopup}
                            //openForm={openForm(obj._id)}
                            />
                                
                            
                        </Grid>
                        
                    )
                })}
                </Grid>
            )}
        </Box>
    )
}