import { Box, Grid, Paper, styled, Button, Typography, Fade} from "@mui/material";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import noimg from "../imgs/dummy_650x100_ffffff_cccccc.png"
import prujubäkkis from "../imgs/prujubäkkis1.PNG"
import LoginIcon from '@mui/icons-material/Login';
import { useEffect, useState } from "react";
import { useMediaQuery } from "../api/utils";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function LandingPage() {

    const navigate = useNavigate();
    const {auth} = useAuth();
    const [titletransition, setTitleTransition] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setTitleTransition(true);
      }, 1000)
    }, [])
    
    
    const isMobile = useMediaQuery("(max-width: 700px)");



    return(
        <Box sx={{width: '100%', height:'100%'}}>
            <Box
            sx={{
                mx: 'auto', 
                width:'100%',
                
                border: '1px solid',
                borderColor: '#f5f5f5',
                background:'linear-gradient(rgba(255,255,255,255), transparent)'
            }}
            >
                <Box sx={{background:'linear-gradient(to right, rgba(38,38,38,255), rgba(38,38,38,0.8))', margin: 5, padding: 2, display: 'inline-flex', borderRadius:1}}>
                    <Typography variant="h2"  sx={{color:'#ffffff', fontWeight:'bold'}}>
                        FORM DEMO
                    </Typography>
                </Box>
                {auth !== undefined && 'user' in auth ?
                (
                    <Box>
                        <Box sx={{margin: 5}}>
                            <Box sx={{display:'flex'}}>
                                <Fade in={titletransition} {...(titletransition ? {timeout: 1000} : {})}>
                                    <Typography variant="h3" >
                                        Welcome
                                    </Typography>
                                </Fade>
                                <Fade in={titletransition} {...(titletransition ? {timeout: 2000} : {})}>
                                    <Typography variant="h3" >
                                        ,
                                    </Typography>
                                </Fade>
                                <Fade in={titletransition} {...(titletransition ? {timeout: 4000} : {})}>
                                    <Typography variant="h3" sx={{marginLeft:1}}>
                                        {auth.user}
                                    </Typography>
                                </Fade>
                                
                                
                            </Box>
                            
                        </Box>
                        <Box sx={{ backgroundColor: '#262626', height:'35ch'}}>
                            <Box sx={{marginLeft:5, paddingTop:5}}>
                                <Typography color={'white'} variant="h5">
                                    Workflow Guide Mode Intro
                                </Typography>
                                <Button size="large" variant="contained" endIcon={<LoginIcon/>} onClick={() => {navigate('/workflowguide/intro')}}
                                    sx={{}}
                                >
                                    <Typography variant="h4">
                                        Intro
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    
                )
                :
                (
                    <Box sx={{margin: 5}}>
                        <Box>
                            <Typography variant="h5">
                                Already have an account?
                            </Typography>
                            
                            <Button size="large" endIcon={<LoginIcon/>} onClick={() => {navigate('/login')}}
                                sx={{}}
                            >
                                <Typography variant="h4">
                                    Log In
                                </Typography>
                            </Button>
                        </Box>
                        
                    </Box>
                )
                }
                
            </Box>
        </Box>
        
    )
}

/*
    Tallennus
    Valitse mobiili
*/