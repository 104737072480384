import { useEffect, useState } from 'react';
import { UserHasPermissions } from '../api/utils';
import FormCardElement from './form-card-element';
import FormFolder from './form-folder.component';
import QueryList from './query-list.component';
import { Box, Grid, Typography, List, ListItemIcon, ListItemText, Collapse, ListItemButton, ListItem } from '@mui/material';
import {motion} from "framer-motion"
import { Reorder, useDragControls } from 'framer-motion';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// The list functionality animated and logics with Framer
export default function FormListListView({formData, folders, currentFolder, currentFolderChildren, folderChildrenAmount, openFolder, filterString, suretodeletepopup, getQueryObjById, getFillCounter, auth}) {

    const [templist, settemplist] = useState([])
    const controls = useDragControls()

    useEffect(() => {
        settemplist(formData)
    }, [])
    


    const ROListItem = ({form}) => {
        const controls = useDragControls()
        return(
            <ListItem
                component={Reorder.Item}
                key={form._id}
                value={form}
                dragListener={false}
                dragControls={controls}
            >
                <ListItemIcon
                    className='reorder-handle'
                    onPointerDown={(e) => controls.start(e)}
                >
                    <DragHandleIcon/>
                </ListItemIcon>
                <ListItemButton>
                    <ListItemText>
                        {form.name}
                    </ListItemText>
                </ListItemButton>

            </ListItem>
        )
    }


    return (
        <Box sx={{backgroundColor:currentFolder!==null?currentFolder.color:null,transition: "all 0.5s ease",borderRadius:5,
            display:'flex', width:'95%', justifyContent:'center', alignItems:'center'
        }}
        >
            
            {(filterString === '' && filterString.length === 0) ? (
                //filter string empty, show current folder 🤖
                <Box sx={{width:{md:'75%', lg:"75%"}}}>
                    <List
                        component={Reorder.Group}
                        values={templist}
                        onReorder={settemplist}
                        
                    >
                        {templist.map(form => (
                            <ListItem
                                component={Reorder.Item}
                                value={form}
                                key={form._id}
                                dragListener={true}
                                dragControls={controls}
                            >
                                <ListItemIcon
                                    className='reorder-handle'
                                    onPointerDown={(e) => controls.start(e)}
                                >
                                    <DragHandleIcon
                                    />
                                </ListItemIcon>
                                <ListItemButton>
                                    <ListItemText>
                                        {form.name}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))

                        }
                    </List>
                </Box>
            ) : (
                // 🏃 filtering folders and forms based on filterstring
                <Grid container spacing={3} columns={12}>
                {folders.filter(folder => folder.name.toString().toLowerCase().includes(filterString.toString().toLowerCase())&&folder._id!=="0").map((obj, index) => {
                    //folder.id !== 0 hides root folder 👻
                    return(
                        <Grid xs={12} sm={3} md={2} lg={1.5} key={obj._id}>
                            
                            <FormFolder openFolderCB={openFolder} folder={obj} children_n={folderChildrenAmount(obj._id)}/>
                                
                        </Grid>
                    )
                })}

                {formData.filter(form => form.name.toString().toLowerCase().includes(filterString.toString().toLowerCase())).map((obj, index) => {
                    return (
                        
                        <Grid xs={12} sm={6} md={4} lg={3} key={obj._id}>
                            
                            <FormCardElement 
                            key={obj._id}
                            delay={80*index}
                            queryTable={getQueryObjById(obj._id)}
                            //👇 form is for showing data on form cards, consider injecting only relevant data
                            form={{
                                name: obj.name,
                                id: obj._id,
                                build_instruction: obj.build_instruction,
                                timestamp: obj.createdAt,
                                creator: obj.creator,
                                fill_counter: getFillCounter(obj._id)
                            }}
                            userCanDelete={UserHasPermissions(auth.permissions, ["Delete"])}
                            startDeletePopup={suretodeletepopup}
                            //openForm={openForm(obj._id)}
                            />
                                
                            
                        </Grid>
                        
                    )
                })}
                </Grid>
            )}
        </Box>
    )
}