//https://www.youtube.com/watch?v=nI8PYZNFtac

/* SAFE GETTER
    Idea: "'Get' requrest the server in a defined async function inside useEffect
    with mounting, abortcontroller and try/catch block while using privateAxios"
    code:
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try{
                const response = await aciosPrivate.get('/users', {
                    signal: controller.signal
                });
                console.log(response.data);
                isMounted && setUsers(response.data);
            } catch(err) {
                console.error(err);
                navigate('/login', {state: {from: location}, replace: true});
            }
        }
    })
*/

import {axios, axiosPrivate} from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const {auth} = useAuth();

    useEffect(() => {
        const reqIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );
      
        const resIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevReq = error?.config;
                if (error?.reponse?.status === 403 && !prevReq?.sent) {
                    prevReq.sent = true;
                    const newAccessToken = await refresh();
                    prevReq.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevReq);
                }
                return Promise.reject(error);
            }
        );

      return () => {
        axiosPrivate.interceptors.response.eject(resIntercept);
        axiosPrivate.interceptors.response.eject(reqIntercept);
      }
    }, [auth, refresh])
    

    return axiosPrivate;
}

export default useAxiosPrivate;