/* to do 21.8
- nested mui grid for form create
- implement form elements in order (in external component) inside grid row Input/DropDown
- right column size for elements
- add new row & save n amount of row data
- connect save button to backend and send row data to handler
*/


import React, { Component, useEffect, useState } from 'react';
import DataRow from './datarow.component.js'; 

import axios from '../api/axios'
import {Link, useNavigate, useLocation} from 'react-router-dom';
import CreateFormSettings from './create-form-settings.component.js';

import { styled } from '@mui/material/styles';
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Box, Typography, Divider, Tooltip, IconButton, Menu,MenuItem, TextField, Collapse, CircularProgress, List, ListItemButton, ListSubheader, ListItemIcon,ListItemText} from '@mui/material';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SettingsIcon from '@mui/icons-material/Settings';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Grid from '@mui/material/Unstable_Grid2';
 
import useAuth from '../hooks/useAuth.js';

import OpenAI from 'openai';

import { Reorder } from 'framer-motion';

const SystemTemplateForms = [
    [{"property_name":"First Name",'property_type':"Text",'require_field':true} , {"property_name":"Last Name",'property_type':"Text",'require_field':true}, {"property_name":"Age",'property_type':"Number",'require_field':false}, {"property_name":"Email",'property_type':"Text",'require_field':true}, {"property_name":"Phone Number",'property_type':"Text",'require_field':true}, {"property_name":"Address",'property_type':"Text",'require_field':false}],
    [{"property_name":"Location",'property_type':"Text",'require_field':true}, {"property_name":"Order Number",'property_type':"Number",'require_field':true}, {"property_name":"Contact Email",'property_type':"Number",'require_field':true}]
]

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


const helper_system_prompt = "The answer can not include any raw text, for example no notion that you understood the question should be included, respond should consist only of a json code block. The code block should be in JSON format which contains a JSON object array with n number of items depending on how you interpet the free form question and decide to include them. The JSON object array\'s object only and only contains properties \"property_name\" and \"property_type\"  property named \"property_name\" should be related to the data user wants to be saved. no creative or silly names please. property named \"property_type\" can only be one of the following strings \"Number\", \"Text\", \"Toggle\", \"Time\", \"Date\", \"Image\", \"List\" and \"Stopwatch\". The format of the strings is case sensitive and should always start with capital letter.  object array\'s object\'s property named \"property_type\" should be one of the before mentioned strings fit accordinginly how you interpet the user prompt. If user asks for example form, give the user a form that contains fields for name, phone number (as text), email, date of birth, profile picture etc. The root object array's name should ALWAYS be \"data\" ";
const sus = process.env.REACT_APP_OPENAI_API_KEY;

export default function CreateForm()  {

    const [formName, setFormName] = useState("");
    const [rowCounter, setRowCounter] = useState(1);
    const [rowIdCounter, setRowIDCounter] = useState(0);
    const [rowDatasets, setRowDataSets] = useState([])
    const [rowStatusData, setRowstatusData] = useState();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [exitSafetyOpen, setExitSafetyOpen] = useState(false);
    const [formSettings, setFormSettings] = useState();
    const [formValid, setFormValid] = useState(false);
    const [formRowGap, setFormRowGap] = useState(0.5);
    const [rememberViewMode, setRememberViewMode] = useState(null)

    const location = useLocation();
    const [userTemplateName, setUserTemplateName] = useState("");
    const [openSaveTemplateDialog, setOpenSaveTemplateDialog] = useState(false);
    const handleClickOpenSaveTemplate = () => {
        setOpenSaveTemplateDialog(true);
    }
    const handleClickCloseSaveTemplate = () => {
        setOpenSaveTemplateDialog(false);
    }
    const [loadingTemplates, setLoadingTemplates] = useState([])
    const [fetchedTemplates, setFetchedTemplates] = useState([]);
    const [openSystemTemplatesSubheader, setOpenSystemTemplatesSubheader] = useState(false)
    const [openUserTemplatesSubheader, setOpenUserTemplatesSubheader] = useState(false)
    const [templateElAnchor, setTemplateElAnchor] = useState(null)
    const openTemplates = Boolean(templateElAnchor)
    const handleClickTemplate = (event) => {
        setTemplateElAnchor(event.currentTarget);
    }
    const handleCloseTemplate = () => {
        setTemplateElAnchor(null)
    }

    const onChangeUserTemplateName = (e) => {
        setUserTemplateName(e.target.value);
    }

    const handleClickSystemTemplates = () => {
        if(openSystemTemplatesSubheader) { setOpenSystemTemplatesSubheader(false);} else {setOpenSystemTemplatesSubheader(true);}
    }
    const handleClickUserTemplates = () => {
        if(openUserTemplatesSubheader) { setOpenUserTemplatesSubheader(false);} else {setOpenUserTemplatesSubheader(true);}
    }
    const [helperOpen, setHelperOpen] = useState(false);
    const [helperPrompt, setHelperPrompt] = useState("");
    const [helperResult, setHelperResult] = useState({});
    const [loadingHelper, setLoadingHelper] = useState(false);
    const openai = new OpenAI({
        apiKey: sus,
        dangerouslyAllowBrowser: true
    });
    const {auth} = useAuth();

    const navigate = useNavigate();

    const handleClickOpenSettings = () =>{
        setSettingsOpen(true);
    }

    const handleClickCloseSettings = () =>{
        setSettingsOpen(false);
    }

    const handleClickOpenExitSafe = () => {
        setExitSafetyOpen(true);

    }
    const handleClickCloseExitSafe = () => {
        setExitSafetyOpen(false);

    }

    const handleBackClick = () => {
        if(rowDatasets.length > 0){
            handleClickOpenExitSafe()
        } else {
            navigate(-1);
        }
    }

    const saveFormSetting = (settings) => {
        setFormSettings({
            "isPrivate": settings.privateForm,
            "fillGroups": settings.fillGroups,
            "editGroups": settings.editGroups
        })
        setFormName(settings.formName)
        setFormRowGap(settings.rowGap);
    }

    const handleChangePromptTextField = (e) => {
        setHelperPrompt(e.target.value);
        console.log(helperPrompt);
    }

    useEffect(() => {
      constructHelperDataRows()
    }, [helperResult])
    

    const constructHelperDataRows = () => {
        
        
        if(helperResult !== null && helperResult.success && helperResult.data !== null && Object.keys(helperResult.data.data).length > 0){
            setRowDataSets([])
            console.log(Object.keys(helperResult.data.data).length > 0)
            console.log("check success");
            console.log(helperResult.data.data)
            let arraytemp = []
            let idc = rowIdCounter
            helperResult.data.data.map((row, i) => {
                arraytemp.push({
                    additional_info:[],
                    id: idc+i,
                    row: i + 1,
                    property_name: row.property_name,
                    property_type: row.property_type,
                    require_field: false
                })

            })
            setRowIDCounter(rowIdCounter => rowIdCounter + Object.keys(helperResult.data.data).length);
            setRowDataSets(arraytemp);
        }
    }

    const insertTemplate = (template) => {
        if(template != null ){
            setRowDataSets([])
            let arraytemp = []
            let idc = rowIdCounter
            template.map((row, i) => {
                arraytemp.push({
                    additional_info: row.additional_info,
                    id: idc+i,
                    row: i + 1,
                    property_name: row.property_name,
                    property_type: row.property_type,
                    require_field: false
                })

            })
            setRowIDCounter(rowIdCounter => rowIdCounter + template.length);
            setRowDataSets(arraytemp);
        }
        handleCloseTemplate();
    }

    const handleHelperSubmit = async () => {
        
        setLoadingHelper(true);
        try{
            /*
            const res = axios.post("https://api.openai.com/v1/chat/completions",{
                headers:{
                    Authorization: `Bearer ${sus}`
                },
                body: JSON.stringify({
                    model:'gpt-3.5-turbo',
                    messages:[
                        {"role": "system", "content": helper_system_prompt},
                        {"role": "user", "content":helperPrompt}
                    ],
                })
            })
            */
            console.log("is this even reached");
            const res = await openai.chat.completions.create({
                messages:[
                    {"role": "system", "content": helper_system_prompt},
                    {"role": "user", "content":helperPrompt}
                ],
                model: "gpt-3.5-turbo",
                response_format: { type: "json_object" },
            });
            console.log(JSON.parse(res.choices[0].message.content))
            let john = {
                "success":true,
                "data":JSON.parse(res.choices[0].message.content)
            }
            setHelperResult(john);
            console.log(john);
            
            
            //constructHelperDataRows();
        } catch (e){
            setHelperResult({
                "success":false,
                "data":e
            })
            console.log(e);
        }
    

        setLoadingHelper(false);
        setHelperOpen(false);
        
        
    }

    const checkCreateFormValidation = () => {
        //iterate datarows, check no empty datarows exist
        const temp = rowDatasets;
        let valid = true;
        if(rowDatasets === null || rowDatasets.length === 0){
            valid = false;
        }else {
            temp.map((row) => {
                console.log(row);
                if(row.property_name === '' || row.property_type === ''){
                    valid = false;
                }
                if(row.property_type === 'List'){
                    if(row.additional_info.length === 0){
                        valid = false;
                    }else {
                        row.additional_info.map((_row) => {
                            if(_row.property_name === '' || _row.property_type === ''){
                                valid = false;
                            }
                        })
                    }
                }
            })
        }
        
        setFormValid(valid);
    }

    const handleRowAdd = () =>{
        /*
        this.setState({
            rowCounter: this.state.rowCounter + 1
        })
        */
        setRowCounter(rowCounter +1);
        setRowIDCounter(rowIdCounter +1);
        //this.state.rowItems.push({'id':this.state.rowCounter})
        //setRowItems(rowItems => [...rowItems, {'id':rowCounter}])
        setRowDataSets(rowDatasets => [...rowDatasets, {'row':rowCounter, 'id':rowIdCounter, "property_name":'','property_type':'','require_field':false}])
        //console.log(rowItems);
        console.log(rowDatasets);
        console.log(rowCounter);
    }
    const handleRowXAdd = (pname, ptype) =>{
        /*
        this.setState({
            rowCounter: this.state.rowCounter + 1
        })
        */
        setRowCounter(rowCounter +1);
        setRowIDCounter(rowIdCounter +1);
        //this.state.rowItems.push({'id':this.state.rowCounter})
        //setRowItems(rowItems => [...rowItems, {'id':rowCounter}])
        setRowDataSets(rowDatasets => [...rowDatasets, {'row':rowCounter, 'id':rowIdCounter, "property_name":pname,'property_type':ptype,'require_field':false}])
        //console.log(rowItems);
        console.log(rowDatasets);
        console.log(rowCounter);
    }
    

    useEffect(()=>{
        handleRowAdd();
        setFormName(location.state.formname)
        if(location.state.lastview !== undefined){
            setRememberViewMode(location.state.lastview)
        }
        console.log("current folder: "+location.state.folder);
        fetchTemplates()
    }, [])

    function fetchTemplates(){
        setLoadingTemplates(true);
        

        axios.get('/templates', {params:{"server":auth.server, "creator":auth.user}})
        .then(response => {
            setFetchedTemplates(response.data);
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoadingTemplates(false)
            console.log("fetched templates");
             
        })
    }

    const saveTemplate = () => {
        let curtemplate = rowDatasets;
        axios.post('/templates/add',{
            name: userTemplateName,
            template: curtemplate,
            creator: auth.user,
            server: auth.server
        })
        .then(res => {
            console.log(res.data);
            setFetchedTemplates([
                ...fetchedTemplates,
                {
                    name: userTemplateName,
                    template: curtemplate,
                    creator: auth.user
                }
            ]);
        })
        .catch((error) => {
            console.log(error);
            console.log(userTemplateName);
        })
        handleClickCloseSaveTemplate();
    }

    const moveRowDown = (rownum) => {
        //replace rowDatasets with current working row data array
        if(rownum !== undefined && rownum !== rowDatasets.length-1){
            let temp1 = rowDatasets[rownum];
            let temp2 = rowDatasets[rownum+1];
            setRowDataSets(rowDatasets.map((row, i) => {
                if(i == rownum){
                    return temp2;
                } else if(i == rownum+1){
                    return temp1;
                }else{
                    return row;
                }
            })

            )
        }
    }   
    const moveRowUp = (rownum) => {
        //replace rowDatasets with current working row data array
        if(rownum !== undefined && rownum !== 0){
            let temp1 = rowDatasets[rownum];
            let temp2 = rowDatasets[rownum-1];
            setRowDataSets(rowDatasets.map((row, i) => {
                if(i == rownum){
                    return temp2;
                } else if(i == rownum-1){
                    return temp1;
                }else{
                    return row;
                }
            })

            )
        }
    }

    const newRowCallBack = (value) => {
        const updatedRowdatasets = rowDatasets.map(rowdata => {
            if(rowdata.id === value.id){
                return {
                    ...rowdata,
                    property_name: value.property_name,
                    property_type: value.property_type,
                    require_field: value.require_field,
                    additional_info: value.additional_info
                };
            } else {
                return rowdata;
            }
        })
        setRowDataSets(updatedRowdatasets);
        
        console.log(rowDatasets)
    }

    useEffect(() => {
        checkCreateFormValidation();
    }, [rowDatasets])
    
    /*
    const rowCallback = (value) =>{
        if(rowDatasets && !rowDatasets.length){
            //row data sets is empty, push item
            //this.state.rowDatasets.push(value)
            setRowDataSets(rowDatasets => [...rowDatasets, value])
            console.log(rowDatasets);
            console.log(value);
        } else {
            //not empty, find matching ID to append otherwise, push item
            /*
            this.setState(prevState=> ({
                rowDatasets: prevState.rowDatasets.map(
                    obj => obj.id === value.id ? Object.assign(obj, {
                        property_name: value.property_name,
                        property_type: value.property_type,
                        require_field: value.require_field
                    }) : obj
                )
            }))
            */
           /*
            const updatedRowdatasets = rowDatasets.map(rowdata => {
                if(rowdata.id === value.id){
                    return {
                        ...rowdata,
                        property_name: value.property_name,
                        property_type: value.property_type,
                        require_field: value.require_field
                    };
                } else {
                    return rowdata;
                }
            })
            setRowDataSets(updatedRowdatasets);

            if (!rowDatasets.find(obj => obj.id === value.id)){
                //this.state.rowDatasets.push(value);
                setRowDataSets(rowDatasets => [...rowDatasets, value])
            }

        }
        console.log(rowDatasets)
    }
    */
    
    const rowDeleteCallfunction = (e) => {
        popfromRowDatasets(e);
    }

    function popfromRowDatasets(target){
        var temparr = [...rowDatasets]
        if(rowDatasets.length > target.row-1 && target.row >= 0){

            var whaa = temparr.splice(target.row, 1)
            console.log(whaa);
            console.log(temparr);
            const updatedRowdatasets = temparr.map((rowdata, index) => {
                return {
                    ...rowdata,
                    row: index+1
                };
            })
            console.log(updatedRowdatasets);
            setRowDataSets(updatedRowdatasets);
            //setRowDataSets(rowDatasets.filter((o, i) => target.id-1 !== i));
            setRowCounter(rowCounter => rowCounter -1);
            console.log(rowDatasets);
        }
    }

    


    const formSubmitSave = () =>{
        // all collected data is saved and posted. If form post is successful only after then the querty post will be done
        const form = {
            name: formName,
            folder: location.state.folder._id,
            clearance: '0',
            build_instruction: rowDatasets,
            creator: auth.user,
            server: auth.server
        }
        
        console.log(form);
         
        axios.post('/forms/add', form)
        .then(res => {
            console.log(res.data);
            var pairid = res.data.id;
            const query = {
                name: formName,
                clearance: '0',
                form_pair_id: pairid,
                query_data: [],
                server: auth.server
                
            }
            const folderdata = {
                id: location.state.folder._id,
                child: pairid
            }
            axios.post('/queries/add', query)
            .then(res => {
                console.log(res.data);
                navigate("/forms", {
                    state: {
                        success_create: true,
                        lastview: rememberViewMode
                    }
                });
            })
            .catch(err => {
                console.log(err);
                navigate("/forms", {
                    state: {
                        success_create: false,
                        lastview: rememberViewMode
                    }
                });
            })
        })
        .catch(err => {
            console.log(err);
            navigate("/forms", {
                state: {
                    success_create: false,
                    lastview: rememberViewMode
                }
            });
        })
    }
    
        return (
            <div style={{ height:'100%', width:'100%', top:55}}>
                <Box sx={{display:'flex', justifyContent:'space-between', background: "#e6e6e6"}}>
                    <Box sx={{alignItems:'center', display:{xs:'none',md:'none', lg:'flex'}}}>
                        <Button variant="contained" onClick={handleBackClick} startIcon={<ArrowBackIosIcon/>}>Back</Button>
                    </Box>
                    <Box sx={{alignItems:'center', display:{xs:'flex',md:'flex', lg:'none'}}}>
                        <IconButton onClick={handleBackClick} color='primary'>
                            <ArrowBackIosIcon fontSize='large'/>
                        </IconButton>
                        
                    </Box>
                    <Box className='d-flex align-items-center'>
                        <Typography variant="h5" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                            {formName}
                        </Typography>
                    </Box>
                    <Box sx={{alignItems:'center', display:{xs:'none',md:'none', lg:'flex',},  }}>
                        
                        <Button onClick={() => {setHelperOpen(true)}} variant="contained" endIcon={<SmartButtonIcon/>}>AI</Button>
                        <Button variant='contained' id='template-button' aria-controls={openTemplates ? 'template-menu' : undefined} aria-haspopup="true" aria-expanded={openTemplates ? 'true' : undefined} onClick={handleClickTemplate}>Templates</Button>
                        
                        <Button onClick={handleClickOpenSettings} variant="contained" endIcon={<SettingsIcon/>}>Settings</Button>
                        
                        <Button onClick={formSubmitSave} disabled={!formValid} variant='contained' color='success' endIcon={<SaveIcon/>} >SAVE</Button>
                        
                        
                    </Box>
                    <Box sx={{alignItems:'center', display:{xs:'flex',md:'flex', lg:'none'}}}>

                        <IconButton onClick={() => {setHelperOpen(true)}} >
                            <SmartButtonIcon fontSize='large' sx={{color:'#1565c0'}}/>
                        </IconButton>
                        <IconButton onClick={handleClickOpenSettings}  >
                            <SettingsIcon fontSize='large' sx={{color:'#1565c0'}}/>
                        </IconButton>
                        <IconButton onClick={formSubmitSave} disabled={!formValid} color='success'>
                            <SaveIcon fontSize='large'/>
                        </IconButton>
                    </Box>
                    <Menu
                    id='template-menu'
                    anchorEl={templateElAnchor}
                    open={openTemplates}
                    onClose={handleCloseTemplate}
                    MenuListProps={{
                        'aria-labelledby':'template-button',
                    }}
                    >
                        <List
                            subheader={
                                <ListSubheader>
                                    Templates
                                </ListSubheader>
                            }
                        >
                            <ListItemButton onClick={handleClickSystemTemplates}>
                                <ListItemIcon>
                                    <ListAltIcon/>
                                </ListItemIcon>
                                <ListItemText primary="System Templates"/>
                                {openSystemTemplatesSubheader ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </ListItemButton>
                            <Collapse in={openSystemTemplatesSubheader} timeout="auto" unmountOnExit>
                                <List>
                                    <ListItemButton sx={{pl:4}} onClick={() => insertTemplate(SystemTemplateForms[0])}>
                                        <ListItemText>
                                            User Data Form
                                        </ListItemText>
                                    </ListItemButton>
                                    <ListItemButton sx={{pl:4}} onClick={() => insertTemplate(SystemTemplateForms[1])}>
                                        <ListItemText>
                                            Customer Form
                                        </ListItemText>
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <Divider />
                            <ListItemButton onClick={handleClickUserTemplates}>
                                <ListItemIcon>
                                    <ListAltIcon/>
                                </ListItemIcon>
                                <ListItemText primary="User Templates"/>
                                {openUserTemplatesSubheader ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </ListItemButton>
                            <Collapse in={openUserTemplatesSubheader} timeout="auto" unmountOnExit>
                                <List>
                                    <ListItemButton onClick={() => {setOpenSaveTemplateDialog(true); handleCloseTemplate()}} sx={{pl:4}}>
                                        <ListItemIcon>
                                            <AddCircleIcon sx={{color:'#1565c0'}}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Set As New Template
                                        </ListItemText>
                                    </ListItemButton>
                                    <Divider />
                                    {console.log(fetchedTemplates)}
                                    {loadingTemplates ? (null) : (
                                        
                                        fetchedTemplates.map((row, i) => {
                                            return(
                                                <ListItemButton onClick={() => insertTemplate(row.template)} sx={{pl:8}}>
                                                    <ListItemText>
                                                        {row.name}
                                                    </ListItemText>
                                                </ListItemButton>
                                            )
                                        }))}
                                    
                                    
                                </List>
                            </Collapse>
                        </List>
                    </Menu>
                </Box>
                
                <Box sx={{
                    display:'flex', 
                    height: formValid ? '0px' : '25px' ,
                    alignContent:'center', 
                    justifyContent:'end',
                    transition: "all 0.5s ease",
                    WebkitTransition: "all 0.5s ease",
                    MozTransition: "all 0.5s ease" ,
                    }}>
                    <Typography sx={{ fontSize:'13px', color:formValid?'white' : 'red', letterSpacing:'2px' , transition: "all 0.5s ease",}}>
                        Fill out all the datarows to save the form
                    </Typography>
                </Box>
                
                <div className="container-fluid">
                    <Grid container spacing={2} rowGap={formRowGap} columns={16} direction={'column'} style={{height:"100%"}}>
                        <Grid container xs={16} columnSpacing={1} padding={'8px 0px 4px 0px'} layout={'row'}>
                            <Grid xs={1}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                        #
                                    </Typography>
                                </Item>
                            </Grid>
                            <Grid xs={6}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Property Name
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Name
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={5}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center', fontFamily:'Open Sans'}}>
                                            Property Type
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                            Type
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={2}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold', justifyContent:'center',fontFamily:'Open Sans'}}>
                                            Require field
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Tooltip title="Require field">
                                            <PriorityHighIcon />
                                        </Tooltip>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid xs={2}>
                                <Item style={{color: 'black', fontWeight:'bold'}}>
                                    <Box sx={{ justifyContent:'center',display:{xs:'none',md:'none', lg:'flex'}}}>
                                        <Typography variant="body1" sx={{fontWeight:'bold',fontFamily:'Open Sans'}}>
                                            Row Operations
                                        </Typography>
                                    </Box>
                                    <Box sx={{justifyContent:'center',display:{xs:'flex',md:'flex', lg:'none'}}}>
                                        <Tooltip title="Row Operations">
                                            <SettingsIcon />
                                        </Tooltip>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                        
                        <Reorder.Group
                            axis='y'
                            values={rowDatasets}
                            onReorder={setRowDataSets}
                             
                        >

                        
                        {rowDatasets.map((row, index)=> {
                            return(
                                
                                    <DataRow 
                                    key={row.id}
                                    maxrows={rowDatasets.length}
                                    injectData={row}
                                    id={row.id}
                                    rownum={index}
                                    callback={newRowCallBack}
                                    rowdeleteCB={rowDeleteCallfunction}
                                    moveRowDown={moveRowDown}
                                    moveRowUp={moveRowUp}
                                    _item={row}
                                    ></DataRow>
                                
                                
                            )
                        })}
                        </Reorder.Group>
                        
                    </Grid>
                </div>
                <br />
                <div className='d-flex align-items-center justify-content-center ' >
                    <IconButton onClick={handleRowAdd}>
                        <AddCircleIcon fontSize='large' sx={{fontSize: 70, }} color="primary" ></AddCircleIcon>
                    </IconButton>
                    
                </div>
                {/* save template dialog */}
                <Dialog
                    open={openSaveTemplateDialog}
                    onClose={handleClickCloseSaveTemplate}
                    aria-labelledby='template-save-title'
                >
                    <DialogTitle id='template-save-title'>
                        Template title
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            What will the user template be titled as?
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin='dense'
                            label='Title'
                            fullWidth
                            variant='standard'
                            onChange={onChangeUserTemplateName}
                        >

                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCloseSaveTemplate}>Cancel</Button>
                        <Button onClick={() => saveTemplate()} variant='contained'>Save</Button>
                    </DialogActions>
                </Dialog>
                {/* leave dialog */}
                <Dialog
                    open={exitSafetyOpen}
                    onClose={handleClickCloseExitSafe}
                    arial-labelledby='exit-safe-title'
                    
                >
                    <DialogTitle id='exit-safe-title'>
                        Discard unsaved changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to leave? Leaving now will discard all unsaved changes!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCloseExitSafe}>Stay</Button>
                        <Button onClick={() => {
                            navigate("/forms", {
                                state: {
                                    success_create: false,
                                    lastview: rememberViewMode
                                }
                            });
                        }}>Exit</Button>
                    </DialogActions>
                </Dialog>
                {/* HELPER DIALOG */}
                <Dialog
                    open={helperOpen}
                    onClose={() => setHelperOpen(false)}
                    arial-labelledby='exit-safe-title'
                    maxWidth={'lg'}
                >
                    <DialogTitle >
                        
                        Create Form Helper
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography sx={{fontSize:13, fontWeight: 'bold', letterSpacing:1, fontFamily:'Open Sans'}} >
                                Powered by Chat GPT 3.5 Turbo API
                            </Typography>
                        </DialogContentText>
                        <DialogContentText>
                            Describe what you want in your form or what do you use your form for (e.g. I want a form to save dimensions of a room) and the helper will try to give you a starter form!
                        </DialogContentText>
                        <br />
                        <Collapse in={!loadingHelper}>
                            <TextField 
                                /*
                                style={{
                                    opacity:loadingHelper ? (0) : (1),
                                    transition: "all 0.5s ease",
                                    WebkitTransition: "all 0.5s ease",
                                    MozTransition: "all 0.5s ease"
                                }}
                                */
                                sx={{width:'100%'}}
                                label="Prompt"
                                multiline
                                minRows={5}
                                onChange={handleChangePromptTextField}
                                disabled={loadingHelper}
                            />
                        </Collapse>
                        {!loadingHelper ? (null) : (
                            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <CircularProgress />
                            </Box>
                        )}
                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' disabled={loadingHelper} onClick={() => setHelperOpen(false)} >Back</Button>
                        <Button variant='contained' color='success' disabled={loadingHelper} onClick={() => handleHelperSubmit()}>Generate</Button>
                    </DialogActions>
                </Dialog>

                {formName =="" || formName ==null?"" : (
                    <CreateFormSettings
                        open={settingsOpen}
                        handleClose={handleClickCloseSettings}
                        saveSettings={saveFormSetting}
                        formName={formName}
                    />
                )}
                
            </div>
        )
    }

/*
<Item className='d-flex align-items-center justify-content-center' style={{width:'100%', minHeight:35 , padding: '0'}} >
                                    <input style={{width:'100%', minHeight:35,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
                                </Item>

==ROW CHILD COMPONENT===
<Grid container xs={16} columnSpacing={1} layout={'row'} style={{height:'100%'}}>
    <Grid xs={1} style={{height:'100%'}}><Item>1</Item></Grid>
    <Grid xs={6} style={{height:'100%', }}>
        <input style={{width:'100%', minHeight:35,  padding: '2 2 2 2', border:'0', backgroundColor:'#EFEFEF', borderColor:'#E5E5E5', borderRadius:5}} type="text" />
    </Grid>
    <Grid xs={5} style={{height:'100%'}}>
        <select style={{width:'100%', minHeight:35,  padding: '2 2 2 2', border:'0', borderRadius:5 }}>
            <option>Choose Property Type</option>
                {options.map((option, index) => {
                    return <option key={index}>
                        {option}
                    </option>
                })}
        </select>
    </Grid>
    <Grid xs={2}>
        <Item className='d-flex align-items-center justify-content-center' style={{padding:0, minHeight:35}}>
            <Switch  size='small'/>
        </Item>
    </Grid>
    <Grid xs={2}><Item>Row operations</Item></Grid>
</Grid>


old save button
<Button onClick={this.formSubmitSave} variant="contained" color='success' endIcon={<SaveIcon/>}>save</Button>
*/