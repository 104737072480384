import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  useNavigate,
  useLocation,
  Link,
  UNSAFE_DataRouterStateContext,
} from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from '../api/axios'
import useAuth from '../hooks/useAuth';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {  PermObjToArray, PermParser } from '../api/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';



const LOGIN_URL = "/users/login";

/* AUTHORIZATION, AUTHENTICATION
PART 1 - https://www.youtube.com/watch?v=X3qyxo_UTR4
PArt 2 https://www.youtube.com/watch?v=oUZjO00NkhY
PART 3 https://www.youtube.com/watch?v=nI8PYZNFtac
*/

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


function LoginPage() {
    
    const {setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [loginGrow, setLoginGrow] = useState(false);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [server, setServer] = useState("");
    const [credentialsWarn, setCredentialsWarn] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [popup_loginTimeout, setPopup_loginTimeout] = useState(false);

    useEffect(()=>{
        setCredentialsWarn(false);
        setLoginGrow(true);
        if(localStorage.getItem("server")){
            setServer(localStorage.getItem("server"))
        }
        //debuga();
    },[])

    const debuga = () => {
        let value = "11111111";
        const perm = PermParser(value);
        const permar = PermObjToArray(PermParser(value));
        console.log(perm)
        console.log(permar)
    }

    const updateInputUsername = (e) => {
        setUser(e.target.value);
        if(credentialsWarn){setCredentialsWarn(false);}
    }
    const updateInputPassword = (e) => {
        setPassword(e.target.value);
        if(credentialsWarn){setCredentialsWarn(false);}
    }
    const updateInputServer = (e) => {
        setServer(e.target.value);
        if(credentialsWarn){setCredentialsWarn(false);}
    }
    const onLoginSubmit = async (e) => {
        
        setLoadingLogin(true);
        e.preventDefault();
        try{
            const response = await axios.post(LOGIN_URL, JSON.stringify({user, password, server}),
            {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            }
            );
            console.log(JSON.stringify(response?.data));

            const accessToken = response?.data?.accessToken;
            //const permissions = response?.data?.permissions;
            //console.log(response?.data?.permissions)
            const permissions = PermObjToArray(PermParser(response?.data?.permissions.value))
            const rolename = response?.data?.permissions.role;
            //console.log(rolename);
            console.log(permissions);
            
            setAuth({user, password, permissions, server, accessToken, rolename});
            
            localStorage.setItem("server",server)
            setCredentialsWarn(false);
            setLoginGrow(false);
            setLoadingLogin(false);
            navigate(from, {replace: true})
        } catch(err){
            if(!err?.response){
                console.log("No Server Response");
                setLoadingLogin(false);
            } else if(err.response?.status === 400){
                console.log("Missing credentials");
                setCredentialsWarn(true);
                setLoadingLogin(false);
                console.log(err.response?.message);
            } else if(err.response?.status === 401){
                console.log("Unauthorized")
                setCredentialsWarn(true);
                setLoadingLogin(false);
            }else{
                console.log("Login failed");
            }
            //errRef.current.focus();
        }
    }


  return (
    <div className='d-flex justify-content-center align-items-center' style={{height:"65vh", }}>
        <div>
            <Grow in={loginGrow}>
                <Box component='form' autoComplete="off"  sx={{p:2, width: {xs:'100%', md:'50ch'},borderRadius:1, boxShadow:'-2px 1px 1px 0px rgba(0,0,0,0.1)'}}>
                    <Typography variant='h5' gutterBottom>
                        Log in
                    </Typography>
                    <div>
                        <TextField 
                        
                        value={server}
                        onChange={updateInputServer}
                        label="Server"
                        fullWidth
                        variant='standard'
                        margin='normal'
                        />
                    </div>
                    <div>
                        <TextField 
                        onChange={updateInputUsername}
                        required
                        id="standard-username"
                        defaultValue=""
                        label="Username"
                        fullWidth
                        variant='standard'
                        margin='normal'
                        />
                    </div>
                    <div>
                        <TextField 
                        onChange={updateInputPassword}
                        required
                        id="standard-password"
                        type='password'
                        defaultValue=""
                        label="Password"
                        fullWidth
                        variant='standard'
                        margin='normal'
                        />
                    </div>
                    
                    {credentialsWarn ? (
                        <div>
                            <Typography variant='body1' gutterBottom color='red'>
                                *Username or password is incorrect
                            </Typography>
                        </div>
                    ) : (
                        <div>
                            
                        </div>
                    )}
                    <Box sx={{m:1, position:'relative'}}>
                        <Button 
                        onClick={onLoginSubmit}
                        disabled={loadingLogin}
                        >
                            Log in
                        </Button>
                        {loadingLogin
                        && (
                            <CircularProgress
                            size={30}
                            sx={{
                            color: green,
                            position: 'absolute',
                            marginTop: '5px',
                            marginLeft: '-45px',

                            }}
                            />
                        )

                        }

                    </Box>
                    <Snackbar open={loadingLogin} autoHideDuration={10000}>
                        <MuiAlert severity='info' elevation={6} >
                            The login may take a while! The API server has been 'spun down' after inactivity and will take anywhere from 5 - 60 seconds to reload. During active use the login time shouldn't be longer than 5 - 10 seconds!
                        </MuiAlert>
                    </Snackbar>
                </Box>
            </Grow>
        </div>
    </div>
  )
}

export default LoginPage;